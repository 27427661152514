import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card, CardBody, CardHeader, UncontrolledTooltip } from 'reactstrap'

import {
    getNotifications,
    markAllRead,
    deleteAll,
    markRead,
    deleteOne,
} from 'features/dashboard/Notification.actionTypes'
import { getNotification } from 'features/dashboard/selectors'
import NotificationBody from './NotificationBody'
import Paginator from './Paginator'
import { getPaginationPages } from 'utils'

const NotificationCard = ({ toggle, notificationOpen }) => {
    const notification = useSelector(getNotification)
    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState(0)
    const [pagination, setPagination] = useState([])
    const [notificationItems, setNotificationItems] = useState([])
    const [notificationTab, setNotificationTab] = useState('all')

    const { items = [], status } = notification

    // Fetch notifications on component mount
    useEffect(() => {
        dispatch(getNotifications())
    }, [dispatch])

    // Mark all notifications as read if the notification panel is open
    useEffect(() => {
        if (notificationOpen) {
            const markReadTimeout = setTimeout(() => {
                dispatch(markAllRead())
            }, 5000)
            return () => clearTimeout(markReadTimeout) // Cleanup timeout on unmount
        }
    }, [notificationOpen, dispatch])

    // Filter notifications based on the selected tab
    useEffect(() => {
        if (items.length > 0) {
            const filteredItems =
                items[currentPage]?.filter((item) => {
                    if (notificationTab === 'system') return item.notification_type === 'system'
                    if (notificationTab === 'robot') return item.notification_type === 'vehicle'
                    if (notificationTab === 'sms') return item.sms_id
                    return true // 'all' tab
                }) || []
            setNotificationItems(filteredItems)
        }
    }, [items, notificationTab, currentPage])

    // Handle pagination when items or the current page changes
    useEffect(() => {
        if (notificationItems.length > 0) {
            const pageNumbers = getPaginationPages(currentPage, notificationItems)
            setPagination(pageNumbers)
        }
    }, [notificationItems, currentPage, notificationTab])

    return (
        <Card
            className="card-box shadow-none border-0"
            style={{
                height: '600px',
                overflowY: items.length > 0 ? 'scroll' : 'hidden',
                scrollbarWidth: 'none',
            }}
        >
            <CardHeader
                className="d-flex justify-content-between align-items-center position-sticky top-0 w-100"
                style={{ padding: '12px 24px' }}
            >
                <span
                    style={{
                        color: '#3D414C',
                        fontWeight: 600,
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '0.2px',
                    }}
                >
                    Notifications
                </span>
                <div>
                    {notification.items.length > 0 && (
                        <>
                            <img
                                id="delete-all"
                                src="/svgs/organization/buttons/trash.svg"
                                alt="Delete All"
                                width="16px"
                                height="16px"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    dispatch(deleteAll()).then(() => {
                                        dispatch(getNotifications())
                                        setNotificationItems([])
                                    })
                                }}
                            />
                            <UncontrolledTooltip
                                placement="auto"
                                target="delete-all"
                                style={{ backgroundColor: '#FFFF', color: '#3D414C' }}
                            >
                                Delete all
                            </UncontrolledTooltip>
                        </>
                    )}
                    <div
                        style={{
                            display: 'inline-block',
                            width: '1px',
                            height: '24px',
                            backgroundColor: '#E0E0E0',
                            margin: '0 12px',
                            verticalAlign: 'middle',
                        }}
                    ></div>
                    <img
                        src="/svgs/close-icon/x-dark-default.svg"
                        alt="Close"
                        width="24px"
                        height="24px"
                        style={{ cursor: 'pointer' }}
                        onClick={toggle}
                    />
                </div>
            </CardHeader>
            <CardHeader
                className="d-flex justify-content-start align-items-center w-100"
                style={{ gap: '12px', padding: '16px 24px', position: 'sticky', top: '49px' }}
            >
                {['all', 'system', 'robot', 'sms'].map((tab) => (
                    <span
                        key={tab}
                        className={`notification-tab ${
                            notificationTab === tab
                                ? 'notification-tab-selected'
                                : 'notification-tab-deselected'
                        }`}
                        onClick={() => {
                            setNotificationTab(tab)
                            setCurrentPage(0)
                        }}
                    >
                        {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </span>
                ))}
            </CardHeader>
            <CardBody
                className="d-flex flex-column justify-content-between align-items-center"
                style={{ padding: '16px 24px 16px 10px' }}
            >
                {notificationItems.length === 0 ? (
                    <div
                        style={{
                            textAlign: 'center',
                            color: '#6c757d',
                            fontWeight: 500,
                            padding: '20px 0',
                        }}
                    >
                        {notificationTab === 'system' && 'There are no system notifications.'}
                        {notificationTab === 'robot' && 'There are no robot notifications.'}
                        {notificationTab === 'sms' && 'There are no SMS notifications.'}
                        {notificationTab === 'all' && 'There are no notifications to display.'}
                    </div>
                ) : (
                    <>
                        <div className="d-flex flex-column align-items-start justify-content-center w-100">
                            <NotificationBody
                                items={notificationItems}
                                status={status}
                                handleDelete={(id) => {
                                    dispatch(deleteOne({ id })).then(() => {
                                        dispatch(getNotifications())
                                    })
                                }}
                                handleRead={(id) => dispatch(markRead({ id }))}
                            />
                        </div>
                        <div className="pb-3 pl-3">
                            {notificationItems.length > 1 && status !== 'loading' && (
                                <Paginator
                                    totalPages={notificationItems.length}
                                    pages={pagination}
                                    activePage={currentPage}
                                    handlePageChange={setCurrentPage}
                                />
                            )}
                        </div>
                    </>
                )}
            </CardBody>
        </Card>
    )
}

export default NotificationCard
