import React from 'react'

import { useDispatch } from 'react-redux'
import { Button, Form, FormGroup, Input, Label, UncontrolledTooltip } from 'reactstrap'
import { Field, Formik } from 'formik'
import { HiOutlineTrash } from 'react-icons/hi'
import { getAreaZones, updateAreaZone } from 'features/dashboard/Map.actionTypes'
import { customInputForm } from 'ui/common'
import { useSelector } from 'react-redux'
import { selectors } from 'features/dashboard'

const EditCapacityZone = ({ zone, toggle, handleDeleteModalShow }) => {
    const area = useSelector(selectors.getTeamMap)
    const uuid = area.areas.uuid
    const type = zone.zone_type
    const dispatch = useDispatch()
    const handleEdit = (values) => {
        const data = values
        dispatch(updateAreaZone({ type: type, data })).then(({ error }) => {
            if (error) return
            dispatch(getAreaZones({ uuid, type: type }))
        })
        toggle()
    }
    return (
        <>
            <div
                style={{ marginBottom: '24px' }}
                className={`d-flex w-100 justify-content-between align-items-start`}
            >
                <img
                    src="/svgs/map-editor/obstacles-icon.svg"
                    alt="obstacle-icon"
                    width="48px"
                    height="48px"
                />
                <img
                    src="/svgs/close-icon/x-dark-default.svg"
                    alt="obstacle-icon"
                    width="24px"
                    height="24px"
                    style={{ cursor: 'pointer' }}
                    onClick={toggle}
                />
            </div>
            <h5>
                <strong>Edit capacity zone preset</strong>
            </h5>

            <div style={{ marginTop: '45px' }} className="d-flex flex-column">
                <Formik initialValues={zone} onSubmit={handleEdit}>
                    {({ values, handleSubmit, setFieldValue }) => (
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault()
                                handleSubmit()
                            }}
                        >
                            <FormGroup>
                                <Label className="modals-labels">Zone distance threshold (meters)</Label>
                                <img
                                    src="/svgs/trigger-actions/question-mark.svg"
                                    alt="plus"
                                    id="zone-distance"
                                    width={16}
                                    height={16}
                                    style={{
                                        cursor: 'pointer',
                                        color: '#C2C5CE',
                                        marginLeft: '4px',
                                    }}
                                />
                                <UncontrolledTooltip
                                    placement="right"
                                    target="zone-distance"
                                    delay={{ show: 100, hide: 400 }}
                                    className="custom-tooltip"
                                >
                                    Distance at which capacity zone will be checked
                                </UncontrolledTooltip>
                                <Field
                                    className="modals-inputs"
                                    type="number"
                                    name="zone_distance_threshold"
                                    step={1}
                                    min="0"
                                    placeholder={zone.zone_distance_threshold}
                                    required
                                    component={customInputForm}
                                    value={values.zone_distance_threshold || ''}
                                ></Field>
                            </FormGroup>
                            <FormGroup check className="my-3 ml-2">
                                <Input
                                    type="checkbox"
                                    id="check_capacity_zone_task_allocation"
                                    checked={
                                        values.check_capacity_zone_task_allocation === true
                                            ? true
                                            : false
                                    }
                                    onChange={() => {
                                        setFieldValue(
                                            'check_capacity_zone_task_allocation',
                                            !values.check_capacity_zone_task_allocation
                                        )
                                    }}
                                />

                                <Label check for="capacityZones">
                                    Check capacity zone allocation
                                </Label>
                                <img
                                    src="/svgs/trigger-actions/question-mark.svg"
                                    alt="plus"
                                    id="check-capacity"
                                    width={16}
                                    height={16}
                                    style={{
                                        cursor: 'pointer',
                                        color: '#C2C5CE',
                                        marginLeft: '4px',
                                    }}
                                />
                                <UncontrolledTooltip
                                    placement="right"
                                    target="check-capacity"
                                    delay={{ show: 100, hide: 400 }}
                                    className="custom-tooltip"
                                >
                                    If set to "True", the vehicle limit for capacity zones is
                                    enforced during task allocation; if "False", the limit is
                                    managed at the entry station of the zone when vehicles arrive.
                                </UncontrolledTooltip>
                            </FormGroup>
                            <FormGroup>
                                <Label className="modals-labels">Zone vehicle limit </Label>
                                <img
                                    src="/svgs/trigger-actions/question-mark.svg"
                                    alt="plus"
                                    id="vehicle-limit"
                                    width={16}
                                    height={16}
                                    style={{
                                        cursor: 'pointer',
                                        color: '#C2C5CE',
                                        marginLeft: '4px',
                                    }}
                                />
                                <UncontrolledTooltip
                                    placement="right"
                                    target="vehicle-limit"
                                    delay={{ show: 100, hide: 400 }}
                                    className="custom-tooltip"
                                >
                                    Max number of vehicles allowed in this zone.
                                </UncontrolledTooltip>
                                <Field
                                    className="modals-inputs"
                                    type="number"
                                    name="zone_vehicle_limit"
                                    step={1}
                                    min="0"
                                    placeholder={zone.zone_vehicle_limit}
                                    required
                                    component={customInputForm}
                                    value={values.zone_vehicle_limit || ''}
                                ></Field>
                            </FormGroup>

                            <div className="d-flex w-100 justify-content-between align-items-center">
                                <Button
                                    color="none"
                                    onClick={() => handleDeleteModalShow(zone)}
                                    className="modals-delete-btn"
                                >
                                    <HiOutlineTrash size={20} className="mr-1" />
                                    Delete
                                </Button>

                                <Button
                                    color="none"
                                    onClick={toggle}
                                    className="modals-cancel-btn new-modals-btn"
                                >
                                    Cancel
                                </Button>
                            </div>

                            <Button
                                color="meili"
                                className="modals-save-btn new-modals-btn mt-2 ml-auto mr-2"
                                type="submit"
                                //disabled={JSON.stringify(zone) === JSON.stringify(values)}
                            >
                                Edit zone
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )
}

export default EditCapacityZone
