import React, { useEffect, useMemo, useRef, useState } from 'react'
import * as moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { AgGridReact } from 'ag-grid-react'
import { BiChevronDown } from 'react-icons/bi'
import {
    Row,
    Button,
    Col,
    ModalBody,
    Modal,
    ButtonGroup,
    UncontrolledTooltip,
    Popover,
    DropdownToggle,
    DropdownMenu,
    UncontrolledDropdown,
    DropdownItem,
} from 'reactstrap'
import { selectors } from 'features/dashboard'
import {
    cleanFormValues,
    deleteTaskV2,
    getActionsPresets,
    getPresetTasksV2,
    getScheduleTasksV2,
    getTaskDefinitions,
    getTasksAll,
} from 'features/dashboard/Task.actionTypes'
import { getMapStations } from 'features/dashboard/Map.actionTypes'
import { getTeamVehiclesLight, getVehicleCategories } from 'features/dashboard/Vehicle.actionTypes'
import { getWebhooks } from 'features/dashboard/Team.actionTypes'
import { getDevices } from 'features/dashboard/RosSetup.actionTypes'
import { getTriggers } from 'features/dashboard/Trigger.actionTypes'
import PreSetTaskFormNew from '../Tasks/PresetTaskFormNew'
import {
    actionsPresetsColumns,
    schedulesList,
    definitionList,
    missionsColumns,
    presetsColumns,
    triggersColumns,
    webhookList,
} from './ActionsManageColumns'
import RemoveModal from '../MapComponents/RemoveModal'
import { DateRangePicker } from 'react-date-range'
import { formatDateToDdMmYy } from 'utils'
import { TITLE } from 'config'
import Filter from 'layout-components/Filter'
import LoadingSpinner from 'components/utils/LoadingSpinner'
import ScheduledMissionEditModal from './ScheduledMissionEditModal'
import DefinitionCreation from './DefinitionCreation'
import TriggerModal from './TriggerModal'
import WebhookModal from '../organization/WebhookModal'
import { BsInfoCircle } from 'react-icons/bs'
import TaskModalV2 from '../Tasks/TaskModalV2'
import { setMessages } from 'features/generic/Message.actionTypes'

const TriggerManage = ({ match }) => {
    const { slug } = match.params
    const dispatch = useDispatch()
    const map = useSelector(selectors.getTeamMap)
    const taskStore = useSelector(selectors.getTask)
    const devices = useSelector(selectors.getDevicess)
    const { triggers } = useSelector(selectors.getTriggers)
    const webhooks = useSelector(selectors.getTeamWebhooks)
    const [activeTab, setActiveTab] = useState('presets')
    const [datepicker, setDatepicker] = useState(false)
    const [modal, setModal] = useState(false)
    const [removeModal, setRemoveModal] = useState(false)
    const [dataOnClick, setDataOnClick] = useState({})
    const [btnText, setButtonText] = useState('Mission preset')
    const [noDataText, setNoDataText] = useState('mission presets')
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [lastClickedPage, setLastClickedPage] = useState(1)
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [task, setTask] = useState('')
    let today = moment().format('YYYY-MM-DD')
    const [initialFilterValues, setInitialFilterValues] = useState({
        stations: [],
    })
    const datetoggle = () => setDatepicker((prevState) => !prevState)
    const [searchText, setSearchText] = useState('')
    const [webhookForm, setWebhookForm] = useState('')
    const gridRef = useRef()
    const [api, setApi] = useState(null)
    const onGridReady = (params) => {
        gridRef.current = params.api // Store grid API in gridRef
        params.api.sizeColumnsToFit()
        setApi(params.api)
        currentPageNumber > 1 && params.api.paginationGoToPage(lastClickedPage)
    }
    const defaultColDef = {
        cellClass: 'custom-cell mt-2', // Apply custom class to all cells
        headerClass: 'custom-header',
        resizable: true,
        sortable: true,
        flex: 1, // Apply custom class to all headers
    }
    const getCurrentPage = () => {
        if (api && !api.isDestroyed()) {
            const currentPage = api.paginationGetCurrentPage() + 1 // Add 1 to convert to 1-based index
            setLastClickedPage(currentPage)
        }
    }
    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase()
        setSearchText(value)
        api && api.onFilterChanged(value)
    }
    useEffect(() => {
        document.title = `${TITLE} - Actions`
    }, [])

    const toggle = () => {
        setModal(!modal)
        dispatch(cleanFormValues())
    }

    // dateRange functions
    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        today: 'today',
        week: 'week',
        key: 'selection',
    })
    const handleSelect = (ranges) => {
        setSelectedDateRange(ranges.selection)
    }
    const sDate = moment(selectedDateRange.startDate).format('YYYY-MM-DDTHH:mm:ss')
    const eDate = moment(selectedDateRange.endDate).format('YYYY-MM-DDTHH:mm:ss')

    var start = moment(selectedDateRange?.startDate).format('DD MMM YYYY')
    var end = moment(selectedDateRange?.endDate).format('DD MMM YYYY')

    const updateDate = () => {
        const from_date = sDate
        const to_date = eDate
        dispatch(getTasksAll({ slug, range: `${from_date},${to_date}` })).then(({ payload }) => {
            if (payload) {
                dispatch(
                    setMessages([
                        {
                            type: 'success',
                            text: 'Success',
                        },
                    ])
                )
            }
        })
        datetoggle()
    }

    const canNotCancelTask = () => {
        return (
            task.status === 'failed' ||
            task.status === 'terminated' ||
            task.status === 'aborted' ||
            task.status === 'completed'
        )
    }

    const canCancelTask = () => !canNotCancelTask()

    const handleFail = ({ uuid }) => {
        dispatch(deleteTaskV2({ uuid }))
        dispatch(getTasksAll({ slug, range: today }))
        toggle()
    }
    const close = () => {
        setModal(false)
        setDataOnClick({})
    }

    const dropdownToggle = () => setDropdownOpen(!dropdownOpen)
    const toggleRemove = () => setRemoveModal(!removeModal)

    useEffect(() => {
        if (!modal) {
            dispatch(cleanFormValues())
            setDataOnClick({})
        }
    }, [modal]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (currentPageNumber > 1) dispatch(getPresetTasksV2({ slug, page: currentPageNumber }))
    }, [currentPageNumber]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(getDevices({ slug }))
        dispatch(getVehicleCategories({ slug }))
        dispatch(getTeamVehiclesLight({ slug }))
        dispatch(getMapStations({ uuid: map.areas.uuid }))
        dispatch(getTriggers({ slug }))
        dispatch(getActionsPresets({ slug }))
        dispatch(getTaskDefinitions(slug))
        dispatch(getTasksAll({ slug, range: today }))
        dispatch(getScheduleTasksV2({ slug }))
        dispatch(getPresetTasksV2({ slug, page: currentPageNumber }))
        dispatch(getWebhooks({ slug }))
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        switch (activeTab) {
            case 'missions':
                setButtonText('Mission')
                setNoDataText('missions')
                return
            case 'presets':
                setButtonText('Mission preset')
                setNoDataText('mission presets')
                return
            case 'definitions':
                setButtonText('Action definition')
                setNoDataText('action definitions')
                return
            case 'actions-presets':
                setButtonText('Action preset')
                setNoDataText('action presets')
                return
            case 'triggers':
                setButtonText('Trigger')
                setNoDataText('triggers')
                return
            case 'webhooks':
                setButtonText('Webhook')
                setNoDataText('webhooks')
                return
            default:
                setButtonText('Mission schedule')
                setNoDataText('scheduled missions')
        }
    }, [activeTab])

    const showActionsLength = () => {
        switch (activeTab) {
            case 'missions':
                return taskStore?.listV2.length || 0
            case 'scheduleMissions':
                return taskStore?.listV2.length || 0

            case 'presets':
                if (taskStore.presetsV2.length) return 0
                return taskStore?.presetsV2?.count || 0
            case 'definitions':
                return taskStore?.taskDefinitions.length || 0
            case 'actions-presets':
                return taskStore?.actionsPresets?.length || 0
            case 'triggers':
                return triggers?.length || 0
            case 'webhooks':
                return webhooks?.length || 0
            default:
                return taskStore.schedulesV2.length || 0
        }
    }

    const modalSwitcher = () => {
        switch (activeTab) {
            case 'presets':
            case 'actions-presets':
                return (
                    <PreSetTaskFormNew
                        toggle={toggle}
                        taskStore={taskStore}
                        stations={map?.stations}
                        devices={devices}
                        actions={taskStore?.taskDefinitions}
                        actionsPresets={taskStore?.actionsPresets}
                        activeTab={activeTab}
                        preset={dataOnClick}
                        currentPageNumber={currentPageNumber}
                        toggleRemove={toggleRemove}
                    />
                )
            case 'definitions':
                return (
                    <DefinitionCreation
                        slug={slug}
                        toggle={toggle}
                        taskStore={taskStore}
                        toggleRemove={toggleRemove}
                        definitions={taskStore?.taskDefinitions}
                        definition={dataOnClick}
                    />
                )
            case 'triggers':
                return (
                    <TriggerModal
                        slug={slug}
                        toggle={toggle}
                        areaUuid={map.areas.uuid}
                        triggers={triggers}
                        trigger={dataOnClick}
                        tasks={taskStore?.presetsV2.results}
                        toggleRemove={toggleRemove}
                    />
                )
            case 'scheduleMissions':
                return (
                    <ScheduledMissionEditModal
                        slug={slug}
                        toggle={toggle}
                        taskStore={taskStore}
                        mission={dataOnClick}
                        missions={taskStore?.schedulesV2}
                        toggleRemove={toggleRemove}
                    />
                )
            case 'missions':
                return (
                    <TaskModalV2
                        toggle={toggle}
                        modal={modal}
                        handleFail={handleFail}
                        canCancelTask={canCancelTask}
                        task={task}
                    />
                )
            case 'webhooks':
                return (
                    <WebhookModal
                        slug={slug}
                        scope="team"
                        modal={modal}
                        toggle={toggle}
                        form={webhookForm}
                        webhook={dataOnClick}
                        toggleRemove={toggleRemove}
                    />
                )
            default:
                return
        }
    }

    const dataSwitcher = () => {
        switch (activeTab) {
            case 'missions':
                return dataMissions
            case 'presets':
                return dataPresets
            case 'definitions':
                return dataDefinitions
            case 'actions-presets':
                return dataActionPresets
            case 'triggers':
                return dataTriggers
            case 'webhooks':
                return webhookData
            default:
                return dataSchedules
        }
    }
    const columnsSwitcher = () => {
        switch (activeTab) {
            case 'missions':
                return missionsColumns
            case 'presets':
                return presetsColumns
            case 'definitions':
                return definitionList
            case 'actions-presets':
                return actionsPresetsColumns
            case 'triggers':
                return triggersColumns
            case 'webhooks':
                return webhookList
            default:
                return schedulesList
        }
    }
    let setRef = window.innerWidth > 1020 ? false : true
    let isMobile = useRef(setRef)

    useEffect(() => {
        const resize = () => {
            if (window.innerWidth > 920) {
                if (isMobile.current) {
                    isMobile.current = false
                }
            } else {
                if (!isMobile.current) {
                    isMobile.current = true
                }
            }
        }

        window.addEventListener('resize', resize)

        return () => window.removeEventListener('resize', resize)
    }, [])
    const VariablesCell = ({ variables }) => {
        if (!variables || variables.length === 0) {
            return <span>No form values</span>
        }

        const firstVariable = variables[0]
        const additionalVariables = variables.slice(1)
        const tooltipContent = additionalVariables
            .map((variable) => `${variable.variable_name} (${variable.variable_type})`)
            .join(', ')

        return (
            <span title={additionalVariables.length > 0 ? tooltipContent : ''}>
                {firstVariable.variable_name} ({firstVariable.variable_type})
                {additionalVariables.length > 0 && <span> +{additionalVariables.length} more</span>}
            </span>
        )
    }

    const RenderTriggereManage = ({ action }) => (
        <div className="d-flex justify-content-center align-items-center">
            <img
                src="/svgs/organization/buttons/trash.svg"
                alt="trash"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                    setDataOnClick(action)
                    toggleRemove()
                }}
            />
            <img
                style={{
                    cursor: 'pointer',
                    marginLeft: '1rem',
                }}
                src="/svgs/trigger-actions/edit.svg"
                alt="edit"
                onClick={() => {
                    if (activeTab === 'webhooks') setWebhookForm('edit-webhook')
                    setDataOnClick(action)
                    toggle()
                }}
            />
        </div>
    )

    const RenderDefinitionsManage = ({ action }) => (
        <div className="d-flex justify-content-center align-items-center">
            <img
                style={{
                    cursor: action.system_generated ? 'not-allowed ' : 'pointer',
                    pointerEvents: action.system_generated ? ' none ' : '',
                    opacity: action.system_generated ? ' 0.5 ' : '',
                }}
                src="/svgs/organization/buttons/trash.svg"
                alt="trash"
                onClick={() => {
                    setDataOnClick(action)
                    toggleRemove()
                }}
            />
            <img
                style={{
                    cursor: action.system_generated ? 'not-allowed ' : 'pointer',
                    pointerEvents: action.system_generated ? 'none ' : '',
                    opacity: action.system_generated ? ' 0.5 ' : '',
                    marginLeft: '1rem',
                }}
                src="/svgs/trigger-actions/edit.svg"
                alt="edit"
                onClick={() => {
                    setDataOnClick(action)
                    toggle()
                }}
            />
        </div>
    )

    const dataMissions = useMemo(() => {
        if (!taskStore.listV2) return []
        return Object.values(taskStore?.listV2)?.map((task) => {
            return {
                uuid: task?.uuid,
                number: task?.number,
                status: task?.status,
                vehicle: task?.vehicle?.verbose_name,
                created_at: task?.vehicle?.created_at,
                update: (
                    <BsInfoCircle
                        className={'font-size-lg ml-auto info-icon'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setTask(task)
                            toggle()
                        }}
                    />
                ),
            }
        }) // eslint-disable-next-line
    }, [taskStore.listV2])

    const dataPresets = useMemo(() => {
        if (!taskStore.presetsV2.results) return []
        return taskStore?.presetsV2?.results.map((preset) => {
            const date = new Date(preset?.metadata.created_at)
            return {
                uuid: preset?.uuid,
                title: preset?.title,
                created_at: formatDateToDdMmYy(date),
                details: <span>{preset.details}</span>,
                update: <RenderTriggereManage action={preset} key={preset.uuid} />,
            }
        })
    }, [taskStore.presetsV2.results])

    const dataDefinitions = useMemo(() => {
        if (!taskStore.taskDefinitions) return []
        return taskStore.taskDefinitions.map((definition) => ({
            uuid: definition?.uuid,
            title: definition?.displayable_name,
            type: definition.system_generated ? 'System default' : 'Custom created',
            description: <span>{definition.description}</span>,
            variables: <VariablesCell variables={definition.form?.variables} />,
            update: <RenderDefinitionsManage action={definition} key={definition.uuid} />,
        }))
    }, [taskStore.taskDefinitions])

    const dataActionPresets = useMemo(() => {
        if (!taskStore.actionsPresets) return []
        return taskStore?.actionsPresets.map((preset) => ({
            uuid: preset?.uuid,
            title: preset?.displayable_name,
            type: preset?.definition_information.displayable_name,
            details: (
                <span>
                    {preset.definition_information?.displayable_name === 'Move to point'
                        ? `Move to ${preset.point_information?.title || 'point'}`
                        : `${preset.definition_information?.displayable_name} at ${
                              preset.point_information?.title || 'point'
                          }`}
                </span>
            ),
            update: <RenderTriggereManage action={preset} key={preset.uuid} />,
        }))
    }, [taskStore.actionsPresets])

    const DropdownButton = ({ label, isActive, onClick }) => (
        <DropdownItem className="dropdownhover">
            <Button outline className="text-dark" active={isActive} onClick={onClick}>
                {label}
            </Button>
        </DropdownItem>
    )

    const dataSchedules = useMemo(() => {
        if (!taskStore.schedulesV2) return []
        return taskStore.schedulesV2.map((schedule) => {
            const subtaskArray = schedule.task_preset.subtasks
            const subtaskText = subtaskArray.map((subtask, index) => {
                if (index === subtaskArray.length - 1) {
                    return subtask.action.definition_information.displayable_name
                } else {
                    return `${subtask.action.definition_information.displayable_name} - `
                }
            })

            const result = <span>{subtaskText.join('')}</span>
            const date = new Date(schedule?.date_tz)
            return {
                uuid: schedule?.uuid,
                title: schedule?.name,
                execute_at: `${formatDateToDdMmYy(date)}
                ${schedule?.time_tz}h`,
                details: <span>{result}</span>,
                enabled: schedule.enabled,
                offline: schedule.offline,
                update: <RenderTriggereManage action={schedule} key={schedule.uuid} />,
            }
        })
    }, [taskStore.schedulesV2])

    const dataTriggers = useMemo(() => {
        if (!triggers) return []
        return triggers.map((trigger) => ({
            uuid: trigger?.uuid,
            title: trigger?.verbose_name,
            url: trigger?.url,
            action: trigger?.action,
            ip_whitelist: trigger?.ip_whitelist.join(', '),
            initialized: trigger?.initialized,
            update: <RenderTriggereManage action={trigger} key={trigger.uuid} />,
        }))
    }, [triggers])

    const webhookData = useMemo(() => {
        return webhooks.map((webhook) => ({
            uuid: webhook.uuid,
            url: webhook.url,
            actions: webhook?.action_list?.join(', ') || '-',
            headers:
                (
                    <div className="d-flex flex-column">
                        <span>
                            keys: {webhook?.headers?.map((header) => header.key || '-').join(', ')}
                        </span>
                        <span>
                            values:{' '}
                            {webhook?.headers?.map((header) => header.value || '-').join(', ')}
                        </span>
                    </div>
                ) || '-',
            action: <RenderTriggereManage action={webhook} />,
        }))
    }, [webhooks])

    return (
        <div>
            <div className="w-100" style={{ marginTop: '-45px' }}>
                <h3>Actions</h3>
            </div>
            <Row className="mt-4 w-100">
                <Col className="pb-5">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                            {isMobile.current ? (
                                <UncontrolledDropdown style={{ marginBottom: '8px' }}>
                                    <DropdownToggle caret className="text-dark bg-white">
                                        {btnText}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownButton
                                            label="Mission presets"
                                            className={` ${
                                                activeTab === 'presets'
                                                    ? 'org-button-selected'
                                                    : 'actionBtn'
                                            } org-button`}
                                            style={{ height: '2.5rem', borderRadius: '6px' }}
                                            onClick={() => {
                                                setActiveTab('presets')
                                                setSearchText('')
                                            }}
                                        />
                                        <DropdownButton
                                            label="Missions"
                                            className={`${
                                                activeTab === 'missions'
                                                    ? 'org-button-selected'
                                                    : 'actionBtn'
                                            } org-button`}
                                            style={{ height: '2.5rem', borderRadius: '6px' }}
                                            onClick={() => {
                                                setActiveTab('missions')
                                                setSearchText('')
                                            }}
                                        />
                                        <DropdownButton
                                            label="Mission schedules"
                                            className={`${
                                                activeTab === 'scheduleMissions'
                                                    ? 'org-button-selected'
                                                    : 'actionBtn'
                                            } org-button`}
                                            style={{ height: '2.5rem', borderRadius: '6px' }}
                                            onClick={() => {
                                                setActiveTab('scheduleMissions')
                                                setSearchText('')
                                            }}
                                        />
                                        <DropdownButton
                                            label="Action definitions"
                                            className={`${
                                                activeTab === 'definitions'
                                                    ? 'org-button-selected'
                                                    : 'actionBtn'
                                            } org-button`}
                                            style={{ height: '2.5rem', borderRadius: '6px' }}
                                            onClick={() => {
                                                setActiveTab('definitions')
                                                setSearchText('')
                                            }}
                                        />
                                        <DropdownButton
                                            label="Action presets"
                                            className={`${
                                                activeTab === 'actions-presets'
                                                    ? 'org-button-selected'
                                                    : 'actionBtn'
                                            } org-button`}
                                            style={{ height: '2.5rem', borderRadius: '6px' }}
                                            onClick={() => {
                                                setActiveTab('actions-presets')
                                                setSearchText('')
                                            }}
                                        />
                                        <DropdownButton
                                            label="Triggers"
                                            className={`${
                                                activeTab === 'triggers'
                                                    ? 'org-button-selected'
                                                    : 'actionBtn'
                                            } org-button`}
                                            style={{ height: '2.5rem', borderRadius: '6px' }}
                                            onClick={() => {
                                                setActiveTab('triggers')
                                                setSearchText('')
                                            }}
                                        />
                                        <DropdownButton
                                            label="Webhooks"
                                            className={`${
                                                activeTab === 'webhooks'
                                                    ? 'org-button-selected'
                                                    : 'actionBtn'
                                            } org-button `}
                                            style={{ height: '2.5rem', borderRadius: '6px' }}
                                            onClick={() => {
                                                setActiveTab('webhooks')
                                                setSearchText('')
                                            }}
                                        />
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            ) : (
                                <ButtonGroup
                                    className="org-button-group"
                                    style={{ padding: '3px' }}
                                >
                                    <Button
                                        className={` ${
                                            activeTab === 'presets'
                                                ? 'org-button-selected'
                                                : 'actionBtn'
                                        } org-button`}
                                        style={{ height: '2.5rem', borderRadius: '6px' }}
                                        onClick={() => {
                                            setActiveTab('presets')
                                            setSearchText('')
                                        }}
                                    >
                                        Mission presets
                                    </Button>
                                    <Button
                                        className={` ${
                                            activeTab === 'missions'
                                                ? ' org-button-selected'
                                                : 'actionBtn'
                                        } org-button`}
                                        style={{ height: '2.5rem', borderRadius: '6px' }}
                                        onClick={() => {
                                            setActiveTab('missions')
                                            setSearchText('')
                                        }}
                                    >
                                        Missions
                                    </Button>
                                    <Button
                                        className={`${
                                            activeTab === 'scheduleMissions'
                                                ? 'org-button-selected'
                                                : 'actionBtn'
                                        } org-button`}
                                        style={{ height: '2.5rem', borderRadius: '6px' }}
                                        onClick={() => {
                                            setActiveTab('scheduleMissions')
                                            setSearchText('')
                                        }}
                                    >
                                        Mission schedules
                                    </Button>
                                    <Button
                                        className={`${
                                            activeTab === 'definitions'
                                                ? 'org-button-selected'
                                                : 'actionBtn'
                                        } org-button`}
                                        style={{ height: '2.5rem', borderRadius: '6px' }}
                                        onClick={() => {
                                            setActiveTab('definitions')
                                            setSearchText('')
                                        }}
                                    >
                                        Action definitions
                                    </Button>
                                    <Button
                                        className={`${
                                            activeTab === 'actions-presets'
                                                ? 'org-button-selected'
                                                : 'actionBtn'
                                        } org-button`}
                                        style={{ height: '2.5rem', borderRadius: '6px' }}
                                        onClick={() => {
                                            setActiveTab('actions-presets')
                                            setSearchText('')
                                        }}
                                    >
                                        Action presets
                                    </Button>
                                    <Button
                                        className={`${
                                            activeTab === 'triggers'
                                                ? 'org-button-selected'
                                                : 'actionBtn'
                                        } org-button`}
                                        style={{ height: '2.5rem', borderRadius: '6px' }}
                                        onClick={() => {
                                            setActiveTab('triggers')
                                            setSearchText('')
                                        }}
                                    >
                                        Triggers
                                    </Button>
                                    <Button
                                        className={`${
                                            activeTab === 'webhooks'
                                                ? 'org-button-selected'
                                                : 'actionBtn'
                                        } org-button `}
                                        style={{ height: '2.5rem', borderRadius: '6px' }}
                                        onClick={() => {
                                            setActiveTab('webhooks')
                                            setSearchText('')
                                        }}
                                    >
                                        Webhooks
                                    </Button>
                                </ButtonGroup>
                            )}
                            {/* 
                            <ButtonGroup className="org-button-group" style={{ padding: '3px' }}>
                                <Button
                                    className={` ${
                                        activeTab === 'presets' ? 'org-button-selected' : 'actionBtn'
                                    } org-button`}
                                    style={{ height: '2.5rem', borderRadius: '6px' }}
                                    onClick={() => {
                                        setActiveTab('presets')
                                        setSearchText('')
                                    }}
                                >
                                    Mission presets
                                </Button>
                                <Button
                                    className={` ${
                                        activeTab === 'missions' ? ' org-button-selected' : 'actionBtn'
                                    } org-button`}
                                    style={{ height: '2.5rem', borderRadius: '6px' }}
                                    onClick={() => {
                                        setActiveTab('missions')
                                        setSearchText('')
                                    }}
                                >
                                    Missions
                                </Button>
                                <Button
                                    className={`${
                                        activeTab === 'scheduleMissions'
                                            ? 'org-button-selected'
                                            : 'actionBtn'
                                    } org-button`}
                                    style={{ height: '2.5rem', borderRadius: '6px' }}
                                    onClick={() => {
                                        setActiveTab('scheduleMissions')
                                        setSearchText('')
                                    }}
                                >
                                    Mission schedules
                                </Button>
                                <Button
                                    className={`${
                                        activeTab === 'definitions' ? 'org-button-selected' : 'actionBtn'
                                    } org-button`}
                                    style={{ height: '2.5rem', borderRadius: '6px' }}
                                    onClick={() => {
                                        setActiveTab('definitions')
                                        setSearchText('')
                                    }}
                                >
                                    Action definitions
                                </Button>
                                <Button
                                    className={`${
                                        activeTab === 'actions-presets' ? 'org-button-selected' : 'actionBtn'
                                    } org-button`}
                                    style={{ height: '2.5rem', borderRadius: '6px' }}
                                    onClick={() => {
                                        setActiveTab('actions-presets')
                                        setSearchText('')
                                    }}
                                >
                                    Action presets
                                </Button>
                                <Button
                                    className={`${
                                        activeTab === 'triggers' ? 'org-button-selected' : 'actionBtn'
                                    } org-button` }
                                    style={{ height: '2.5rem', borderRadius: '6px' }}
                                    onClick={() => {
                                        setActiveTab('triggers')
                                        setSearchText('')
                                    }}
                                >
                                    Triggers
                                </Button>
                                <Button
                                    className={`${
                                        activeTab === 'webhooks' ? 'org-button-selected' : 'actionBtn'
                                    } org-button `}
                                    style={{ height: '2.5rem', borderRadius: '6px' }}
                                    onClick={() => {
                                        setActiveTab('webhooks')
                                        setSearchText('')
                                    }}
                                >
                                    Webhooks
                                </Button>
                            </ButtonGroup> */}

                            <span
                                className="text-meili rounded px-2 ml-4 badges"
                                style={{ backgroundColor: '#F9EFFE' }}
                            >
                                {`${showActionsLength()} ${noDataText}`}
                            </span>
                        </div>

                        <div className="d-flex align-items-center">
                            {activeTab === 'missions' || activeTab === 'scheduleMissions' ? (
                                <>
                                    <img
                                        src="/svgs/trigger-actions/question-mark.svg"
                                        alt="plus"
                                        id="mission-question"
                                        style={{ cursor: 'pointer', color: '#C2C5CE' }}
                                    />
                                    <UncontrolledTooltip
                                        placement="left"
                                        target="mission-question"
                                        className="custom-tooltip"
                                    >
                                        {activeTab === 'missions'
                                            ? ' Missions can be created on the mission page '
                                            : 'Missions can be scheduled on the mission page'}
                                    </UncontrolledTooltip>
                                </>
                            ) : (
                                <div className="d-flex">
                                    <Button
                                        className="save-btn-modal modals-new-btns"
                                        style={{
                                            border: 'none',
                                            height: '2.5rem',
                                            marginLeft: '12px',
                                        }}
                                        onClick={() => {
                                            if (activeTab === 'webhooks')
                                                setWebhookForm('add-webhook')
                                            toggle()
                                        }}
                                    >
                                        <img
                                            src="/svgs/trigger-actions/add.svg"
                                            alt="plus"
                                            style={{ marginRight: '8px' }}
                                        />
                                        {btnText}
                                    </Button>
                                </div>
                            )}

                            {/* <img
                                    src="/svgs/trigger-actions/settings-dots.svg"
                                    alt="plus"
                                    style={{ cursor: 'pointer' }}
                                /> */}
                        </div>
                    </div>

                    <div
                        className=" search-header d-flex justify-content-between align-items-center top-btn"
                        style={{ marginTop: '16px', marginBottom: '16px', marginRight: '20px' }}
                    >
                        {' '}
                        <div className="">
                            <input
                                type="text"
                                className="search-box"
                                placeholder="Search"
                                value={searchText}
                                onChange={handleSearch}
                            ></input>
                        </div>
                        <div className="d-flex">
                            {activeTab === 'missions' && (
                                <div style={{ marginTop: '12px' }}>
                                    <Popover
                                        popperClassName="popover-full-width rounded border-0 card-border "
                                        trigger="legacy"
                                        target="datepicker"
                                        toggle={datetoggle}
                                        placement="bottom-end"
                                        isOpen={datepicker}
                                    >
                                        <DateRangePicker
                                            ranges={[selectedDateRange]}
                                            onChange={handleSelect}
                                            months={2}
                                            minDate={new Date(2020, 0, 1)}
                                            maxDate={new Date(2025, 11, 31)}
                                            direction="vertical"
                                            fixedHeight={false}
                                        />
                                        <div
                                            className="d-flex justify-content-end mb-2 mr-3"
                                            style={{
                                                width: '100%',
                                                padding: '14px',
                                                borderTop: '2px solid #f1f1f3',
                                            }}
                                        >
                                            <Button
                                                className="cancel-btn-modal modals-new-btns"
                                                style={{ marginRight: '8px' }}
                                                onClick={datetoggle}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                className="save-btn-modal modals-new-btns"
                                                onClick={updateDate}
                                            >
                                                Apply
                                            </Button>
                                        </div>
                                    </Popover>{' '}
                                    <Button
                                        outline
                                        style={{ borderRadius: 10 }}
                                        id="datepicker"
                                        className="d-flex align-items-center justify-content-center filter-button-org popover-btn"
                                    >
                                        <span>{start}</span> - <span>{end}</span>
                                        <BiChevronDown></BiChevronDown>
                                    </Button>
                                </div>
                            )}
                            {(activeTab === 'missions' ||
                                activeTab === 'presets' ||
                                activeTab === 'actions-presets') && (
                                <Filter
                                    table="actions"
                                    slug={slug}
                                    initValues={initialFilterValues}
                                    setInitValues={setInitialFilterValues}
                                    setLastClickedPage={setLastClickedPage}
                                    dropdownOpen={dropdownOpen}
                                    toggle={dropdownToggle}
                                    activeTab={activeTab}
                                    stations={map?.stations}
                                />
                            )}
                        </div>
                    </div>
                    <div className="mb-3  dash-table-container">
                        {dataSwitcher().length === 0 && taskStore?.status === 'loaded' ? (
                            <h5 className="p-4">There are currently no {noDataText}.</h5>
                        ) : taskStore.status === 'loading' && !modal ? (
                            <div
                                className=" bg-white w-100 pb-2 mt-3"
                                style={{ minHeight: '45rem' }}
                            >
                                <LoadingSpinner />
                            </div>
                        ) : (
                            <div
                                className="rounded-table-container ag-theme-quartz"
                                style={{ width: '100%' }}
                            >
                                {
                                    <AgGridReact
                                        keyField="uuid"
                                        rowData={dataSwitcher()}
                                        columnDefs={columnsSwitcher()}
                                        onGridReady={onGridReady}
                                        pagination={true}
                                        paginationPageSize={10}
                                        paginationPageSizeSelector={[10, 20, 50, 100]}
                                        defaultColDef={defaultColDef}
                                        rowHeight={60}
                                        onPaginationChanged={getCurrentPage}
                                        quickFilterText={searchText}
                                        domLayout="autoHeight"
                                        tooltipShowDelay={0} // Show tooltip immediately
                                        rowClassRules={{
                                            'gray-row': (params) => params.node.rowIndex % 2 === 1,
                                        }}
                                    />
                                }
                            </div>
                        )}
                        {activeTab === 'presets' &&
                            taskStore.status === 'loaded' &&
                            taskStore.presetsV2.next &&
                            taskStore.presetsV2.results.length < taskStore.presetsV2.count &&
                            lastClickedPage ===
                                Math.ceil(taskStore.presetsV2.results.length / 10) && (
                                <Button
                                    style={{
                                        position: 'absolute',
                                        bottom: '0.7rem',
                                        right: '24px',
                                        borderRadius: '8px',
                                        background: '#f9fafb',
                                        color: '#4e5462',
                                    }}
                                    onClick={() => setCurrentPageNumber(currentPageNumber + 1)}
                                >
                                    Load more (
                                    {taskStore.presetsV2.count - taskStore.presetsV2.results.length}
                                    )
                                </Button>
                            )}
                    </div>
                </Col>
            </Row>

            <Modal className="new-modals" centered isOpen={modal} toggle={toggle}>
                <ModalBody>{modalSwitcher()}</ModalBody>
            </Modal>

            <Modal className="new-modals" centered isOpen={removeModal} toggle={toggleRemove}>
                <ModalBody>
                    <RemoveModal
                        slug={slug}
                        data={dataOnClick}
                        type={activeTab === 'webhooks' ? 'webhook' : 'presets'}
                        toggle={toggleRemove}
                        toggleBig={close}
                        setDataOnClick={setDataOnClick}
                    />
                </ModalBody>
            </Modal>
        </div>
    )
}
export default TriggerManage
