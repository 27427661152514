import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'
import { TITLE } from 'config'
import { AgGridReact } from 'ag-grid-react'
import LoadingSpinner from 'components/utils/LoadingSpinner'
import ManageOrganizationModals from './ManageOrganizationModals'
import { getOrgSetups, removeSetup, resetSetups } from 'features/dashboard/RosSetup.actionTypes'
import dayjs from 'dayjs'
import { selectors } from 'features/dashboard'
import { getOrgTeams } from 'features/dashboard/Organization.actionTypes'
import Filter from 'layout-components/Filter'
import { HiOutlineX } from 'react-icons/hi'

const FleetSetupsManage = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const setupStore = useSelector(selectors.getSetup)
    const teams = useSelector(selectors.getOrganizationTeams)
    const [currentOrg] = useState(JSON.parse(localStorage.getItem('org')))
    const [modal, setModal] = useState(false)
    const [form, setForm] = useState(null)
    const [dataOnClick, setDataOnClick] = useState({})
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [lastClickedPage, setLastClickedPage] = useState(1)
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [initialFilterValues, setInitialFilterValues] = useState({ team: [], type: [] })
    const dropdownToggle = () => setDropdownOpen(!dropdownOpen)
    const toggle = () => setModal(!modal)
    const [searchText, setSearchText] = useState('')
    const gridRef = useRef()
    const [api, setApi] = useState(null)

    const onGridReady = useCallback(
        (params) => {
            gridRef.current = params.api // Store grid API in gridRef
            params.api.sizeColumnsToFit() // Resize columns once the grid is ready
            setApi(params.api)

            if (lastClickedPage > 1) {
                setTimeout(() => {
                    params.api.paginationGoToPage(lastClickedPage - 1) // Adjust for zero-based index
                }, 0)
            }
        },
        [lastClickedPage]
    )
    const defaultColDef = {
        cellClass: 'cell-class', // Apply custom class to all cells
        headerClass: 'custom-header', // Apply custom class to all headers
        resizable: true,
        sortable: true,
        flex: 1,
        valueFormatter: (params) => {
            return params.value
        },
    }
    const getCurrentPage = () => {
        if (api && !api.isDestroyed()) {
            const currentPage = api.paginationGetCurrentPage() + 1 // Add 1 to convert to 1-based index
            setLastClickedPage(currentPage)
        }
    }

    useEffect(() => {
        document.title = `${TITLE} - Fleet setups manage`
    }, [])

    useEffect(() => {
        dispatch(getOrgSetups({ slug: currentOrg.slug, page: currentPageNumber }))
        dispatch(getOrgTeams(currentOrg.slug))
    }, [currentOrg.slug, dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (currentPageNumber > 1)
            dispatch(
                getOrgSetups({
                    slug: currentOrg.slug,
                    page: currentPageNumber,
                    filters: initialFilterValues,
                })
            )
    }, [currentPageNumber]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!modal) {
            setDataOnClick({})
            setForm(null)
        }
    }, [modal])

    const handleRemoveSetup = (data) => {
        dispatch(removeSetup(data)).then(({ error }) => {
            if (!error) {
                getCurrentPage()
                dispatch(
                    getOrgSetups({
                        slug: currentOrg.slug,
                        page: currentPageNumber,
                        filters: initialFilterValues,
                    })
                )
            }
            toggle()
        })
    }

    const validateDate = (date, defaultMessage) => {
        if (date.isValid()) return date.format('YYYY/MM/DD, HH:mm:ss')
        return defaultMessage || '-'
    }
    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase()
        setSearchText(value)
        api && api.onFilterChanged(value)
    }

    const RenderSetupManage = ({ item, form }) => (
        <div className="d-flex justify-content-center align-items-center">
            <img
                style={{ cursor: 'pointer' }}
                src="/svgs/organization/buttons/trash.svg"
                alt="trash"
                onClick={() => {
                    setDataOnClick(item)
                    setForm(form.remove)
                    setModal(true)
                }}
            />
            <img
                style={{
                    marginLeft: '1rem',
                    cursor: 'pointer',
                }}
                src="/svgs/trigger-actions/edit.svg"
                alt="edit"
                onClick={() => {
                    setDataOnClick(item)
                    setForm(form.edit)
                    setModal(true)
                }}
            />
        </div>
    )

    const setupData = useMemo(() => {
        return setupStore?.setups?.results?.map((setup) => {
            return {
                uuid: setup.uuid,
                name: setup.verbose_name || '-',
                team: setup.team.title || '-',
                type: setup.device_model ? 'Device' : 'Vehicle',
                teamObj: setup.team,
                pin: setup.pin || '-',
                used: <div>{validateDate(dayjs(setup.used), 'Never Used')} </div>,
                created_at: <div>{validateDate(dayjs(setup.created_at))} </div>,
                action: (
                    <RenderSetupManage
                        item={setup}
                        form={{ edit: 'edit-setup', remove: 'remove-setup' }}
                    />
                ),
            }
        })
    }, [setupStore.setups.results])

    const customDateComparator = (date1, date2) => {
        const d1 = new Date(date1.props.children[0].replace(',', ' ')) // Replace the comma with a space for a valid date format
        const d2 = new Date(date2.props.children[0].replace(',', ' '))

        if (d1 < d2) return -1
        if (d1 > d2) return 1
        return 0
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            headerClass: 'custom-header',
            sortable: true,
            unSortIcon: true,
        },
        {
            field: 'team',
            headerName: 'Team',
            valueFormatter: (params) => {
                return params.value
            },
            cellRenderer: (params) => {
                return (
                    <div
                        className="team-div-cell"
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                            history.push({
                                pathname: `/dashboard/organization/teams/${params.data.teamObj.slug}/`,
                                state: {
                                    slug: params.data.teamObj.slug,
                                    team: params.data.teamObj,
                                },
                            })
                        }
                    >
                        {params.data.team}
                    </div>
                )
            },
        },
        {
            field: 'type',
            headerName: 'Type',
        },
        {
            field: 'pin',
            headerName: 'PIN',
        },
        {
            field: 'used',
            headerName: 'Used at',
            sortable: true,
            comparator: customDateComparator,
            valueFormatter: (params) => {
                return params.value
            },
            cellRenderer: (params) => {
                return <div>{params.data?.used.props.children}</div>
            },
        },
        {
            field: 'created_at',
            headerName: 'Created at',
            valueFormatter: (params) => {
                return params.value
            },
            sortable: true,
            comparator: customDateComparator,
            cellRenderer: (params) => {
                return <div>{params.data?.created_at.props.children}</div>
            },
        },
        {
            field: 'action',
            headerName: '',
            cellClass: 'action-style',
            maxWidth: 100,
            valueFormatter: (params) => {
                return params.value
            },
            cellRenderer: (params) => {
                return <div>{params.data.action}</div>
            },
        },
    ]

    return (
        <div className="big-org-container">
            <div className="heading-container-org" style={{ marginTop: '32px' }}>
                <div>
                    <div className="mb-2 d-flex align-items-center" data-testid="renderText">
                        <span className="header-text-org">Fleet setups</span>
                        {setupStore.status === 'loaded' && (
                            <span
                                className="text-meili rounded px-2"
                                style={{ backgroundColor: '#F9EFFE', marginLeft: '24px' }}
                            >
                                {setupStore?.setups?.count} setups
                            </span>
                        )}
                    </div>
                    <span className="small-text-org">
                        An overview of all fleet setups in your organisation.
                    </span>
                </div>
                <div className="d-flex">
                    <Button
                        className="new-buttons-desing-org-purple org-page-btns text-white"
                        onClick={() => {
                            setForm('add-setup')
                            toggle()
                        }}
                        style={{ height: '40px' }}
                    >
                        <img src="/svgs/trigger-actions/add.svg" alt="plus" />
                        Add fleet setup
                    </Button>
                </div>
            </div>

            <div className="search-header">
                {' '}
                <input
                    type="text"
                    className="search-box"
                    placeholder="Search"
                    value={searchText}
                    onChange={handleSearch}
                ></input>
                <Filter
                    table="setups"
                    slug={currentOrg.slug}
                    initValues={initialFilterValues}
                    setInitValues={setInitialFilterValues}
                    setLastClickedPage={setLastClickedPage}
                    setCurrentPageNumber={setCurrentPageNumber}
                    dropdownOpen={dropdownOpen}
                    toggle={dropdownToggle}
                    teams={teams}
                />
            </div>
            {setupStore.status === 'loading' ? (
                <LoadingSpinner />
            ) : (
                <>
                    {[...initialFilterValues.team, ...initialFilterValues.type].length > 0 && (
                        <div className="d-flex align-items-start">
                            {[...initialFilterValues.team, ...initialFilterValues.type].map(
                                (item, index) => (
                                    <div
                                        key={index}
                                        className="filter-style d-flex align-items-center text-meili"
                                        style={{
                                            backgroundColor: '#F9EFFE',
                                            marginRight: '8px',
                                        }}
                                    >
                                        <HiOutlineX
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setInitialFilterValues((prevValues) => {
                                                    const newTeam = prevValues.team.filter(
                                                        (t) => t !== item
                                                    )
                                                    const newType = prevValues.type.filter(
                                                        (t) => t !== item
                                                    )

                                                    const updatedValues = {
                                                        team: newTeam,
                                                        type: newType,
                                                    }

                                                    dispatch(resetSetups())
                                                    setLastClickedPage(0)
                                                    setCurrentPageNumber(1)
                                                    dispatch(
                                                        getOrgSetups({
                                                            slug: currentOrg.slug,
                                                            filters: updatedValues,
                                                        })
                                                    )

                                                    return updatedValues
                                                })
                                            }}
                                        />
                                        {item}
                                    </div>
                                )
                            )}
                        </div>
                    )}
                    <div className="rounded-table-container ag-theme-alpine" Role="table">
                        {setupData?.length > 0 ? (
                            <AgGridReact
                                classes="my-custom-table"
                                keyField="uuid"
                                rowData={setupData}
                                columnDefs={columns}
                                defaultColDef={defaultColDef}
                                onGridReady={onGridReady}
                                rowHeight={70}
                                pagination={true}
                                paginationPageSize={10}
                                paginationPageSizeSelector={[10, 20, 50, 100]}
                                onPaginationChanged={getCurrentPage}
                                quickFilterText={searchText}
                                domLayout="autoHeight"
                                rowClassRules={{
                                    'gray-row': (params) => params.node.rowIndex % 2 === 1,
                                }}
                            />
                        ) : (
                            <h5 className="p-4">There are currently no fleet setups.</h5>
                        )}

                        {setupStore?.setups?.next &&
                            lastClickedPage === Math.ceil(setupData.length / 10) && (
                                <Button
                                    style={{
                                        position: 'absolute',
                                        bottom: '0.7rem',
                                        right: '24px',
                                        borderRadius: '8px',
                                        background: '#f9fafb',
                                        color: '#4e5462',
                                    }}
                                    onClick={() => setCurrentPageNumber(currentPageNumber + 1)}
                                >
                                    Load more (
                                    {setupStore?.setups?.count - setupStore?.setups?.results.length}
                                    )
                                </Button>
                            )}
                    </div>
                </>
            )}
            <ManageOrganizationModals
                slug={currentOrg?.slug}
                form={form}
                modal={modal}
                toggle={toggle}
                filters={initialFilterValues}
                setForm={setForm}
                dataOnClick={dataOnClick}
                submitDelete={handleRemoveSetup}
                currentPageNumber={currentPageNumber}
            />
        </div>
    )
}

export default FleetSetupsManage
