import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectors } from 'features/dashboard'
import IndoorMiniMap from './IndoorMiniMap'
import OutdoorMiniMap from './OutdoorMiniMap'
import { useDispatch } from 'react-redux'
import {
    getAreaMapSettings,
    GetCustomiseStationIcon,
    getMapAreaDetails,
    getMapAreaOfInterest,
} from 'features/dashboard/Map.actionTypes'

const MiniMap = ({
    getRobot,
    collapse,
    actions,
    sidebar,
    icon,
    setIcon,
    taskHovered,
    lastJsonMessage,
}) => {
    const dispatch = useDispatch()
    const { slug } = useParams()
    const team = useSelector(selectors.getTeam)
    const orgSlug = team.details.organization.slug
    const mapStore = useSelector(selectors.getTeamMap)
    const custom_icon = useSelector(selectors.getCustomIcon)
    const [status, setStatus] = useState('loading')
    const areaUuid = mapStore?.areas?.uuid
    const isOutsideMap = team.details?.operation_type === 'outside'
    const isInsideMap = team.details?.operation_type === 'inside'

    useEffect(() => {
        setStatus('loading')
        if (slug !== team.details?.slug) return
        isOutsideMap && setStatus('outside')

        if (isInsideMap) {
            Promise.all([
                dispatch(getMapAreaDetails(areaUuid)),
                dispatch(getMapAreaOfInterest(areaUuid)),
                dispatch(getAreaMapSettings({ uuid: areaUuid })),
            ]).then(([{ payload }]) => {
                if (payload) {
                    setStatus('inside')
                }
            })
            setStatus('error')
        }
    }, [slug, team.details]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(GetCustomiseStationIcon(orgSlug))
    }, [orgSlug]) // eslint-disable-line react-hooks/exhaustive-deps

    if (status === 'inside')
        return (
            <IndoorMiniMap
                getRobot={getRobot}
                sidebar={sidebar}
                actions={actions}
                customIcon={custom_icon}
                icon={icon}
                parentStatus={status}
                collapse={collapse}
                setIcon={setIcon}
                taskHovered={taskHovered}
                lastJsonMessage={lastJsonMessage}
            />
        )
    if (status === 'outside') return <OutdoorMiniMap />

    return <></>
}

export default MiniMap
