import React from 'react'
import Chart from 'react-apexcharts'
import { Card, CardBody } from 'reactstrap'

export const CharComponent = ({ labels, text, values, title }) => {
    const data = {
        series: [
            {
                name: 'data',
                data: values ? values : [0, 0],
            },
        ],
        options: {
            chart: {
                type: 'bar',
                stacked: true,
                width: '100%',
                toolbar: {
                    show: false,
                },
            },
            grid: {
                padding: {
                    bottom: -25, // Remove bottom padding
                },
            },
            colors: ['#BA4FFA', '#D99EF7'],
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 2,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                },
            },
            dataLabels: {
                enabled: false,
                formatter: (values) => {
                    // Convert seconds back to HH:MM:SS format for displaying
                    const hours = Math.floor(values / 3600)
                    const minutes = Math.floor((values % 3600) / 60)
                    const seconds = values % 60
                    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
                        2,
                        '0'
                    )}:${String(seconds).padStart(2, '0')}`
                },
            },
            stroke: {
                show: false,
                //   width: 2,
                colors: ['transparent'],
            },

            xaxis: {
                categories: labels,
            },
            yaxis: {
                labels: {
                    formatter: (values) => {
                        if (text !== 'Times (HH:MM:SS)') {
                            return values
                        } else {
                            const hours = Math.floor(values / 3600)
                            const minutes = Math.floor((values % 3600) / 60)
                            const seconds = Math.floor(values % 60)
                            return Math.round(hours) < 1
                                ? Math.round(minutes) + 'm'
                                : Math.round(minutes) < 1
                                ? Math.round(seconds) + 's'
                                : hours + 'h'
                        }
                    },
                },
                title: {
                    text: text,
                },
            },
            legend: {
                position: 'top',
                offsetX: 70,
            },

            tooltip: {
                y: {
                    formatter: (values) => {
                        if (text !== 'Times (HH:MM:SS)') {
                            return values
                        } else {
                            const hours = Math.floor(values / 3600)
                            const minutes = Math.floor((values % 3600) / 60)
                            const seconds = values % 60
                            return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
                                2,
                                '0'
                            )}:${String(seconds).padStart(2, '0')}`
                        }
                    },
                },
            },
        },
    }

    return (
        <Card className="card-container">
            <CardBody className="p-0 ">
                <h5 className="title-value"> {title} </h5>
                <Chart
                    options={data?.options}
                    series={data?.series}
                    type="bar"
                    height={210}
                    width="100%"
                />
            </CardBody>
        </Card>
    )
}
