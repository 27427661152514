import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Nav, NavItem, Popover } from 'reactstrap'
import NotificationCard from 'components/dashboard-components/NotificationsTitle'
import UserDashboardMenu from 'components/users/UserDashboardMenu'
import { selectors } from 'features/dashboard'
import NukeModal from './NukeModal'
import NotificationsWebSocket from '../../components/utils/notificationsWebSocket'
import RealTimeNotificationSwitch from './RealTimeNotificationSwitch'

const PageTitle = () => {
    const token = useSelector((state) => state.user.token)
    const { slug } = useParams()
    const user = useSelector(selectors.getUser)
    const [notificationOpen, setNotification] = useState(false)
    const [userMenu, setUserMenu] = useState(false)
    const [nukeModal, setNukeModal] = useState(false)
    const storedValueWSNotification = JSON.parse(localStorage.getItem('real_time_notification'))

    const toggle = () => setNotification((prevState) => !prevState)
    const toggleNuke = () => setNukeModal((prevState) => !prevState)

    return (
        <div
            className={`d-flex justify-content-end align-items-center p-3`}
            style={{ height: '50px', width: '100%' }}
        >
            <Nav>
                <NavItem className="d-flex align-items-center">
                    {localStorage.getItem('nuke') === 'stopped' ? (
                        <img
                            src="/svgs/page-title-icons/play.svg"
                            alt="nuke"
                            onClick={toggleNuke}
                            style={{ cursor: 'pointer', marginRight: '16px' }}
                        />
                    ) : (
                        <img
                            src="/svgs/page-title-icons/nuke.svg"
                            alt="nuke"
                            onClick={toggleNuke}
                            style={{ cursor: 'pointer', marginRight: '16px' }}
                        />
                    )}
                </NavItem>
                <NavItem className="pr-2 d-flex align-items-center">
                    <img
                        id="notifications"
                        src="/svgs/page-title-icons/notification.svg"
                        alt="notifications"
                        style={{ cursor: 'pointer' }}
                    />
                    {storedValueWSNotification && (
                        <NotificationsWebSocket token={token} toggle={toggle} />
                    )}
                    <Popover
                        hideArrow={true}
                        popperClassName="notifications-popover"
                        target="notifications"
                        toggle={toggle}
                        placement="left"
                        trigger="legacy"
                        isOpen={notificationOpen}
                        container="body"
                    >
                        <NotificationCard toggle={toggle} notificationOpen={notificationOpen} />
                    </Popover>
                </NavItem>
                <NavItem className="d-flex align-items-center">
                    <RealTimeNotificationSwitch />
                </NavItem>
                <NavItem className="d-flex align-items-center" style={{ marginLeft: '16px' }}>
                    {user.profile.image ? (
                        <div
                            className="rounded-circle"
                            style={{
                                width: '48px',
                                height: '48px',
                                overflow: 'hidden',
                            }}
                        >
                            <img
                                id="userMenu"
                                src={user.profile.image}
                                alt="user"
                                width={48}
                                height={48}
                                style={{
                                    objectFit: 'cover',
                                    width: '100%',
                                    height: '100%',
                                    cursor: 'pointer',
                                }}
                            />
                        </div>
                    ) : (
                        <img
                            id="userMenu"
                            src={'/svgs/page-title-icons/user.svg'}
                            alt="user"
                            width={48}
                            height={48}
                            style={{ cursor: 'pointer' }}
                        />
                    )}
                    <Popover
                        popperClassName="popover-full-width rounded border-0"
                        target="userMenu"
                        toggle={() => setUserMenu(!userMenu)}
                        placement="auto"
                        trigger="legacy"
                        isOpen={userMenu}
                    >
                        <UserDashboardMenu />
                    </Popover>
                </NavItem>
            </Nav>
            <NukeModal nukeModal={nukeModal} toggleNuke={toggleNuke} slug={slug} />
        </div>
    )
}

export default PageTitle
