import { actionTypes } from './TeamAnalytics.actionTypes'
import dayjs from 'dayjs'

export const teamAnalyticsState = {
    status: 'loading',
    cardStatus: 'loading',
    delete: '',
    filterdata: {},
    updatedAt: null,
    cardData: {},
    cardlist: {},
    dashboardView: {},
    dashboards: [],
}

export const teamAnalyticsReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.GET_DASHBOARD_CARD:
            return {
                ...state,
                updatedAt: null,
                status: 'loading',
            }
        case actionTypes.FILTER_DASHBOARD:
            return {
                ...state,
                updatedAt: null,
                status: 'loading',
            }

        case actionTypes.GET_DASHBOARD_CARD_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                updatedAt: dayjs(),
                cardData: action.payload.data.dashboard_cards,
            }
        case actionTypes.GET_DASHBOARD_CARD_FAIL:
            return {
                ...state,
                status: 'error',
            }

        case actionTypes.ADD_DASHBOARD_CARD_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                filterdata: [...state.filterdata.dashboard_cards, action.payload.data],
            }
        case actionTypes.CREATE_DASHBOARD_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                dashboards: [...state?.dashboards, action.payload?.data],
            }
        case actionTypes.FILTER_DASHBOARD_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                filterdata: action.payload.data.dashboard_cards,
            }

        case actionTypes.GET_DASHBOARD_VIEW:
            return {
                ...state,
                updatedAt: null,
                status: 'loading',
            }

        case actionTypes.GET_DASHBOARD_VIEW_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                updatedAt: dayjs(),
                dashboardView: action.payload?.data.results,
            }
        case actionTypes.GET_DASHBOARD_VIEW_FAIL:
            return {
                ...state,
                status: 'error',
            }

        case actionTypes.GET_All_CARD:
            return {
                ...state,
                updatedAt: null,
                status: 'loading',
            }

        case actionTypes.GET_All_CARD_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                cardlist: action.payload.data.results,
            }
        case actionTypes.DELETE_DASHBOARD_CARD_SUCCESS:
            return {
                ...state,
                delete: action.payload.data.deleted,
                filterdata: state.filterdata?.filter((card) => card.uuid !== action.payload.data.uuid),
            }

        case actionTypes.DELETE_DASHBOARD_VIEW_SUCCESS:
            return {
                ...state,
                status: 'deleted',
                dashboardView: state.dashboardView?.filter(
                    (view) => view.uuid !== action.payload.data.uuid
                ),
            }

        case actionTypes.UPDATE_DASHBOARD_SUCCESS:
            return {
                ...state,
                status: 'updated',
                dashboardView: state.dashboardView.map((dash) => {
                    if (dash.uuid === action.payload.data.uuid) return action.payload.data
                    return dash
                }),
            }

        default:
            return state
    }
}
