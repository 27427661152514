import React from 'react'
import { BsArrowUpShort, BsArrowDownShort, BsArrowRightShort } from 'react-icons/bs'
import { Card, CardBody } from 'reactstrap'

export const CardComponent = ({ title, value, unit, comparison, selectedDays }) => {
    const { difference, isIncrease, isEqual } = comparison || {}
    const convertSeconds = (value) => {
        if (value === 0 || null) return 0
        const hours = Math.floor(value / 3600)
        const minutes = Math.floor((value % 3600) / 60)
        const seconds = value % 60
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
            Math.round(seconds)
        ).padStart(2, '0')}`
    }

    return (
        <Card className="card-container">
            <CardBody className="p-0">
                <div className="d-flex justify-content-between position-relative">
                    <div>
                        <h5 className="title-value">{title}</h5>
                        <h2 className="text py-3">
                            {isNaN(value)
                                ? 0
                                : title === 'Total on task time' || title === 'Average on task time'
                                ? convertSeconds(value)
                                : Math.round(value)}
                            {unit && unit}
                        </h2>
                        <small className="small-text">
                            {isEqual ? (
                                <span size={20} className="equal-data mr-1">
                                    <BsArrowRightShort /> no change
                                </span>
                            ) : isIncrease ? (
                                <BsArrowUpShort size={20} className="text-success" />
                            ) : (
                                <BsArrowDownShort size={20} className="text-danger" />
                            )}
                            {difference !== null ? (
                                title === 'Total on task time' || title === 'Average on task time'? (
                                    `${convertSeconds(difference)} ${unit || ''}`
                                ) : (
                                    `${Math.abs(difference)} ${unit || ''}`
                                )
                            ) : (
                                <span size={20} className="equal-data mr-1">
                                    no change
                                </span>
                            )}{' '}
                            <span> vs last {selectedDays} days</span>
                        </small>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}
