import React from 'react'
import * as Sentry from '@sentry/react'

import App from './App'
import * as serviceWorker from './serviceWorker'

import { Provider } from 'react-redux'
import configureStore from './store'

import 'ag-grid-community/styles/ag-grid.css' // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './i18n'
import './assets/base.scss'
import './assets/styles.scss'

// New import for createRoot
import { createRoot } from 'react-dom/client'
import { SENTRY_DSN, RELEASE, SENTRY_SAMPLE_RATE,SENTRY_TRACES_SAMPLE_RATE,SENTRY_DEBUG } from 'config'

const store = configureStore()

Sentry.init({   
    dsn: SENTRY_DSN,
    release: RELEASE,   
    sampleRate: SENTRY_SAMPLE_RATE,
    tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
    debug: SENTRY_DEBUG ,
    autoSessionTracking: true,
    integrations: [Sentry.browserTracingIntegration()],
    environment: 'development',
})
const error = console.error
console.error = (...args) => {
    if (/defaultProps/.test(args[0]) || /findDOMNode/.test(args[0])) return

    error(...args)
}

const rootElement = document.getElementById('root')

// New way to render the app using createRoot
if (rootElement) {
    const root = createRoot(rootElement)
    root.render(
        <Sentry.ErrorBoundary>
            <Provider store={store}>
                
                <App />
            </Provider>
        </Sentry.ErrorBoundary>
    )
}

// Service worker configuration
serviceWorker.unregister()
