import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Image as KonvaImage, Text } from 'react-konva'
import { Html } from 'react-konva-utils'
import { Modal, ModalBody, Button } from 'reactstrap'
import useImage from 'use-image'
import { useToggler } from 'utils'
import { updateVehicle } from 'features/dashboard/Vehicle.actionTypes'

const VehiclePointer = ({
    details,
    vehicle,
    handlePointSubmitSelect,
    selectStation,
    getRobot,
    sizeText,
    taskHovered,
    selectedRobot,
    setSelectedRobot,
    setRobotHovered,
    showVehiclesNames,
    setDataHovered,
    estimatedPose,
    robotSelectedEstPose,
    setRobotSelectedEstPose,
}) => {
    const dispatch = useDispatch()
    const robotRef = useRef()
    const [robotImage] = useImage('/svgs/map-layer/robot-default.svg')
    const [robotImageHighlighted] = useImage('/svgs/map-layer/robot-hover.svg')
    const [waitingForPoseRobot] = useImage('/svgs/map-layer/waiting_for_pose_amr.svg')
    const [robotLoaded] = useImage('/svgs/map-layer/robot-loaded.svg')
    const [forkliftLoaded] = useImage('/svgs/map-layer/forklift-loaded.svg')
    const [waitingForPoseRobotDark] = useImage('/svgs/map-layer/waiting_for_pose_dark_amr.svg')
    const [waitingForPoseRobotFork] = useImage('/svgs/map-layer/waiting_pose.svg')
    const [waitingForPoseRobotDarkFork] = useImage('/svgs/map-layer/waiting_for_pose_dark.svg')
    const [robotCollision] = useImage('/svgs/map-layer/robot-collision.svg')
    const [forkliftImage] = useImage('/svgs/map-layer/forklift.svg')
    const [forkliftImageHighlighted] = useImage('/svgs/map-layer/forklift-hover.svg')
    const [forkliftCollision] = useImage('/svgs/map-layer/forklift-collision.svg')
    const [robotEmergencyYellow] = useImage('/svgs/map-layer/emergency-stop-robot-yellow.svg')
    const [robotEmergencyOrange] = useImage('/svgs/map-layer/emergency-stop-robot-orange.svg')
    const [forkEmergencyYellow] = useImage('/svgs/map-layer/emergency-stop-fork-yellow.svg')
    const [forkEmergencyOrange] = useImage('/svgs/map-layer/emergency-stop-fork-orange.svg')
    const [forkNonManageable] = useImage('/svgs/map-layer/agv-non-manageable.svg')
    const [forkNonManageableEmergencyOrange] = useImage(
        '/svgs/map-layer/agv-non-manageable-emergency-orange.svg'
    )
    const [forkNonManageableEmergencyYellow] = useImage(
        '/svgs/map-layer/agv-non-manageable-emergency-yellow.svg'
    )
    const [rtls] = useImage('/svgs/map-layer/rtls.svg')
    const [rtlsEmergencyOrange] = useImage('/svgs/map-layer/rtls-emergency-orange.svg')
    const [rtlsEmergencyYellow] = useImage('/svgs/map-layer/rtls-emergency-yellow.svg')
    const [visualize] = useImage('/svgs/map-layer/visualize.svg')
    const [visualizeLoaded] = useImage('/svgs/map-layer/visualize-loaded.svg')
    // const [robotImageHovered] = useImage('/svgs/robot-icon-white-background-connected-hover-pink.svg')
    const [modal, toggleModal] = useToggler()
    const [waitingForPoseRobotAmr, setWaitingForPoseRobotAmr] = useState(waitingForPoseRobot)
    const [waitingPoseRobotFork, setWaitingPoseRobotFork] = useState(waitingForPoseRobotFork)
    const [currentRobotEmergency, setCurrentRobotEmergency] = useState(robotEmergencyYellow)
    const [currentForkEmergency, setCurrentForkEmergency] = useState(forkEmergencyYellow)
    const [currentRtlsEmergency, setCurrentRtlsEmergency] = useState(rtlsEmergencyYellow)
    const [agvNonManageableEmergency, setAgvNonManageableEmergency] = useState(
        forkNonManageableEmergencyYellow
    )
    const [robotHoveredTask, setRobotHoveredTask] = useState(false)
    const [forkHoveredTask, setForkHoveredTask] = useState(false)

    const handleSubmit = (uuid) => {
        const data = { uuid, is_collision_detected: false }
        dispatch(updateVehicle(data)).then(({ error }) => {
            if (error) return
        })
    }

    useEffect(() => {
        let interval
        if (vehicle?.vehicle_state === 'emergency_stop') {
            interval = setInterval(() => {
                setCurrentRobotEmergency((prev) =>
                    prev === robotEmergencyYellow ? robotEmergencyOrange : robotEmergencyYellow
                )
                setCurrentForkEmergency((prev) =>
                    prev === forkEmergencyYellow ? forkEmergencyOrange : forkEmergencyYellow
                )
                setCurrentRtlsEmergency((prev) =>
                    prev === rtlsEmergencyYellow ? rtlsEmergencyOrange : rtlsEmergencyYellow
                )
                setAgvNonManageableEmergency((prev) =>
                    prev === forkNonManageableEmergencyYellow
                        ? forkNonManageableEmergencyOrange
                        : forkNonManageableEmergencyYellow
                )
            }, 750)
        } else {
            setCurrentRobotEmergency(robotEmergencyYellow)
            setCurrentForkEmergency(forkEmergencyYellow)
        }

        if (vehicle?.vehicle_state === 'waiting_for_pose') {
            interval = setInterval(() => {
                setWaitingForPoseRobotAmr((prev) =>
                    prev === waitingForPoseRobot ? waitingForPoseRobotDark : waitingForPoseRobot
                )
                setWaitingPoseRobotFork((prev) =>
                    prev === waitingForPoseRobotFork
                        ? waitingForPoseRobotDarkFork
                        : waitingForPoseRobotFork
                )
            }, 750)
        } else {
            setWaitingPoseRobotFork(waitingForPoseRobotFork)
        }

        return () => clearInterval(interval)
    }, [
        vehicle?.vehicle_state,
        robotEmergencyYellow,
        robotEmergencyOrange,
        forkEmergencyYellow,
        forkEmergencyOrange,
        rtlsEmergencyYellow,
        rtlsEmergencyOrange,
        forkNonManageableEmergencyYellow,
        forkNonManageableEmergencyOrange,
        waitingForPoseRobot,
        waitingForPoseRobotDark,
        waitingForPoseRobotFork,
        waitingForPoseRobotDarkFork,
    ])

    useEffect(() => {
        let interval
        if (taskHovered === vehicle?.verbose_name) {
            interval = setInterval(() => {
                setRobotHoveredTask((prev) =>
                    prev === robotImageHighlighted ? robotImage : robotImageHighlighted
                )
                setForkHoveredTask((prev) =>
                    prev === forkliftImageHighlighted ? forkliftImage : forkliftImageHighlighted
                )
            }, 200) // Short interval for strobe effect (200ms or adjust as needed)
        } else {
            setRobotHoveredTask(robotImage) // Reset to default image
            setForkHoveredTask(forkliftImage) // Reset to default image
        }
        return () => clearInterval(interval)
    }, [
        robotImage,
        taskHovered,
        forkliftImage,
        vehicle?.verbose_name,
        robotImageHighlighted,
        forkliftImageHighlighted,
    ])

    const showImage = () => {
        if (vehicle?.vehicle_type === 'amr') {
            switch (true) {
                case vehicle?.is_collision_detected:
                    return robotCollision
                case estimatedPose && !robotSelectedEstPose:
                case selectStation:
                case selectedRobot === vehicle?.uuid:
                    return robotImageHighlighted
                case taskHovered === vehicle?.verbose_name:
                    return robotHoveredTask
                case vehicle?.vehicle_state === 'waiting_for_pose':
                    return waitingForPoseRobotAmr
                case vehicle?.vehicle_state === 'emergency_stop':
                    return currentRobotEmergency
                case vehicle?.is_loaded:
                    return robotLoaded
                default:
                    return robotImage
            }
        }

        if (vehicle?.vehicle_type === 'agv_manageable') {
            switch (true) {
                case vehicle?.is_collision_detected:
                    return forkliftCollision
                case estimatedPose && !robotSelectedEstPose:
                case selectStation:
                case selectedRobot === vehicle?.uuid:
                    return forkliftImageHighlighted
                case taskHovered === vehicle?.verbose_name:
                    return forkHoveredTask
                case vehicle?.vehicle_state === 'emergency_stop':
                    return currentForkEmergency
                case vehicle?.vehicle_state === 'waiting_for_pose':
                    return waitingPoseRobotFork
                case vehicle?.is_loaded:
                    return forkliftLoaded
                default:
                    return forkliftImage
            }
        }

        if (vehicle?.vehicle_type === 'agv_non_manageable') {
            switch (true) {
                case vehicle?.vehicle_state === 'emergency_stop':
                    return agvNonManageableEmergency
                default:
                    return forkNonManageable
            }
        }

        if (vehicle?.vehicle_type === 'rtls') {
            switch (true) {
                case vehicle?.vehicle_state === 'emergency_stop':
                    return currentRtlsEmergency
                default:
                    return rtls
            }
        }

        if (vehicle?.vehicle_type === 'visualize') {
            if(vehicle?.is_loaded) return visualizeLoaded
            return visualize
        }
    }

    const formatBatteryLevel = (vehicle) => {
        if (vehicle && vehicle.battery_level !== null && vehicle.battery_level !== undefined) {
            return parseFloat(vehicle.battery_level).toFixed(2) + '%'
        } else {
            return 'N/A'
        }
    }

    const formatCoordinate = (coord) => {
        return details && coord !== null && coord !== undefined
            ? parseFloat(coord).toFixed(2) + 'm'
            : 'N/A'
    }

    const name = vehicle && vehicle.verbose_name ? vehicle.verbose_name : ''
    const type = vehicle && vehicle.vehicle_type ? vehicle.vehicle_type : ''
    const state = vehicle && vehicle.vehicle_state ? vehicle.vehicle_state : ''
    const battery = formatBatteryLevel(vehicle)
    const xText = formatCoordinate(details?.xm)
    const yText = formatCoordinate(details?.ym)
    const speed = parseFloat(details?.speed).toFixed(2)
    const dataToShow = { name, type, state, battery, x: xText, y: yText, speed }

    if (vehicle?.is_online === false) return null

    return (
        <>
            {details && (
                <KonvaImage
                    image={showImage()}
                   //zIndex={1000}
                    ref={robotRef}
                    x={details?.xd}
                    y={details?.yd}
                    width={vehicle?.dimensions_in_pixels[1]}
                    height={vehicle?.dimensions_in_pixels[1]}
                    offsetX={vehicle?.dimensions_in_pixels[1] / 2 || 0}
                    offsetY={vehicle?.dimensions_in_pixels[1] / 2 || 0}
                    onClick={() => {
                        const { vehicle_type, uuid, is_collision_detected } = vehicle || {}

                        if (
                            vehicle_type === 'agv_non_manageable' ||
                            vehicle_type === 'rtls' ||
                            vehicle_type === 'visualize'
                        )
                            return

                        if (selectStation && !estimatedPose) {
                            handlePointSubmitSelect(selectStation, uuid)
                            return
                        }

                        if (!selectStation && !estimatedPose) {
                            if (is_collision_detected) {
                                toggleModal()
                            } else {
                                setSelectedRobot(uuid)
                            }
                        }

                        if (!selectStation && !vehicle.is_collision_detected && estimatedPose) {
                            setRobotSelectedEstPose(vehicle?.uuid)
                        }
                    }}
                    onMouseOver={(e) => {
                        getRobot(vehicle?.verbose_name)
                        const container = e.target.getStage().container()
                        setRobotHovered(true)
                        setDataHovered(dataToShow)
                        if (
                            vehicle?.vehicle_type === 'agv_manageable' ||
                            vehicle?.vehicle_type === 'amr'
                        ) {
                            container.style.cursor = 'pointer'
                        }
                    }}
                    onMouseLeave={(e) => {
                        getRobot()
                        setRobotHovered(false)
                        setDataHovered(null)
                        const container = e.target.getStage().container()
                        container.style.cursor = 'default'
                    }}
                    opacity={1}
                    rotation={!details ? 0 : -270 - details?.rotation}
                />
            )}

            <Text
                x={details?.xd}
                y={details?.yd - 10}
                offsetX={vehicle?.dimensions_in_pixels[1] / 2 || 0}
                offsetY={vehicle?.dimensions_in_pixels[1] / 2 || 0}
                stroke="#670d95"
                strokeWidth={0.1}
                text={showVehiclesNames ? vehicle.verbose_name : ''}
                fontStyle="bold"
                fontSize={sizeText}
            />

            <Html>
                <Modal isOpen={modal} toggle={toggleModal} centered className="new-modals">
                    <ModalBody>
                        <div
                            style={{ marginBottom: '24px' }}
                            className={`w-100 d-flex justify-content-between`}
                        >
                            <img
                                src="/svgs/task-modal/smiley-robot.svg"
                                alt="plus"
                                width="48px"
                                height="48px"
                            />

                            <img
                                src="/svgs/close-icon/x-dark-default.svg"
                                alt="x"
                                width="24px"
                                height="24px"
                                style={{ cursor: 'pointer' }}
                                onClick={toggleModal}
                            />
                        </div>
                        <span className="modals-little-head">Turn off proximity alert</span>
                        <br />
                        <span className="modals-description">
                            If you click 'Turn off', {vehicle?.verbose_name} will resume its task.
                        </span>

                        <div
                            className="d-flex align-items-center justify-content-end"
                            style={{ marginTop: '36px' }}
                        >
                            <Button
                                style={{ marginRight: '24px' }}
                                color="none"
                                className="modals-delete-btn"
                                onClick={toggleModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="meili"
                                className="modals-save-btn new-modals-btn"
                                onClick={() => {
                                    handleSubmit(vehicle?.uuid)
                                    toggleModal()
                                }}
                            >
                                Turn off
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
            </Html>
        </>
    )
}

export default VehiclePointer
