import React, { useEffect, useState } from 'react'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import { deleteCustomIcon } from 'features/dashboard/Map.actionTypes'
import { HiOutlineTrash } from 'react-icons/hi'

const CustomStationIcon = ({
    selected,
    setPreview,
    preview,
    options,
    setFieldValue,
    fieldValue,
    iconColor,
    defaultIcon,
}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        preview && setFieldValue('custom_icon', preview.uuid)
    }, [preview, setFieldValue])

    const [svgUpdated, setSvgUpdated] = useState('')
    const updateSvgStyles = async () => {
        const svgURL =
            selected?.system_generated === true
                ? selected?.icon
                : '/svgs/map-layer/station-default.svg'

        const response = await fetch(svgURL)
        let svgText = await response.text()
        svgText = svgText.replace(/stroke="[^"]+"/g, `stroke="${iconColor}"`)
        svgText = svgText.replace(/fill="#BA4FFA"/g, `fill="${iconColor}"`)
        setSvgUpdated(svgText)
    }
    useEffect(() => {
        updateSvgStyles(iconColor)
    }, [iconColor]) // eslint-disable-line react-hooks/exhaustive-deps

    if (!options) return

    return (
        <UncontrolledDropdown className="w-100" data-testid="dropDown">
            <DropdownToggle
                style={{
                    border: '1px solid var(--grey-100, #e7e9ec)',
                    borderRadius: '8px',
                    padding: '8px 12px',
                    fontSize: '16px',
                    height: '40px',
                    width: '100%',
                    background: 'white',
                    color: '#3d414c',
                    textAlign: 'left',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <span data-testid="selected" className="grey-400">
                    {preview ? (
                        <img
                            src={preview.icon}
                            alt="Selected"
                            style={{
                                maxWidth: '25px',
                                height: '25px',
                                marginRight: '8px',
                            }}
                        />
                    ) : selected?.icon !== undefined || null ? (
                        selected.system_generated ? (
                            <span dangerouslySetInnerHTML={{ __html: svgUpdated }} />
                        ) : (
                            <img
                                src={selected?.icon}
                                alt="Selected"
                                style={{
                                    maxWidth: '25px',
                                    height: '25px',
                                    marginRight: '8px',
                                }}
                            />
                        )
                    ) : iconColor !== '#BA4FFA' ? (
                        <span dangerouslySetInnerHTML={{ __html: svgUpdated }} />
                    ) : (
                        <img
                            src={defaultIcon}
                            alt="Selected"
                            style={{
                                maxWidth: '25px',
                                height: '25px',
                                marginRight: '8px',
                            }}
                        />
                    )}
                    <span className="ml-1">
                        {preview
                            ? preview.title
                            : selected?.title !== undefined || null
                            ? selected.title
                            : 'Default'}
                    </span>
                </span>
                <FontAwesomeIcon color="black" size="xs" icon={faChevronDown} />
            </DropdownToggle>
            <DropdownMenu
                data-testid="testmenu"
                className="col-12 scrolable mb-5"
                style={{ overflow: 'auto', zIndex: 1000, maxHeight: '150px' }}
            >
                {options?.map(
                    (item) =>
                        item && (
                            <DropdownItem
                                key={item.uuid}
                                value={preview ? preview.uuid : item.uuid}
                                onClick={() => {
                                    setPreview(item)
                                    setFieldValue(fieldValue, item.uuid)
                                }}
                            >
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <img
                                            src={item.icon}
                                            alt="Selected"
                                            style={{
                                                maxWidth: '25px',
                                                height: '25px',
                                                marginRight: '8px',
                                            }}
                                        />

                                        <span className="ml-3">{item.title}</span>
                                    </div>
                                    {!item.system_generated && (
                                        <HiOutlineTrash
                                            size={20}
                                            className=""
                                            onClick={() => dispatch(deleteCustomIcon(item.uuid))}
                                        />
                                    )}
                                </div>
                            </DropdownItem>
                        )
                )}
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

export default CustomStationIcon
