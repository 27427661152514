import React, { useState, useRef } from "react";

const AuthCodeInput = ({ length = 6, onComplete, setFieldValue }) => {
  const [values, setValues] = useState(Array(length).fill(""));
  const inputsRef = useRef([]);

  const handleChange = (e, index) => {
    const { value } = e.target;
    if (!/^[0-9]*$/.test(value)) return; // Only allow numbers

    const newValues = [...values];
    newValues[index] = value.slice(-1); // Only take the last digit
    setValues(newValues);

    // Move to the next input
    if (value && index < length - 1) {
      inputsRef.current[index + 1].focus();
    }

    // Trigger onComplete if all fields are filled
    if (newValues.every((v) => v.length === 1)) {
      onComplete && onComplete(newValues.join(""), setFieldValue);
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && values[index] === "" && index > 0) {
      inputsRef.current[index - 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").slice(0, length);
    const newValues = pasteData.split("");
    while (newValues.length < length) {
      newValues.push("");
    }
    setValues(newValues);

    // Focus the last non-empty input
    const lastIndex = Math.min(pasteData.length, length) - 1;
    inputsRef.current[lastIndex].focus();

    if (newValues.every((v) => v.length === 1)) {
      onComplete && onComplete(newValues.join(""), setFieldValue);
    }
  };

  return (
    <div className="auth-code-input" onPaste={handlePaste}>
      {values.map((value, index) => (
        <input
          key={index}
          type="text"
          placeholder="0"
          maxLength="1"
          autoFocus={index === 0}
          value={value}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(el) => (inputsRef.current[index] = el)}
        />
      ))}
    </div>
  );
};

export default AuthCodeInput;
