import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form, FormGroup, Label, Modal, ModalBody, Spinner } from 'reactstrap'
import { Field, Formik } from 'formik'
import { selectors } from 'features/dashboard'
import { changePassword, delete2FA, get2FASecret } from 'features/user/actionTypes'
import { PasswordUpdateSchema } from 'schemas/user'
import { customInputForm } from 'ui/common'
import TwoFactorAuthIntro from './TwoFactorAuthIntro'

const Security = ({ twoFactorEnabled, setTwoFactorEnabled }) => {
    const dispatch = useDispatch()
    const user = useSelector(selectors.getUser)
    const [showOldPass, setShowOldPass] = useState(false)
    const [showNewPass, setShowNewPass] = useState(false)
    const [showRepeatPass, setShowRepeatPass] = useState(false)
    const [modal, setModal] = useState(false)

    const toggle = () => setModal(!modal)

    const handleSubmit = ({ data, setErrors, resetForm }) => {
        dispatch(changePassword({ data })).then(({ error }) => {
            if (error) {
                setErrors(error.response.data)
            } else {
                resetForm()
            }
        })
    }

    return (
        <div
            className="d-flex align-items-start justify-content-start w-100 h-100"
            style={{ gap: '32px' }}
        >
            <div
                className="d-flex flex-column align-items-start mt-5 w-50 h-100"
                style={{ paddingRight: '32px', borderRight: '1px solid #E7E9EC' }}
            >
                <div>
                    <h4>Password</h4>
                    <span>Please enter your current password to update your password.</span>
                </div>
                <hr className="custom-hr" />
                <Formik
                    initialValues={{
                        old_password: '',
                        new_password: '',
                        password_confirm: '',
                    }}
                    validationSchema={PasswordUpdateSchema}
                    onSubmit={(data, { setErrors, resetForm }) => {
                        handleSubmit({ data, setErrors, resetForm })
                    }}
                >
                    {({ values, isSubmitting, handleSubmit }) => (
                        <Form
                            className="w-100"
                            onSubmit={(e) => {
                                e.preventDefault()
                                handleSubmit()
                            }}
                        >
                            <Label for="old_password" className="user-settings-label">
                                Current password
                            </Label>

                            <FormGroup className="d-flex align-items-center w-100">
                                <Field
                                    id="old_password"
                                    component={customInputForm}
                                    type={showOldPass ? 'text' : 'password'}
                                    name="old_password"
                                    placeholder="*********"
                                    style={{
                                        border: '1px solid #E7E9EC',
                                        borderRadius: '8px',
                                        padding: '8px 12px',
                                        fontSize: '16px',
                                        height: '36px',
                                        width: '100%',
                                    }}
                                    value={values.old_password || ''}
                                />
                                <img
                                    src={
                                        showOldPass
                                            ? '/svgs/user-icon/eyeOn.svg'
                                            : '/svgs/user-icon/eyeOff.svg'
                                    }
                                    alt="eye"
                                    style={{ marginLeft: '-2rem', cursor: 'pointer' }}
                                    onClick={() => setShowOldPass(!showOldPass)}
                                />
                            </FormGroup>

                            <Label for="new-password" className="user-settings-label">
                                New password
                            </Label>
                            <FormGroup className="d-flex flex-column align-items-start w-100">
                                <div className="d-flex align-items-center w-100">
                                    <Field
                                        id="new-password"
                                        autocomplete="new-password"
                                        name="new_password"
                                        type={showNewPass ? 'text' : 'password'}
                                        component={customInputForm}
                                        style={{
                                            border: '1px solid #E7E9EC',
                                            borderRadius: '8px',
                                            padding: '8px 12px',
                                            fontSize: '16px',
                                            height: '36px',
                                            width: '100%',
                                        }}
                                        value={values.new_password || ''}
                                    />
                                    <img
                                        src={
                                            showNewPass
                                                ? '/svgs/user-icon/eyeOn.svg'
                                                : '/svgs/user-icon/eyeOff.svg'
                                        }
                                        alt="eye"
                                        style={{ marginLeft: '-2rem', cursor: 'pointer' }}
                                        onClick={() => setShowNewPass(!showNewPass)}
                                    />
                                </div>
                                <span
                                    style={{
                                        lineHeight: '20px',
                                        fontSize: '14px',
                                    }}
                                >
                                    Your new password must be at least 8 characters.
                                </span>
                            </FormGroup>

                            <Label for="password_confirm" className="user-settings-label">
                                Repeat new password
                            </Label>
                            <FormGroup className="d-flex align-items-center w-100">
                                <Field
                                    id="password_confirm"
                                    type={showRepeatPass ? 'text' : 'password'}
                                    name="password_confirm"
                                    component={customInputForm}
                                    style={{
                                        border: '1px solid #E7E9EC',
                                        borderRadius: '8px',
                                        padding: '8px 12px',
                                        fontSize: '16px',
                                        height: '36px',
                                        width: '100%',
                                    }}
                                    value={values.password_confirm || ''}
                                />
                                <img
                                    src={
                                        showRepeatPass
                                            ? '/svgs/user-icon/eyeOn.svg'
                                            : '/svgs/user-icon/eyeOff.svg'
                                    }
                                    alt="eye"
                                    style={{ marginLeft: '-2rem', cursor: 'pointer' }}
                                    onClick={() => setShowRepeatPass(!showRepeatPass)}
                                />
                            </FormGroup>

                            <div className="d-flex justify-content-end mr-3 my-4">
                                <Button
                                    type="submit"
                                    className="save-btn-modal modals-new-btns"
                                    disabled={
                                        user.status === 'loading' ||
                                        JSON.stringify({
                                            old_password: '',
                                            new_password: '',
                                            password_confirm: '',
                                        }) === JSON.stringify(values)
                                    }
                                >
                                    Update password
                                    {isSubmitting && <Spinner size="sm" color="white ml-2" />}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            <div className="d-flex flex-column align-items-start mt-5 w-50 h-100">
                <TwoFactorAuthIntro twoFactorEnabled={twoFactorEnabled} toggle={toggle} />
            </div>

            <Modal isOpen={modal} toggle={toggle} centered className="new-modals">
                <ModalBody>
                    <div
                        className="w-100 d-flex justify-content-between"
                        style={{ marginBottom: '24px' }}
                    >
                        <img
                            src="/svgs/modal-icons/security.svg"
                            alt="obstacle-icon"
                            width="48px"
                            height="48px"
                        />
                        <img
                            src="/svgs/close-icon/x-dark-default.svg"
                            alt="obstacle-icon"
                            width="24px"
                            height="24px"
                            style={{ cursor: 'pointer' }}
                            onClick={toggle}
                        />
                    </div>
                    Are you sure you want to disable two-factor authentication? You will no longer
                    be required to enter a verification code when logging in.
                    <div
                        style={{ marginTop: '24px' }}
                        className={`d-flex w-100 justify-content-between align-items-center`}
                    >
                        <Button
                            className="cancel-btn-modal modals-new-btns w-50 mr-2"
                            onClick={toggle}
                        >
                            No
                        </Button>
                        <Button
                            className="save-btn-modal modals-new-btns w-50"
                            onClick={() => {
                                dispatch(delete2FA()).then((response) => {
                                    if (!response.error)
                                        dispatch(get2FASecret()).then((response) => {
                                            if (!response.error)
                                                setTwoFactorEnabled(response.payload.data.enabled)
                                        })
                                })
                                toggle()
                            }}
                        >
                            Yes
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default Security
