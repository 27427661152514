import React from 'react'
import Chart from 'react-apexcharts'
import { Card, CardBody } from 'reactstrap'
import { CardComponent } from './CardComponent'
import { calculateComparison, getCardData } from './DashboardUtils'
import { CharComponent } from './ChartComponent'

export const TotalMissions = ({ filteredData, selectedDays }) => {
    const cardData = getCardData(filteredData, 'Total missions')
    const value = cardData?.[0]?.value || 0
    const compValue = cardData?.[0]?.comparison?.value || 0
    return (
        <CardComponent
            title="Total missions"
            value={value}
            unit={null}
            comparison={calculateComparison(value, compValue)}
            selectedDays={selectedDays}
        />
    )
}
export const TotalDeliveries = ({ filteredData, selectedDays }) => {
    const cardData = getCardData(filteredData, 'Total deliveries')
    const value = cardData?.[0]?.value || 0
    const compValue = cardData?.[0]?.comparison?.value || 0

    return (
        <CardComponent
            title="Total deliveries"
            value={value}
            unit={null}
            comparison={calculateComparison(value, compValue)}
            selectedDays={selectedDays}
        />
    )
}
export const TotalErrors = ({ filteredData, selectedDays }) => {
    const cardData = getCardData(filteredData, 'Total errors')
    const value = cardData?.[0]?.value || 0
    const compValue = cardData?.[0]?.comparison?.value || 0

    return (
        <CardComponent
            title="Total errors"
            value={value}
            unit={null}
            comparison={calculateComparison(value, compValue)}
            selectedDays={selectedDays}
        />
    )
}
export const MissionStatus = ({ filteredData }) => {
    const cardData = getCardData(filteredData, 'Mission status')
    const labels = cardData?.map((item) => item.label) || []
    const values = cardData?.map((item) => item.value) || []
    const emptyData = values.filter((x) => x !== 0)

    const data = {
        series: values,
        options: {
            states: {
                normal: {
                    filter: {
                        type: 'none',
                        value: 0,
                    },
                },
                hover: {
                    filter: {
                        type: 'none',
                        value: 0,
                    },
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none',
                        value: 0,
                    },
                },
            },
            chart: {
                background: '#fff',
                redrawOnWindowResize: true,
            },

            tooltip: {
                enabled: true,
                fillSeriesColor: false,
                marker: {
                    show: false,
                },

                style: {
                    fontSize: '12px',
                    fontFamily: 'Poppins',
                    backgroundColor: 'white',
                },
                y: {
                    formatter: function (val) {
                        return val
                    },
                    title: {
                        formatter: function (seriesName) {
                            return seriesName + ':'
                        },
                    },
                },
            },
            dataLabels: {
                enabled: false,
            },

            plotOptions: {
                pie: {
                    offsetY: 15,
                    offsetX: -60,
                    donut: {
                        size: '47%',
                        background: 'transparent',
                    },
                },
            },
            labels: labels,
            colors: ['#670D95', '#BA4FFA', '#D99EF7', '#EACAFA', '#F4E2FC'],
            legend: {
                floating: true,
                show: true,
                offsetX: -144,
                fontFamily: 'Poppins',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
                markers: {
                    width: 8,
                    height: 8,
                    offsetX: -5,
                },
                labels: {
                    colors: '#656D7E',
                },
            },
            fill: {
                opacity: 0.9,
                border: 'none',
                colors: ['#670D95', '#BA4FFA', '#D99EF7', '#EACAFA', '#F4E2FC', '#F4E2FC'],
            },
        },
    }

    const noData = {
        series: [0],
        options: {
            chart: {
                height: 320,
                type: 'radialBar',
            },
            fill: {
                colors: ['#7F56D9'],
            },
            labels: ['No missions'],

            plotOptions: {
                radialBar: {
                    offsetY: 10,
                    hollow: {
                        size: '65%',
                    },
                    dataLabels: {
                        showOn: 'always',
                        name: {
                            offsetY: 10,
                            show: true,
                            color: '#3D414C',
                            fontSize: '14px !important',
                            fontFamily: 'Poppins',
                            fontWeight: 'normal',
                        },
                        value: {
                            color: '#24272D',
                            fontSize: '32px',
                            fontFamily: 'Poppins',
                            fontWeight: '500',
                            show: false,
                        },
                    },
                },
            },

            stroke: {
                lineCap: 'round',
            },

            responsive: [
                {
                    breakpoint: 1700,
                    options: {
                        chart: {
                            height: 270,
                        },
                    },
                },

                {
                    breakpoint: 1100,
                    options: {
                        chart: {
                            height: 320,
                        },
                    },
                },
            ],
        },
    }

    return (
        <Card className="card-container">
            <CardBody className="p-0">
                <h5 className="title-value ">Mission status</h5>
                {emptyData.length !== 0 ? (
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: '230px' }}
                    >
                        <Chart
                            options={data.options}
                            series={data.series}
                            height={180}
                            type="donut"
                        />
                    </div>
                ) : (
                    <div
                        className="d-flex justify-content-center align-items-center "
                        style={{ height: '230px' }}
                    >
                        <Chart
                            options={noData.options}
                            series={noData.series}
                            type="radialBar"
                            height={320}
                        />
                    </div>
                )}
            </CardBody>
        </Card>
    )
}
export const TotalDrivingLadenTime = ({ filteredData }) => {
    const cardData = getCardData(filteredData, 'Driving times')
    const labels = ['Laden', 'Unladen']
    const values = cardData?.map((item) => item.value) || [0, 0, 0]
    const emptyData = values.filter((x) => x !== 0)
    const data = {
        series: emptyData.length === 0 ? [10] : values,
        options: {
            states: {
                normal: {
                    filter: {
                        type: 'none',
                        value: 0,
                    },
                },
                hover: {
                    filter: {
                        type: 'none',
                        value: 0,
                    },
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none',
                        value: 0,
                    },
                },
            },
            chart: {
                background: '#fff',
                redrawOnWindowResize: true,
            },

            tooltip: {
                enabled: true,
                fillSeriesColor: false,
                marker: {
                    show: false,
                },

                style: {
                    fontSize: '12px',
                    fontFamily: 'Poppins',
                    backgroundColor: 'white',
                },
                y: {
                    formatter: (values) => {
                        if (emptyData.length === 0) {
                            return ''
                        } else {
                            const hours = Math.floor(values / 3600)
                            const minutes = Math.floor((values % 3600) / 60)
                            const seconds = values % 60
                            return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
                                2,
                                '0'
                            )}:${String(seconds).padStart(2, '0')}`
                        }
                    },
                    title: {
                        formatter: function (seriesName) {
                            return emptyData.length === 0 ? 'no data' : seriesName + ':'
                        },
                    },
                },
            },
            dataLabels: {
                enabled: false,
            },

            plotOptions: {
                pie: {
                    offsetY: 2,
                    offsetX: -60,
                    donut: {
                        size: '47%',
                        background: 'transparent',
                    },
                },
            },
            labels: emptyData.length === 0 ? ['no data'] : labels,
            colors: ['#670D95', '#BA4FFA', '#D99EF7', '#EACAFA', '#F4E2FC'],
            legend: {
                floating: true,
                show: true,
                offsetX: -144,
                fontFamily: 'Poppins',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
                markers: {
                    width: 8,
                    height: 8,
                    offsetX: -5,
                },
                labels: {
                    colors: '#656D7E',
                },
            },
            fill: {
                opacity: 0.9,
                border: 'none',
                colors:
                    emptyData.length === 0
                        ? 'rgba(242, 242, 242, 0.85)'
                        : ['#670D95', '#BA4FFA', '#D99EF7', '#EACAFA', '#F4E2FC', '#F4E2FC'],
            },
        },
    }

    return (
        <Card className="card-container">
            <CardBody className="p-0">
                <h5 className="title-value">Driving times</h5>
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '126px' }}
                >
                    <Chart options={data.options} series={data.series} height={126} type="donut" />
                </div>
            </CardBody>
        </Card>
    )
}
export const TotalOnTaskTime = ({ filteredData, selectedDays }) => {
    const cardData = getCardData(filteredData, 'Total on task time')
    const value = cardData?.[0]?.value || 0
    const compValue = cardData?.[0]?.comparison?.value || 0

    return (
        <CardComponent
            title="Total on task time"
            value={value}
            unit={null}
            comparison={calculateComparison(value, compValue)}
            selectedDays={selectedDays}
        />
    )
}
export const AverageOnTaskTime = ({ filteredData, selectedDays }) => {
    const cardData = getCardData(filteredData, 'Average on task time')
    const value = cardData?.[0]?.value || 0
    const compValue = cardData?.[0]?.comparison?.value || 0
    return (
        <CardComponent
            title="Average on task time"
            value={value}
            unit={null}
            comparison={calculateComparison(value, compValue)}
            selectedDays={selectedDays}
        />
    )
}
export const RobotsAverageIdleTimes = ({ filteredData }) => {
    const cardData = getCardData(filteredData, "Robots' average idle times")
    const robotLabels = cardData?.map((item) => item.label) || []
    const missionValue = cardData?.map((item) => Math.round(item.value)) || ['']

    return (
        <CharComponent
            title="Robots' average idle times"
            text="Times (HH:MM:SS)"
            values={missionValue}
            labels={robotLabels}
        />
    )
}
export const RobotsTotalIdleTimes = ({ filteredData }) => {
    const cardData = getCardData(filteredData, "Robots' total idle times")
    const robotLabels = cardData?.map((item) => item.label) || []
    const missionValue = cardData?.map((item) => Math.round(item.value)) || ['']
    return (
        <CharComponent
            title="Robots' total idle times"
            text="Times (HH:MM:SS)"
            values={missionValue}
            labels={robotLabels}
        />
    )
}

export const RobotsTotalProductivityTime = ({ filteredData }) => {
    const cardData = getCardData(filteredData, "Robots' total productivity times")
    const robotLabels = cardData?.map((item) => item.label) || ['']
    const missionValue = cardData?.map((item) => Math.round(item.value)) || ['']
    return (
        <CharComponent
            title="Robots' total productivity times"
            values={missionValue}
            text="Times (HH:MM:SS)"
            labels={robotLabels}
        />
    )
}
export const RobotsAverageProductivityTime = ({ filteredData }) => {
    const cardData = getCardData(filteredData, "Robots' average productivity times")
    const robotLabels = cardData?.map((item) => item.label) || ['']
    const missionValue = cardData?.map((item) => Math.round(item.value)) || ['']
    return (
        <CharComponent
            title="Robots' average productivity times"
            values={missionValue}
            text="Times (HH:MM:SS)"
            labels={robotLabels}
        />
    )
}

export const RobotsAverageSpeeds = ({ filteredData }) => {
    const cardData = getCardData(filteredData, "Robots' average speeds")
    const robotLabels = cardData?.map((item) => item.label) || []
    const missionValue = cardData?.map((item) => item.value.toFixed(4)) || ['']

    return (
        <CharComponent
            title="Robots' average speeds"
            text="Speed (m/s)"
            values={missionValue}
            labels={robotLabels}
        />
    )
}

export const AverageMissionDistances = ({ filteredData }) => {
    const cardData = getCardData(filteredData, 'Average mission distances')
    const robotLabels = cardData?.map((item) => item.label) || []
    const missionValue = cardData?.map((item) => Math.round(item.value)) || ['']
    return (
        <CharComponent
            title="Average mission distances"
            text="Distance (m)"
            values={missionValue}
            labels={robotLabels}
        />
    )
}
export const RobotsTotalDistances = ({ filteredData }) => {
    const cardData = getCardData(filteredData, "Robots' total distances")
    const robotLabels = cardData?.map((item) => item.label) || []
    const missionValue = cardData?.map((item) => Math.round(item.value)) || ['']

    return (
        <CharComponent
            title="Robots' total distances"
            text="Distance (m)"
            values={missionValue}
            labels={robotLabels}
        />
    )
}
export const RobotsTotalMissions = ({ filteredData }) => {
    const cardData = getCardData(filteredData, "Robots' total missions")
    const robotLabels = cardData?.map((item) => item.label) || []
    const missionValue = cardData?.map((item) => item.value) || ['']

    return (
        <CharComponent
            title="Robots' total missions"
            text="Missions"
            values={missionValue}
            labels={robotLabels}
        />
    )
}

export const MissionDeliveryStatus = ({ filteredData }) => {
    const cardData = getCardData(filteredData, 'Mission delivery status')
    const values = cardData?.map((item) => item.value) || []
    const percentage = (values[0] / (values[0] + values[1])) * 100
    const data = {
        series: isNaN(percentage) ? [0] : [Math.round(percentage)],
        options: {
            chart: {
                height: 320,
                type: 'radialBar',
            },
            fill: {
                colors: ['#7F56D9'],
            },
            labels: ['Delivered'],

            plotOptions: {
                radialBar: {
                    offsetY: 10,
                    hollow: {
                        size: '65%',
                    },
                    dataLabels: {
                        showOn: 'always',
                        name: {
                            offsetY: -10,
                            show: true,
                            color: '#3D414C',
                            fontSize: '14px !important',
                            fontFamily: 'Poppins',
                            fontWeight: 'normal',
                        },
                        value: {
                            color: '#24272D',
                            fontSize: '32px',
                            fontFamily: 'Poppins',
                            fontWeight: '500',
                            show: true,
                        },
                    },
                },
            },
            stroke: {
                lineCap: 'round',
            },
            responsive: [
                {
                    breakpoint: 1700,
                    options: {
                        chart: {
                            height: 270,
                        },
                    },
                },

                {
                    breakpoint: 1100,
                    options: {
                        chart: {
                            height: 320,
                        },
                    },
                },
            ],
        },
    }

    return (
        <Card className="card-container">
            <CardBody className="p-0">
                <h5 className="title-value"> Mission delivery status </h5>
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '230px' }}
                >
                    <Chart
                        options={data.options}
                        series={data.series}
                        type="radialBar"
                        height={320}
                    />
                </div>
            </CardBody>
        </Card>
    )
}

export const RobotStatus = ({ filteredData }) => {
    const cardData = getCardData(filteredData, 'Robot status')
    const values = cardData?.map((item) => item.value) || []
    const percentage = (values[0] / (values[0] + values[1])) * 100

    const data = {
        series: isNaN(percentage) ? [0] : [Math.round(percentage)],

        options: {
            chart: {
                height: 320,
                type: 'radialBar',
            },
            fill: {
                colors: ['#7F56D9'],
            },
            labels: ['Robots used'],

            plotOptions: {
                radialBar: {
                    offsetY: 10,
                    hollow: {
                        size: '65%',
                    },
                    dataLabels: {
                        showOn: 'always',
                        name: {
                            offsetY: -10,
                            show: true,
                            color: '#3D414C',
                            fontSize: '14px !important',
                            fontFamily: 'Poppins',
                            fontWeight: 'normal',
                        },
                        value: {
                            color: '#24272D',
                            fontSize: '32px',
                            fontFamily: 'Poppins',
                            fontWeight: '500',
                            show: true,
                        },
                    },
                },
            },
            stroke: {
                lineCap: 'round',
            },
            responsive: [
                {
                    breakpoint: 1700,
                    options: {
                        chart: {
                            height: 270,
                        },
                    },
                },

                {
                    breakpoint: 1100,
                    options: {
                        chart: {
                            height: 320,
                        },
                    },
                },
            ],
        },
    }
    return (
        <Card className="card-container">
            <CardBody className="p-0">
                <h5 className="title-value"> Robot status </h5>
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '230px' }}
                >
                    <Chart
                        options={data.options}
                        series={data.series}
                        type="radialBar"
                        height={320}
                    />
                </div>
            </CardBody>
        </Card>
    )
}

export const MissionSuccess = ({ filteredData }) => {
    const cardData = getCardData(filteredData, 'Mission success')
    const missionLabel = cardData?.map((item) => item.label) || []
    const missionCompleted = cardData?.map((item) => Math.round(item.Completed)) || []
    const missionTotal = cardData?.map((item) => Math.round(item.Total)) || []
    let months = []
    function monthName(mon) {
        return [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'June',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ][mon - 1]
    }

    for (let i = 1; i <= missionLabel.length; i++) {
        months.push(monthName(i))
    }

    const data = {
        series: [
            {
                name: 'Completed',
                data: missionCompleted || [45],
            },
            {
                name: 'Total',
                data: missionTotal || [69],
            },
        ],
        options: {
            chart: {
                type: 'bar',
                stacked: true,
                width: '100%',
                toolbar: {
                    show: false,
                },
            },
            colors: ['#BA4FFA', '#D99EF7'],
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 2,
                    columnWidth: '50%',
                    endingShape: 'rounded',
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: false,
                //   width: 2,
                colors: ['transparent'],
            },

            xaxis: {
                categories: months,
            },
            yaxis: {
                title: {
                    text: 'Missions',
                },
            },
            legend: {
                position: 'top',
                offsetX: 150,
            },

            tooltip: {
                y: {
                    formatter: function (val) {
                        return val
                    },
                },
            },
        },
    }

    return (
        <Card className="card-container">
            <CardBody className="p-0 ">
                <h5 className="title-value"> Mission success </h5>
                <Chart
                    options={data?.options}
                    series={data?.series}
                    type="bar"
                    height={210}
                    width="100%"
                    ƒ
                />
            </CardBody>
        </Card>
    )
}
