
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    Modal,
    ModalBody,
    Button,
    ModalHeader,
    UncontrolledTooltip,
    Input,
    Label,
    FormGroup,
} from 'reactstrap'

import {
    deleteAllTeamNotifications,
    deleteBulkTeamNotifications,
    deleteOneTeamNotification,
    getTeamNotifications,
    readAllTeamNotifications,
    readBulkTeamNotifications,
    readOneTeamNotification,
} from 'features/dashboard/Notification.actionTypes'
import { getNotification, getTeam } from 'features/dashboard/selectors'
import NotificationBody from './NotificationBody'
import Paginator from './Paginator'
import notificationPop from './../../assets/images/illustrations/notification-pop.svg'
import cx from 'classnames'
import { getPaginationPages } from 'utils'
import { formatDate } from './organization/OrgTeamHelpers'
import { Loading } from 'ui/common'

const NotificationCard = () => {
    const notifications = useSelector(getNotification)
    const team = useSelector(getTeam)
    const [currentPage, setCurrentPage] = useState(0)
    const dispatch = useDispatch()
    const [pagination, setPagination] = useState([])
    const [modal, setModal] = useState(false)
    const [id, setUuid] = useState()
    const modalToggle = () => {
        setModal(!modal)
    }

    const [isCheckAll, setIsCheckAll] = useState(false)
    const [isCheck, setIsCheck] = useState([])
    const [list, setList] = useState()

    useEffect(() => {
        setList(notifications.teamItems[currentPage])
    }, [notifications.teamItems, dispatch, currentPage])

    useEffect(() => {
        const pagesNumbers = getPaginationPages(currentPage, notifications.teamItems)
        setPagination([...pagesNumbers])
    }, [currentPage, notifications.teamItems])

    const handleSelectAll = (e) => {
        setUuid(undefined)
        setIsCheckAll(!isCheckAll)
        setIsCheck(
            Object.values(list)
                .flat()
                .map((li) => li._id)
        )
        if (isCheckAll) {
            setIsCheck([])
        }
    }

    const handleClick = (e) => {
        const { id, checked } = e.target
        setUuid(id)
        setIsCheck([...isCheck, id])
        setIsCheckAll(false)
        if (!checked) {
            setIsCheck(isCheck.filter((item) => item !== id))
        }
    }
    const notification = list?.flat().map(({ _id, text, read, notification_type, _created }) => {
        const iconText =
            notification_type === 'system' ? (
                <div
                    key={_id}
                    className="d-flex flex-column align-items-start justify-content-start"
                >
                    <div className="d-flex align-items-center">
                        <img
                            alt="system"
                            width={48}
                            height={48}
                            id={`system-${_id}`}
                            src="/svgs/dashboard-icons/settings-icon.svg"
                        />
                        <span
                            className="border-none rounded px-1 bg-grey"
                            style={{ color: '#656D7E' }}
                        >
                            {formatDate(_created)}
                        </span>
                    </div>
                    <span
                        className={cx('unread ml-3 ', {
                            read,
                        })}
                    >
                        {text}
                    </span>
                    <UncontrolledTooltip
                        placement="top"
                        target={`system-${_id}`}
                        style={{ backgroundColor: '#FFFF', color: '#3D414C' }}
                    >
                        System notification
                    </UncontrolledTooltip>
                </div>
            ) : (
                <div
                    key={_id}
                    className="d-flex flex-column align-items-start justify-content-start"
                >
                    <div className="d-flex align-items-center">
                        <img
                            alt="system"
                            width={48}
                            height={48}
                            id={`vehicle-${_id}`}
                            src="/svgs/dashboard-icons/vehicle-icon.svg"
                        />
                        <span
                            className="border-none rounded px-1 bg-grey"
                            style={{ color: '#656D7E' }}
                        >
                            {formatDate(_created)}
                        </span>
                    </div>
                    <span
                        className={cx('unread ml-3 ', {
                            read,
                        })}
                    >
                        {text}
                    </span>{' '}
                    <UncontrolledTooltip
                        placement="top"
                        target={`vehicle-${_id}`}
                        style={{ backgroundColor: '#FFFF', color: '#3D414C' }}
                    >
                        Vehicle notification
                    </UncontrolledTooltip>
                </div>
            )
        return (
            <FormGroup check key ={_id}>
                <Input
                    key={_id}
                    type="checkbox"
                    name={text}
                    className={cx('pr-1 unread notidication-card-border mt-4 ', {
                        read: read,
                    })}
                    id={_id}
                    onChange={handleClick}
                    checked={isCheck.includes(_id)}
                />
                <Label check className="mb-3 mt-2">
                    {iconText}{' '}
                </Label>
            </FormGroup>
        )
    })

    const { status } = notifications
    return (
        <div style={{ borderTop: '2px solid #f9fafb' }}>
            <div
                className="text-dark ml-1"
                style={{
                    height: 'auto',
                    overflowY: 'auto',
                    width: '400px',
                    paddingRight: '24px',
                    paddingLeft: '24px',
                }}
            >
                <div
                    className="d-flex justify-content-between"
                    style={{ paddingBottom: '32px', paddingTop: '16px' }}
                >
                    <div className="p-0 notification-title">Team notifications</div>

                    <span
                        className="grey-700 size-14 weight-500"
                        style={{ cursor: 'pointer' }}
                        href="#"
                        onClick={(e) => {
                            e.preventDefault()
                            modalToggle()
                        }}
                    >
                        View all
                    </span>
                </div>

                <div className='d-flex flex-column align-items-start justify-content-center w-100'>
                    <NotificationBody
                        items={notifications.teamItems[0]}
                        status={status}
                        handleDelete={(id) => {
                            dispatch(
                                deleteOneTeamNotification({
                                    teamUuid: team?.details?.uuid,
                                    id,
                                })
                            ).then(() => {
                                dispatch(
                                    getTeamNotifications({
                                        uuid: team?.details?.uuid,
                                    })
                                )
                            })
                        }}
                        handleRead={(id) =>
                            dispatch(readOneTeamNotification({ teamUuid: team?.details?.uuid, id }))
                        }
                    />
                </div>
            </div>
            <Modal isOpen={modal} toggle={modalToggle}>
                <ModalHeader className="d-flex justify-content-between" toggle={modalToggle}>
                    <div className="icon-border mb-3">
                        <img alt="notifications" src={notificationPop}></img>
                    </div>
                    <div className="pr-3 custmText">Team notifications</div>
                </ModalHeader>
                <ModalBody>
                    <div className="d-flex justify-content-between top-border mx-2 pb-3">
                        <FormGroup check>
                            <Input
                                type="checkbox"
                                name="selectAll"
                                id="selectAll"
                                className="unread mt-1"
                                onChange={handleSelectAll}
                                checked={isCheckAll}
                            />
                            <Label check>Select all</Label>
                        </FormGroup>
                        <div className="">
                            <Button
                                outline
                                size="sm"
                                style={{ borderRadius: 10 }}
                                id="filterId"
                                className="text-dark mr-2"
                                onClick={(e) => {
                                    if (isCheckAll === false) {
                                        if (isCheck.length === 1) {
                                            dispatch(
                                                deleteOneTeamNotification({
                                                    teamUuid: team?.details?.uuid,
                                                    id,
                                                })
                                            ).then(() => {
                                                setIsCheck([])
                                                dispatch(
                                                    getTeamNotifications({
                                                        uuid: team?.details?.uuid,
                                                    })
                                                )
                                            })
                                        } else if (isCheck.length > 1) {
                                            const data = isCheck
                                            dispatch(
                                                deleteBulkTeamNotifications({
                                                    uuid: team?.details?.uuid,
                                                    data,
                                                })
                                            ).then(() => {
                                                setIsCheck([])
                                                dispatch(
                                                    getTeamNotifications({
                                                        uuid: team?.details?.uuid,
                                                    })
                                                )
                                            })
                                        }
                                    } else if (isCheckAll === true) {
                                        dispatch(
                                            deleteAllTeamNotifications({
                                                uuid: team?.details?.uuid,
                                            })
                                        ).then(({ error }) => {
                                            dispatch(
                                                getTeamNotifications({ uuid: team?.details?.uuid })
                                            )
                                        })

                                        setIsCheckAll(false)
                                    }
                                }}
                            >
                                Delete
                            </Button>
                            <Button
                                href="#"
                                size="sm"
                                style={{ borderRadius: 10, height: '30px' }}
                                onClick={(e) => {
                                    e.preventDefault()
                                    if (isCheckAll === false) {
                                        if (isCheck.length === 1) {
                                            dispatch(
                                                readOneTeamNotification({
                                                    teamUuid: team?.details?.uuid,
                                                    id,
                                                })
                                            )
                                            setIsCheck([])
                                        } else if (isCheck.length > 1) {
                                            const data = isCheck
                                            dispatch(
                                                readBulkTeamNotifications({
                                                    uuid: team?.details?.uuid,
                                                    data,
                                                })
                                            ).then(() => {
                                                setIsCheck([])
                                                dispatch(
                                                    getTeamNotifications({
                                                        uuid: team?.details?.uuid,
                                                    })
                                                )
                                            })
                                        }
                                    } else if (isCheckAll === true) {
                                        dispatch(
                                            readAllTeamNotifications({ uuid: team?.details?.uuid })
                                        )
                                        setIsCheckAll(false)
                                        setIsCheck([])
                                    }
                                }}
                                color="meili"
                            >
                                Mark as read
                            </Button>
                        </div>
                    </div>

                    {notifications.teamItems.length === 0 ? (
                        <p className="mt-3 text-center text-info">Notification center is empty!</p>
                    ) : status === 'team-notifications-loading' ? (
                        <Loading />
                    ) : (
                        <div className="d-flex flex-column mx-2 my-2">{notification}</div>
                    )}

                    {notifications.teamItems.length > 1 &&
                        status !== 'team-notifications-loading' && (
                            <Paginator
                                totalPages={notifications.teamItems.length}
                                pages={pagination}
                                activePage={currentPage}
                                handlePageChange={(page) => setCurrentPage(page)}
                            />
                        )}
                </ModalBody>
            </Modal>
        </div>
    )
}
export default NotificationCard
