import { actionTypes } from './actionTypes'

const initialState = {
    status: 'loading',
    accessToken: [],
    token: window.localStorage.getItem('token') || '',
    websocketToken: null,
    errors: {},
    profile: {},
}

export const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_PROFILE:
        case actionTypes.ACTIVATE_TOKEN:
        case actionTypes.ACTIVATE_EMAIL:
        case actionTypes.VALIDATE_TOKEN:
        case actionTypes.GET_WS_TOKEN:
        case actionTypes.REQUEST_RESET_PASSWORD:
        case actionTypes.GET_PROFILE:
        case actionTypes.LOGOUT:
        case actionTypes.CHANGE_PASSWORD:
        case actionTypes.SET_PASSWORD:
        case actionTypes.GET_ACCESS_TOKEN:
        case actionTypes.SIGNUP:
        case actionTypes.CREATE_ACCESS_TOKEN:
            return {
                ...state,
                status: 'loading',
            }
        case actionTypes.LOGOUT_SUCCESS:
            window.localStorage.removeItem('token')
            return {
                ...state,
                token: '',
                status: 'loaded',
            }
        case actionTypes.LOGIN_SUCCESS:
            window.localStorage.setItem('token', action.payload.data.token)
            return {
                ...state,
                status: 'loaded',
                token: action.payload.data.token,
            }
        case actionTypes.GET_PROFILE_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                profile: action.payload?.data,
            }
        case actionTypes.GET_ACCESS_TOKEN_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                accessToken: action.payload.data,
            }
        case actionTypes.UPDATE_PROFILE_SUCCESS:
        case actionTypes.ACTIVATE_EMAIL_SUCCESS:
        case actionTypes.SET_PASSWORD_SUCCESS:
        case actionTypes.ACTIVATE_TOKEN_SUCCESS:
        case actionTypes.VALIDATE_TOKEN_SUCCESS:
        case actionTypes.REQUEST_RESET_PASSWORD_SUCCESS:
        case actionTypes.CHANGE_PASSWORD_SUCCESS:
        case actionTypes.CREATE_ACCESS_TOKEN_SUCCESS:
        case actionTypes.CONTACT_US_EMAIL_SUCCESS:
            return {
                ...state,
                status: 'loaded',
            }
        case actionTypes.ACTIVATE_EMAIL_FAIL:
        case actionTypes.ACTIVATE_TOKEN_FAIL:
        case actionTypes.LOGOUT_FAIL:
        case actionTypes.VALIDATE_TOKEN_FAIL:
            return {
                ...state,
                errors: { detail: 'token is not valid' },
                status: 'error',
            }
        case actionTypes.GET_PROFILE_FAIL:
        case actionTypes.GET_ACCESS_TOKEN_FAIL:
        case actionTypes.UPDATE_PROFILE_FAIL:
        case actionTypes.SIGNUP_FAIL:
        case actionTypes.LOGIN_FAIL:
        case actionTypes.REQUEST_RESET_PASSWORD_FAIL:
        case actionTypes.SET_PASSWORD_FAIL:
        case actionTypes.CHANGE_PASSWORD_FAIL:
        case actionTypes.CREATE_ACCESS_TOKEN_FAIL:
            return {
                ...state,
                errors: action.error?.response?.data,
                status: 'error',
            }
        case actionTypes.GET_WS_TOKEN_SUCCESS:
            return {
                ...state,
                status: 'loaded',
                websocketToken: action.payload.data.token,
            }
        default:
            return state
    }
}
