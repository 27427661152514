import React, { useState, useEffect, useRef, useCallback, memo } from 'react'
import { Stage, Layer, Text } from 'react-konva'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import { CardBody, Card, Button, Modal, ModalBody } from 'reactstrap'
import { withSize } from 'react-sizeme'
import { selectors } from 'features/dashboard'
import { Image as KonvaImage } from 'react-konva'
import saveSvg from '../../../assets/images/illustrations/save-icon.svg'
import { getAreaMapSettings, updateAreaMapSettings } from 'features/dashboard/Map.actionTypes'
import { createNewTask, getTasksV2 } from 'features/dashboard/Task.actionTypes'
import LoadingSpinner from 'components/utils/LoadingSpinner'
import { handleWheel } from '../MapComponents/helpers'
import MapObstacle from '../MapComponents/MapObstacle'
import RenderZonesPaths from '../MapComponents/RenderZonesPaths'
import IndoorMapDevices from '../MapComponents/IndoorMapDevices'
import IndoorMapPoint from '../MapComponents/IndoorMapPoint'
import VehiclePointer from '../MapComponents/IndoorVehiclePoint'
import { MiniMapButtons } from '../MapComponents/MiniMapButtons'
import InfoHover from '../MapComponents/InfoHover'
import { getTeamVehicles, sendEstimatedPose } from 'features/dashboard/Vehicle.actionTypes'
import EstimatedPointDot from '../MapComponents/EstimatedPointDot'
import EstimatedPoint from '../MapComponents/EstimatedPoint'

const Map = memo(
    ({
        getRobot,
        icon,
        setIcon,
        actions,
        sidebar,
        messageReceived,
        taskHovered,
        currentLayout,
        currentPageNumber,
        image,
    }) => {
        const { slug } = useParams()
        const dispatch = useDispatch()
        const map = useSelector(selectors.getTeamMap)
        const areaSettings = useSelector(selectors.getTeamMap).areaSettings
        const custom_icon = useSelector(selectors.getCustomIcon)
        const stations = useSelector(selectors.getMapStations)
        const devices = useSelector(selectors.getDevicess)
        const vehicles = useSelector(selectors.getVehicles)
        const [selectStation, setSelectedStation] = useState(null)
        const [stageRef, setStageRef] = useState(null)
        const [robotHovered, setRobotHovered] = useState(false)
        const [selectedRobot, setSelectedRobot] = useState(null)
        const [dataHovered, setDataHovered] = useState(null)
        const [sizeText, setSizeText] = useState(14 / stageRef?.scaleX() || 0)
        const [hidePaths, setHidePaths] = useState(!areaSettings?.show_paths || false)
        const [showTagZonesNames, setShowTagZonesNames] = useState(
            areaSettings?.show_tag_zones_names || false
        )
        const [showVehiclesNames, setShowVehiclesNames] = useState(
            areaSettings?.show_vehicles_names || false
        )
        const [estimatedPose, setEstimatedPose] = useState(false)
        const [estimatedPoseArrowStart, setEstimatedPoseArrowStart] = useState(null)
        const [robotSelectedEstPose, setRobotSelectedEstPose] = useState(null)
        const [modal, setModal] = useState(false)
        const toggle = () => setModal(!modal)
        // const [oneIsActive, setOneVehicleActive] = useState(false)

        const [sizes, setSize] = useState({
            width: 1,
            height: 600,
            ratio: 4,
            scale: 0.5,
        })
        const [pos, setPos] = useState('')
        const biggerMapSize = 1000000
        const colors = {}

        const winRef = useRef(null)
        const { uuid } = map.areas
        const { height, width, x, y } = map.aoi
        const { original_height, original_width, resolution, origin_x, origin_y } = map.areaDetails
        const largestPoint = original_height > original_width ? original_height : original_width

        useEffect(() => {
            setIcon(areaSettings?.show_stations_names)
            setHidePaths(!areaSettings?.show_paths)
            setShowTagZonesNames(areaSettings?.show_tag_zones_names)
            setShowVehiclesNames(areaSettings?.show_vehicles_names)
        }, [areaSettings]) // eslint-disable-line react-hooks/exhaustive-deps

        const updateMapSize = () => {
            if (map.status !== 'image-error' || map.status !== 'loading' || winRef.current) {
                const mapCenterX = x + width / 2
                const mapCenterY = y + height / 2

                const containerCenterX = winRef.current.offsetWidth / 2
                const containerCenterY = winRef.current.offsetHeight / 2

                setSize({
                    width: winRef.current.offsetWidth,
                    height: winRef.current.offsetHeight,
                    largestPoint,
                    scale: areaSettings.zoom
                        ? areaSettings.zoom
                        : (winRef.current?.offsetWidth - window.innerWidth / 2.5) / width,
                    x: areaSettings.center_position
                        ? areaSettings.center_position[0]
                        : containerCenterX - mapCenterX * 2.2,
                    y: areaSettings.center_position
                        ? areaSettings.center_position[1]
                        : containerCenterY - mapCenterY * 2,
                })
            }
        }

        useEffect(() => {
            const mapCenterX = x + width / 2
            const mapCenterY = y + height / 2

            const containerCenterX = winRef.current.offsetWidth / 2
            const containerCenterY = winRef.current.offsetHeight / 2
            setSize({
                width: winRef.current.offsetWidth,
                height: winRef.current.offsetHeight,
                largestPoint,
                scale: areaSettings.zoom
                    ? areaSettings.zoom
                    : (winRef.current?.offsetWidth - window.innerWidth / 2.5) / width,
                x: containerCenterX - mapCenterX * 2.2,
                y: containerCenterY - mapCenterY * 2,
            })
        }, [currentLayout]) // eslint-disable-line react-hooks/exhaustive-deps

        useEffect(() => {
            // Initial size update
            updateMapSize()

            // Handle window resize events
            const handleResize = () => {
                updateMapSize()
            }

            window.addEventListener('resize', handleResize)

            return () => {
                window.removeEventListener('resize', handleResize)
            }
        }, [winRef, map, areaSettings]) // eslint-disable-line react-hooks/exhaustive-deps

        useEffect(() => {
            document.addEventListener('keydown', escFunction, false)

            return () => {
                document.removeEventListener('keydown', escFunction, false)
            }
        }, []) // eslint-disable-line react-hooks/exhaustive-deps

        const escFunction = useCallback((event) => {
            if (event.key === 'Escape') {
                setSelectedStation(false)
                setSelectedRobot(null)
            }
        }, [])

        const handleDragEnd = (e) => {
            if (e.target._lastPos) {
                const { x, y } = e.target._lastPos

                setSize((prevSize) => ({
                    ...prevSize,
                    x,
                    y,
                }))
            } else {
                const { x, y } = e.target.attrs

                setSize((prevSize) => ({
                    ...prevSize,
                    x: sizes.x + x,
                    y: sizes.y + y,
                }))
            }
        }

        const handleSave = () => {
            const data = {
                center_position: [sizes.x.toFixed(5), sizes.y.toFixed(5)],
                zoom: sizes.scale.toFixed(5),
                width: sizes.width,
            }

            dispatch(updateAreaMapSettings({ uuid, data })).then(({ error }) => {
                if (!error) {
                    dispatch(getAreaMapSettings({ uuid }))
                    toggle()
                }
            })
        }

        const onStageRefUpdate = (node) => {
            if (node && !stageRef) {
                setStageRef(node)
            }
        }

        const handlePointSubmit = (point) => {
            const data = {
                subtasks: [
                    {
                        action: {
                            point,
                            definition: actions
                                .filter((action) => action.slug === 'move_to_point')
                                .map((action) => action.uuid)[0],
                        },
                    },
                ],
            }
            dispatch(createNewTask({ slug, data })).then((res) => {
                dispatch(getTeamVehicles({ slug, text: 'detailed=true', page: currentPageNumber }))
                dispatch(getTasksV2(slug))
            })
        }

        const handleEstimatePose = (position) => {
            setEstimatedPose(false)
            setRobotSelectedEstPose(null)
            setEstimatedPoseArrowStart(null)
            const { x, y, x2, y2 } = position
            var deltaX = x2 - x
            var deltaY = y2 - y
            var yaw = Math.atan2(-deltaY, deltaX).toFixed(2)

            const ox = Math.abs(Math.floor(origin_x / resolution))
            const oy = original_height - Math.abs(Math.floor(origin_y / resolution))
            const Xcords = ((x - ox) * resolution).toFixed(2)
            const YCords = -((y - oy) * resolution).toFixed(2)

            const data = { x: Xcords, y: YCords, yaw }
            dispatch(sendEstimatedPose({ uuid: robotSelectedEstPose, data }))
        }

        const handlePointSubmitSelect = (point, vehiclePoint) => {
            const data = {
                subtasks:
                    point.length === 2
                        ? [
                              {
                                  action: {
                                      form_values: { x: point[0], y: point[1] },
                                      definition: actions
                                          .filter((action) => action.slug === 'move_to_location')
                                          .map((action) => action.uuid)[0],
                                  },
                              },
                          ]
                        : [
                              {
                                  action: {
                                      point,
                                      definition: actions
                                          .filter((action) => action.slug === 'move_to_point')
                                          .map((action) => action.uuid)[0],
                                  },
                              },
                          ],
                vehicle_uuid: vehiclePoint,
            }
            return dispatch(createNewTask({ slug, data })).then((res) => {
                dispatch(getTeamVehicles({ slug, text: 'detailed=true', page: currentPageNumber }))
                dispatch(getTasksV2(slug))
                setSelectedStation(null)
                setSelectedRobot(null)
            })
        }

        const handleMouse = (e) => {
            const stage = stageRef
            setSizeText(14 / stage?.scaleX())
            const mousePointTo = getCurrentMousePointer(stage)
            const pos = Object.values(mousePointTo).map((position) => Math.floor(position))
            setPos(pos)
        }

        const getCurrentMousePointer = (stage) => {
            if (!stage.children[0]?.attrs.x && !stage.children[0]?.attrs.y) {
                return {
                    x: (stage.getPointerPosition()?.x - stage.x()) / stage.scaleX(),
                    y: (stage.getPointerPosition()?.y - stage.y()) / stage.scaleY(),
                }
            }
            return {
                x:
                    (stage.getPointerPosition()?.x - stage.x()) / stage.scaleX() -
                    stage.children[0].attrs.x,
                y:
                    (stage.getPointerPosition()?.y - stage.y()) / stage.scaleY() -
                    stage.children[0].attrs.y,
            }
        }

        const vehicle = vehicles.teamVehicle?.map((vehicle) => vehicle.is_online)
        return (
            <div className="m-0 p-0 w-100 h-100 min-h-100 min-w-100" ref={winRef}>
                {map.status !== 'image-error' && (
                    <Card className="card-box shadow-none border-0 w-100 h-100">
                        {map.status === 'loading' ? (
                            <div style={{ marginTop: '40%', minHeight: '100%', minWidth: '100%' }}>
                                <LoadingSpinner />
                            </div>
                        ) : (
                            <CardBody className="m-0 p-0 d-flex justify-content-center w-100 h-100">
                                <Stage
                                    width={sizes.width || 400}
                                    height={sizes.height || 600}
                                    scaleX={sizes.scale || 1}
                                    scaleY={sizes.scale || 1}
                                    x={sizes.x || 1}
                                    y={sizes.y || 1}
                                    onClick={() => {
                                        if (robotSelectedEstPose) {
                                            setEstimatedPoseArrowStart({ x: pos[0], y: pos[1] })
                                        }

                                        if (estimatedPoseArrowStart) {
                                            const data = {
                                                ...estimatedPoseArrowStart,
                                                x2: pos[0],
                                                y2: pos[1],
                                            }
                                            handleEstimatePose(data)
                                        }
                                    }}
                                    onWheel={(e) => {
                                        const isScrollingDown = e.evt.deltaY > 0
                                        handleWheel(e, stageRef, isScrollingDown, sizes, setSize)
                                        setSizeText(14 / stageRef?.scaleX())
                                    }}
                                    ref={(node) => onStageRefUpdate(node)}
                                    onMouseMove={handleMouse}
                                    onDragMove={handleDragEnd}
                                    onDragEnd={handleDragEnd}
                                >
                                    <Layer draggable offsetX={0} offsetY={0}>
                                        <KonvaImage
                                            width={original_width}
                                            height={original_height}
                                            image={image}
                                            onClick={() => {
                                                if (selectedRobot) {
                                                    handlePointSubmitSelect(pos, selectedRobot)
                                                }
                                            }}
                                        />

                                        {map.status !== 'loading'
                                            ? map.zones.tag?.map((zone) => (
                                                  <MapObstacle
                                                      cursor={pos}
                                                      stage={stageRef}
                                                      getCurrentMousePointer={
                                                          getCurrentMousePointer
                                                      }
                                                      width={width}
                                                      key={zone?.uuid}
                                                      zone={zone}
                                                      size={sizeText}
                                                      showTagZonesNames={showTagZonesNames}
                                                  />
                                              ))
                                            : null}

                                        {map.status !== 'loading'
                                            ? map.zones.charging?.map((zone) => (
                                                  <MapObstacle
                                                      cursor={pos}
                                                      stage={stageRef}
                                                      getCurrentMousePointer={
                                                          getCurrentMousePointer
                                                      }
                                                      width={width}
                                                      key={zone?.uuid}
                                                      zone={zone}
                                                  />
                                              ))
                                            : null}
                                        {map.status !== 'loading'
                                            ? map.zones.resting?.map((zone) => (
                                                  <MapObstacle
                                                      cursor={pos}
                                                      stage={stageRef}
                                                      getCurrentMousePointer={
                                                          getCurrentMousePointer
                                                      }
                                                      width={width}
                                                      key={zone?.uuid}
                                                      zone={zone}
                                                  />
                                              ))
                                            : null}
                                        {map.status === 'loaded' &&
                                            !hidePaths &&
                                            map.zonesPaths.map((path) => (
                                                <RenderZonesPaths
                                                    key={path?.uuid}
                                                    path={path}
                                                    scale={sizes.scale}
                                                    slug={slug}
                                                    onlyView={true}
                                                    aoi={map.aoi}
                                                />
                                            ))}
                                        {map.status !== 'loading'
                                            ? map.zones.action?.map((zone) => (
                                                  <MapObstacle
                                                      cursor={pos}
                                                      stage={stageRef}
                                                      getCurrentMousePointer={
                                                          getCurrentMousePointer
                                                      }
                                                      width={width}
                                                      key={zone.uuid}
                                                      zone={zone}
                                                  />
                                              ))
                                            : null}

                                        {devices &&
                                            map.status === 'loaded' &&
                                            devices.map((device) => (
                                                <IndoorMapDevices
                                                    key={device?.uuid}
                                                    setDataHovered={setDataHovered}
                                                    device={device}
                                                    aoi={map.aoi}
                                                />
                                            ))}

                                        {map.status === 'loaded' &&
                                            stations.map((point) => (
                                                <IndoorMapPoint
                                                    slug={slug}
                                                    icon={icon}
                                                    customIcon={custom_icon}
                                                    aoi={map.aoi}
                                                    draggable={false}
                                                    point={point}
                                                    key={point?.uuid}
                                                    sizeText={sizeText}
                                                    estimatedPose={estimatedPose}
                                                    setDataHovered={setDataHovered}
                                                    handleSubmit={handlePointSubmit}
                                                    largestPoint={sizes.largestPoint}
                                                    selectedRobot={selectedRobot}
                                                    setSelectedStation={setSelectedStation}
                                                    handlePointSubmitSelect={
                                                        handlePointSubmitSelect
                                                    }
                                                />
                                            ))}

                                        {map.status !== 'loading'
                                            ? map.zones.forbidden?.map((zone) => (
                                                  <MapObstacle
                                                      cursor={pos}
                                                      stage={stageRef}
                                                      getCurrentMousePointer={
                                                          getCurrentMousePointer
                                                      }
                                                      width={width}
                                                      key={zone?.uuid}
                                                      zone={zone}
                                                  />
                                              ))
                                            : null}

                                        {messageReceived &&
                                            vehicles.wbvehicles?.map(({ details, ...vehicle }) => (
                                                <VehiclePointer
                                                    key={vehicle?.uuid}
                                                    stage={stageRef}
                                                    estimatedPose={estimatedPose}
                                                    setRobotSelectedEstPose={
                                                        setRobotSelectedEstPose
                                                    }
                                                    setDataHovered={setDataHovered}
                                                    fill={colors[vehicle?.uuid]}
                                                    selectedRobot={selectedRobot}
                                                    setSelectedRobot={setSelectedRobot}
                                                    robotSelectedEstPose={robotSelectedEstPose}
                                                    details={details}
                                                    vehicle={vehicle}
                                                    sizeText={sizeText}
                                                    selectStation={selectStation}
                                                    getRobot={getRobot}
                                                    biggerMapSize={biggerMapSize}
                                                    taskHovered={taskHovered}
                                                    setRobotHovered={setRobotHovered}
                                                    handlePointSubmitSelect={
                                                        handlePointSubmitSelect
                                                    }
                                                    showVehiclesNames={showVehiclesNames}
                                                />
                                            ))}

                                        {selectStation && !robotHovered && (
                                            <Text
                                                x={pos[0]}
                                                y={pos[1] / 1.2}
                                                align={'center'}
                                                text="Pick a robot"
                                                fontSize={sizeText}
                                                fontStyle="bold"
                                                stroke="#670d95"
                                                strokeWidth={0.1}
                                            />
                                        )}

                                        {selectedRobot && !robotHovered && !estimatedPose && (
                                            <Text
                                                x={pos[0]}
                                                y={pos[1] / 1.2}
                                                align={'center'}
                                                text="Click on any station to send the robot"
                                                fontSize={sizeText}
                                                fontStyle="bold"
                                                stroke="#670d95"
                                                strokeWidth={0.1}
                                            />
                                        )}

                                        {estimatedPose &&
                                            !robotHovered &&
                                            !robotSelectedEstPose && (
                                                <Text
                                                    x={pos[0]}
                                                    y={pos[1] / 1.2}
                                                    align={'center'}
                                                    text="Select the robot for the estimated pose"
                                                    fontSize={sizeText}
                                                    fontStyle="bold"
                                                    stroke="#670d95"
                                                    strokeWidth={0.1}
                                                />
                                            )}

                                        {robotSelectedEstPose && !estimatedPoseArrowStart && (
                                            <EstimatedPointDot x={pos[0]} y={pos[1]} />
                                        )}

                                        {estimatedPoseArrowStart && (
                                            <EstimatedPoint
                                                currentPos={[
                                                    estimatedPoseArrowStart.x,
                                                    estimatedPoseArrowStart.y,
                                                    ...pos,
                                                ]}
                                                x={estimatedPoseArrowStart.x}
                                                y={estimatedPoseArrowStart.y}
                                            />
                                        )}
                                    </Layer>
                                </Stage>
                                {map.status !== 'loading' && (
                                    <MiniMapButtons
                                        areaUuid={uuid}
                                        toggle={toggle}
                                        icon={icon}
                                        setIcon={setIcon}
                                        hidePaths={hidePaths}
                                        setHidePaths={setHidePaths}
                                        setEstimatedPose={setEstimatedPose}
                                        sizes={sizes}
                                        setSize={setSize}
                                        vehicle={vehicle}
                                        sidebar={sidebar}
                                        stageRef={stageRef}
                                        setSizeText={setSizeText}
                                        showTagZonesNames={showTagZonesNames}
                                        setShowTagZonesNames={setShowTagZonesNames}
                                        showVehicleNsames={showVehiclesNames}
                                        setShowVehiclesNames={setShowVehiclesNames}
                                    />
                                )}
                            </CardBody>
                        )}
                    </Card>
                )}

                <Modal isOpen={modal} toggle={toggle} centered className="new-modals">
                    <ModalBody>
                        <div
                            className="w-100 d-flex justify-content-between"
                            style={{ marginBottom: '24px' }}
                        >
                            <img alt="save" width="48px" height="48px" src={saveSvg}></img>
                            <img
                                src="/svgs/close-icon/x-dark-default.svg"
                                alt="obstacle-icon"
                                width="24px"
                                height="24px"
                                style={{ cursor: 'pointer' }}
                                onClick={toggle}
                            />
                        </div>
                        Are you sure you want to save this display preferences? This will be default
                        view for this map.
                        <div
                            style={{ marginTop: '24px' }}
                            className={`d-flex w-100 justify-content-between align-items-center`}
                        >
                            <Button
                                className="cancel-btn-modal modals-new-btns w-50 mr-2"
                                onClick={toggle}
                            >
                                No
                            </Button>
                            <Button
                                className="save-btn-modal modals-new-btns w-50"
                                onClick={handleSave}
                            >
                                Yes
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>

                <InfoHover dataHovered={dataHovered} />

                {map.status === 'image-error' && (
                    <Redirect to={`/dashboard/${slug}/maps/upload/`} />
                )}
            </div>
        )
    }
)

export default withSize({ monitorHeight: true })(Map)
