import React from 'react'
import { Button } from 'reactstrap'

const TwoFactorAuthIntro = ({ twoFactorEnabled, toggle }) => {
    return (
        <>
            <div>
                <div className="d-flex justify-content-start align-items-center gap-4">
                    <h4>Two-factor authentication</h4>
                    {twoFactorEnabled && (
                        <span className="text-marker active-status-marker mb-2">Activated</span>
                    )}
                </div>
                <span>Enable two-factor authentication to add an extra layer of security.</span>
            </div>
            <hr className="custom-hr" />
            <div className="d-flex align-items-end justify-content-between w-100 pr-4">
                <div className="d-flex flex-column align-items-start w-50">
                    <span
                        style={{
                            fontWeight: '500',
                            fontSize: '14px',
                            lineHeight: '20px',
                            letterSpacing: '0.1px',
                            color: '#24272D',
                        }}
                    >
                        Secure your account
                    </span>
                    <span
                        style={{
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                            letterSpacing: '0.1px',
                            color: '#24272D',
                        }}
                    >
                        {twoFactorEnabled
                            ? `Two-factor authentication has been \n enabled on this account.`
                            : ` Two-factor authentication has not been \n enabled on this account.`}
                    </span>
                </div>
                <div className="w-25">
                    {twoFactorEnabled ? (
                        <Button className="modals-new-btns cancel-btn-modal" onClick={toggle}>
                            Disable 2FA
                        </Button>
                    ) : (
                        <Button
                            className="modals-new-btns light-btn-modal"
                            onClick={() =>
                                (window.location.href = window.location.pathname + 'new-2fa-setup')
                            }
                        >
                            Enable 2FA
                        </Button>
                    )}
                </div>
            </div>
        </>
    )
}

export default TwoFactorAuthIntro
