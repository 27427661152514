import React, { useEffect, useRef, useState } from 'react'
import { Text, Transformer, Tag, Label, Rect, Image } from 'react-konva'
import icon from './icon.png'
import useImage from 'use-image'
// import useImage from 'use-image'

const MapObstacle = ({
    stage,
    zone,
    width,
    map,
    height,
    biggerMapSize,
    smallerMapSize,
    isSelected,
    onSelect,
    onObstacleChange,
    cursor,
    isdraggable,
    mouseMove,
    handleClick,
    editPoint,
    toggle,
    size,
    whzones,
    showTagZonesNames,
}) => {
    const shapeRef = useRef()
    const transformRef = useRef()
    const textRef = useRef()
    const patternRef = useRef(document.createElement('canvas'))
    const [text, setText] = useState('')
    const [redLight] = useImage('/svgs/MiniMap/red-light.svg')
    const [greenLight] = useImage('/svgs/MiniMap/green-light.svg')
    const [hover, setHover] = useState()
    const [textWidth, setTextWidth] = useState(0)
    const [textHeight, setTextHeight] = useState(0)
    const [rotation, setRotation] = useState(0)

    useEffect(() => {
        if (isSelected && editPoint) {
            transformRef.current.nodes([shapeRef.current])
            transformRef.current.getLayer().batchDraw()
            stage?.findOne('.rotater').on('mouseenter', () => {
                stage.content.style.cursor = 'url(' + icon + ') 10 20, default'
                setHover('Drag to rotate')
            })
            stage?.findOne('.rotater').on('mouseleave', () => {
                setHover('Drag to rotate')
            })
        }
        setHover('')
    }, [isSelected, stage, zone, editPoint])

    useEffect(() => {
        const patternCanvas = patternRef.current
        patternCanvas.width = 15
        patternCanvas.height = 15
        const ctx = patternCanvas.getContext('2d')

        ctx.beginPath()
        ctx.strokeStyle = '#E7E9EC'
        ctx.moveTo(0, 15)
        ctx.lineTo(15, 0)
        ctx.stroke()

        const shape = shapeRef.current
        if (shape) {
            shape.fillPatternImage(patternCanvas)
            shape.fillPatternScaleX(1)
            shape.fillPatternScaleY(1)
            shape.getLayer().batchDraw()
        }
    }, [])

    useEffect(() => {
        setTextWidth(getTextWidthPixels())
        setTextHeight(getTextHeightPixels())
    }, [showTagZonesNames])

    const handleTransformer = (e) => {
        const node = shapeRef.current
        const minusValue = Math.sign(node.rotation()) === -1
        if (minusValue) {
            const rotate = Math.round(node.rotation()) + 363
            setText(rotate)
            setRotation(rotate)
        } else {
            const rotate = Math.round(node.rotation())
            setText(rotate)
            setRotation(rotate)
        }
    }

    const zoneTypeColor = () => {
        switch (true) {
            case zone.zone_type === 'forbidden':
                return '#FEE4E2'
            case zone.zone_type === 'action':
                return '#F9F5FF'
            case zone.zone_type === 'charging':
                return '#FFF5DF'
            case zone.zone_type === 'resting':
                return '#EFF8FF'
            case zone.newZone === 'safe_zone' || zone.figure_type === 'safezone':
                return 'lightgreen'
            case zone.zone_type === 'tag':
                return null
            case zone.zone_type === 'evacuation':
                return '#F4F4F9'
            case zone.zone_type === 'capacity':
                return '#FFF6ED'
            case zone.zone_type === 'speed_limit':
                return '#FFEAD5'
            default:
                return 'lightgray'
        }
    }

    const zoneBorderColor = () => {
        switch (zone.zone_type) {
            case 'forbidden':
                return '#F04438'
            case 'action':
                return '#B692F6'
            case 'charging':
                return '#FFD67C'
            case 'resting':
                return '#53B1FD'
            case 'tag':
                return '#E7E9EC'
            case 'evacuation':
                return '#8489B9'
            case 'capacity':
                return '#FEB273'
            case 'speed_limit':
                return '#FB6514'
            default:
                return 'black'
        }
    }

    const pointsTransform = (points, zone, node) => {
        let corners = [...points]

        for (let i = 0; i < corners.length; i++) {
            corners[i] = {
                x: zone.points[i][0],
                y: zone.points[i][1],
            }
        }

        for (let i = 0; i < corners.length; i++) {
            // Here be the magic
            corners[i] = node.getTransform().point(corners[i])
        }

        return corners.map((cor, i) => [Math.round(cor?.x), Math.round(cor?.y)])
    }

    const getTextWidthPixels = () => textRef.current?.getTextWidth()
    const getTextHeightPixels = () => textRef.current?.getTextHeight()

    if (!zone) return null
    if (zone.zone_type === 'obstacle' || zone.figure_type === 'obstacle') return

    return (
        <>
            <Rect
                draggable={isdraggable}
                closed={true}
                ref={shapeRef}
                x={zone.x}
                y={zone.y}
                width={zone.width}
                height={zone.height}
                rotation={zone.rotation}
                stroke={zoneBorderColor()}
                strokeWidth={
                    width * height < smallerMapSize
                        ? 0.4
                        : width * height > biggerMapSize
                        ? 7
                        : width < 200
                        ? 0.4
                        : 1.2
                }
                fill={zoneTypeColor()}
                opacity={
                    isSelected &&
                    zone.zone_type !== 'forbidden' &&
                    zone.zone_type !== 'tag' &&
                    zone.zone_type !== 'action' &&
                    zone.figure_type !== 'obstacle'
                        ? null
                        : 0.6
                }
                onMouseDown={() => editPoint && onSelect()}
                onDragMove={mouseMove}
                onDragEnd={() => {
                    const node = shapeRef.current
                    const scaleX = node.scaleX()
                    const scaleY = node.scaleY()

                    const centerX = Math.round(node.x() + (node.width() * scaleX) / 2)
                    const centerY = Math.round(node.y() + (node.height() * scaleY) / 2)

                    onObstacleChange({
                        ...zone,
                        x: Math.round(node.x()),
                        y: Math.round(node.y()),
                        centroid: [centerX, centerY],
                        width: node.width(),
                        height: node.height(),
                        points: pointsTransform(zone.points, zone, node),
                    })
                }}
                onClick={(e) => {
                    if (
                        !editPoint &&
                        map === 'editor' &&
                        zone.figure_type !== 'obstacle' &&
                        zone.newZone !== 'obstacle'
                    ) {
                        handleClick(zone, toggle)
                    }
                }}
                onTransform={handleTransformer}
                onTransformEnd={() => {
                    const node = shapeRef.current
                    const scaleX = node.scaleX()
                    const scaleY = node.scaleY()

                    node.scaleX(1)
                    node.scaleY(1)

                    onObstacleChange({
                        ...zone,
                        rotation,
                        x: Math.round(node.x()),
                        y: Math.round(node.y()),
                        width: Math.round(node.width() * scaleX),
                        height: Math.round(node.height() * scaleY),
                        points: pointsTransform(zone.points, zone, node),
                    })
                }}
            />

            {whzones?.uuid === zone.uuid && (
                <Image
                    Image={whzones?.zone_vehicle_limit_exceeded ? redLight : greenLight}
                    ref={shapeRef}
                    x={zone.x - 10}
                    y={zone.y - 10}
                    width={20}
                    height={20}
                />
            )}

            {isSelected && (
                <Transformer
                    ref={transformRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox
                        }
                        return newBox
                    }}
                />
            )}
            {isSelected && hover && (
                <>
                    <Label x={cursor.x + 5} y={cursor.y - size}>
                        <Tag fill="#f0f1f3" cornerRadius={50}></Tag>
                        <Text
                            width={size * 6}
                            height={size * 6}
                            align="center"
                            lineHeight={3}
                            text={text + '°'}
                            fontSize={size * 2}
                            fontStyle="bold"
                        ></Text>
                    </Label>
                    <Text
                        x={cursor.x + 5}
                        y={cursor.y - size * 2}
                        stroke="#670d95"
                        strokeWidth={0.1}
                        text={hover}
                        fontStyle="bold"
                        fontSize={size}
                    />
                </>
            )}
            {zone.zone_type === 'tag' && showTagZonesNames && (
                <Text
                    x={
                        zone.points[0][0] +
                        (zone.points[2][0] - zone.points[0][0]) / 2 -
                        textWidth / 2
                    }
                    y={
                        zone.points[0][1] +
                        (zone.points[2][1] - zone.points[0][1]) / 2 -
                        textHeight / 2
                    }
                    stroke="#670d95"
                    ref={textRef}
                    strokeWidth={0.1}
                    text={zone?.title}
                    fontStyle="bold"
                    fontSize={size}
                />
            )}
        </>
    )
}

export default MapObstacle
