import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'
import { TITLE } from 'config'
import { AgGridReact } from 'ag-grid-react'
import LoadingSpinner from 'components/utils/LoadingSpinner'
import ManageOrganizationModals from './ManageOrganizationModals'
import {
    deleteDeviceModel,
    getOrgDeviceModels,
    resetDeviceModels,
} from 'features/dashboard/RosSetup.actionTypes'
import dayjs from 'dayjs'
import { selectors } from 'features/dashboard'
import { getOrgTeams } from 'features/dashboard/Organization.actionTypes'
import { validateDate } from './OrgTeamHelpers'
import Filter from 'layout-components/Filter'
import { HiOutlineX } from 'react-icons/hi'

const DeviceModelsManage = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const setupStore = useSelector(selectors.getSetup)
    const teams = useSelector(selectors.getOrganizationTeams)
    const [currentOrg] = useState(JSON.parse(localStorage.getItem('org')))
    const [modal, setModal] = useState(false)
    const [form, setForm] = useState(null)
    const [dataOnClick, setDataOnClick] = useState({})
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [lastClickedPage, setLastClickedPage] = useState(0)
    const [searchText, setSearchText] = useState('')

    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [initialFilterValues, setInitialFilterValues] = useState({
        team: [],
        device_model_type: [],
    })
    const dropdownToggle = () => setDropdownOpen(!dropdownOpen)
    const toggle = () => setModal(!modal)
    const gridRef = useRef()
    const [api, setApi] = useState(null)

    const onGridReady = useCallback(
        (params) => {
            gridRef.current = params.api // Store grid API in gridRef
            params.api.sizeColumnsToFit() // Resize columns once the grid is ready
            setApi(params.api)

            if (lastClickedPage > 1) {
                setTimeout(() => {
                    params.api.paginationGoToPage(lastClickedPage - 1) // Adjust for zero-based index
                }, 0)
            }
        },
        [lastClickedPage]
    )

    const getCurrentPage = () => {
        if (api && !api.isDestroyed()) {
            const currentPage = api.paginationGetCurrentPage() + 1 // Add 1 to convert to 1-based index
            setLastClickedPage(currentPage)
        }
    }

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase()
        setSearchText(value)
        api && api.onFilterChanged(value)
    }

    useEffect(() => {
        document.title = `${TITLE} - Device models manage`
    }, [])

    useEffect(() => {
        dispatch(getOrgTeams(currentOrg.slug))
        dispatch(getOrgDeviceModels({ slug: currentOrg.slug, page: currentPageNumber }))
    }, [currentOrg.slug, dispatch, currentPageNumber])

    useEffect(() => {
        if (currentPageNumber > 1) {
            dispatch(
                getOrgDeviceModels({
                    slug: currentOrg.slug,
                    page: currentPageNumber,
                    filters: initialFilterValues,
                })
            )
        }
    }, [currentPageNumber]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!modal) {
            setDataOnClick({})
            setForm(null)
        }
    }, [modal])

    const handleRemoveDeviceModel = (data) => {
        dispatch(deleteDeviceModel(data.uuid)).then(({ error }) => {
            if (!error) {
                getCurrentPage()
                dispatch(
                    getOrgDeviceModels({
                        slug: currentOrg.slug,
                        page: currentPageNumber,
                        filters: initialFilterValues,
                    })
                )
            }
            toggle()
        })
    }

    const RenderDeviceManage = ({ item, form }) => (
        <div className="d-flex justify-content-center align-items-center">
            <img
                style={{ cursor: 'pointer' }}
                src="/svgs/organization/buttons/trash.svg"
                alt="trash"
                onClick={() => {
                    setDataOnClick(item)
                    setForm(form.remove)
                    setModal(true)
                }}
            />
            <img
                style={{
                    marginLeft: '1rem',
                    cursor: 'pointer',
                }}
                src="/svgs/trigger-actions/edit.svg"
                alt="edit"
                onClick={() => {
                    setDataOnClick(item)
                    setForm(form.edit)
                    setModal(true)
                }}
            />
        </div>
    )

    const dataDevices = useMemo(() => {
        return setupStore?.device_models?.results?.map((device) => {
            return {
                uuid: device.uuid,
                name: device.name || '-',
                team: device.team.title || '-',
                teamObj: device.team,
                device_model_type: device.device_model_type || '-',
                created_at: <div>{validateDate(dayjs(device.metadata.created_at))}</div>,
                updated_at: <div>{validateDate(dayjs(device.metadata.updated_at))}</div>,
                action: (
                    <RenderDeviceManage
                        item={device}
                        form={{ edit: 'edit-device-model', remove: 'remove-device-model' }}
                    />
                ),
            }
        })
    }, [setupStore.device_models.results])

    const defaultColDef = {
        cellClass: 'cell-class', // Apply custom class to all cells
        headerClass: 'custom-header', // Apply custom class to all headers
    }

    const customDateComparator = (date1, date2) => {
        return new Date(date1.props.children[0]) < new Date(date2.props.children[0]) ? -1 : 1
    }

    const devicesColumns = [
        {
            field: 'name',
            headerName: 'Name',
            sortable: true,
            unSortIcon: true,
        },
        {
            field: 'team',
            headerName: 'Team',
            sortable: true,
            unSortIcon: true,
            valueFormatter: (params) => {
                return params.value
            },
            cellRenderer: (row) => {
                return (
                    <div
                        className="team-div-cell"
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                            history.push({
                                pathname: `/dashboard/organization/teams/${row.data.team}/`,
                                state: {
                                    slug: row.data.team.teamUuid,
                                    team: row.data.team.teamUuid,
                                },
                            })
                        }
                    >
                        {row.data.team}
                    </div>
                )
            },
        },
        {
            field: 'device_model_type',
            headerName: 'Device Model Type',
        },
        // {
        //     dataField: 'pin',
        //     text: 'PIN',
        //     sort: true,
        //     filter: textFilter(),
        // },
        {
            field: 'created_at',
            headerName: 'Created At',
            valueFormatter: (params) => {
                return params.value
            },
            cellRenderer: (params) => {
                return <div>{params.data?.created_at.props.children}</div>
            },
        },
        {
            field: 'updated_at',
            headerName: 'Updated at',
            sortable: true,
            comparator: customDateComparator,
            valueFormatter: (params) => {
                return params.value
            },
            cellRenderer: (params) => {
                return <div>{params.data?.updated_at.props.children}</div>
            },
        },
        {
            field: 'action',
            headerName: '',
            cellClass: 'action-style',
            maxWidth: 100,
            valueFormatter: (params) => {
                return params.value
            },
            cellRenderer: (params) => {
                return <div>{params.data.action}</div>
            },
        },
    ]

    return (
        <div className="big-org-container">
            <div className="heading-container-org" style={{ marginTop: '32px' }}>
                <div>
                    <div className="mb-2 d-flex align-items-center">
                        <span className="header-text-org">Device models</span>
                        {setupStore.status === 'loaded' && (
                            <span
                                className="text-meili rounded px-2 badges"
                                style={{ backgroundColor: '#F9EFFE', marginLeft: '24px' }}
                            >
                                {setupStore?.device_models?.count} device models
                            </span>
                        )}
                    </div>
                    <span className="small-text-org">
                        Manage your device models and their settings here.
                    </span>
                </div>
                <div className="d-flex">
                    <Button
                        className="new-buttons-desing-org-purple org-page-btns text-white"
                        onClick={() => {
                            setForm('add-device-model')
                            toggle()
                        }}
                        style={{ height: '40px' }}
                    >
                        <img src="/svgs/trigger-actions/add.svg" alt="plus" />
                        Add device model
                    </Button>
                </div>
            </div>
            <div className="search-header">
                {' '}
                <input
                    type="text"
                    className="search-box"
                    placeholder="Search"
                    value={searchText}
                    onChange={handleSearch}
                ></input>
                <Filter
                    table="devices"
                    slug={currentOrg.slug}
                    initValues={initialFilterValues}
                    setInitValues={setInitialFilterValues}
                    setCurrentPageNumber={setCurrentPageNumber}
                    setLastClickedPage={setLastClickedPage}
                    dropdownOpen={dropdownOpen}
                    toggle={dropdownToggle}
                    teams={teams}
                />
            </div>
            {setupStore.status === 'loading' ? (
                <LoadingSpinner />
            ) : (
                <>
                    {[...initialFilterValues.team, ...initialFilterValues.device_model_type]
                        .length > 0 && (
                        <div className="d-flex align-items-start">
                            {[
                                ...initialFilterValues.team,
                                ...initialFilterValues.device_model_type,
                            ].map((item, index) => (
                                <div
                                    key={index}
                                    className="filter-style  d-flex align-items-center text-meili"
                                    style={{
                                        backgroundColor: '#F9EFFE',
                                        marginRight: '8px',
                                    }}
                                >
                                    <HiOutlineX
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setInitialFilterValues((prevValues) => {
                                                const newTeam = prevValues.team.filter(
                                                    (t) => t !== item
                                                )
                                                const newDeviceModelType =
                                                    prevValues.device_model_type.filter(
                                                        (type) => type !== item
                                                    )

                                                const updatedValues = {
                                                    team: newTeam,
                                                    device_model_type: newDeviceModelType,
                                                }

                                                dispatch(resetDeviceModels())
                                                setLastClickedPage(0)
                                                setCurrentPageNumber(1)
                                                dispatch(
                                                    getOrgDeviceModels({
                                                        slug: currentOrg.slug,
                                                        filters: updatedValues,
                                                    })
                                                )

                                                return updatedValues
                                            })
                                        }}
                                    />
                                    {item}
                                </div>
                            ))}
                        </div>
                    )}
                    <div className="rounded-table-container ag-theme-quartz">
                        {dataDevices?.length > 0 ? (
                            <AgGridReact
                                ref={gridRef}
                                classes="ag-header-group-cell w-100"
                                keyField="uuid"
                                rowData={dataDevices}
                                columnDefs={devicesColumns}
                                onGridReady={onGridReady}
                                pagination={true}
                                paginationPageSize={10}
                                paginationPageSizeSelector={[10, 20, 50, 100]}
                                defaultColDef={defaultColDef}
                                rowHeight={70}
                                onPaginationChanged={getCurrentPage}
                                quickFilterText={searchText}
                                domLayout="autoHeight"
                                rowClassRules={{
                                    'gray-row': (params) => params.node.rowIndex % 2 === 1,
                                }}
                            />
                        ) : (
                            <h5 className="p-4">There are currently no device models.</h5>
                        )}
                        {setupStore?.device_models?.next &&
                            lastClickedPage === Math.ceil(dataDevices?.length / 10) && (
                                <Button
                                    style={{
                                        position: 'absolute',
                                        bottom: '0.7rem',
                                        right: '24px',
                                        borderRadius: '8px',
                                        background: '#f9fafb',
                                        color: '#4e5462',
                                    }}
                                    onClick={() => setCurrentPageNumber(currentPageNumber + 1)}
                                >
                                    Load more (
                                    {setupStore.device_models.count -
                                        setupStore.device_models.results.length}
                                    )
                                </Button>
                            )}
                    </div>
                </>
            )}
            <ManageOrganizationModals
                slug={currentOrg?.slug}
                form={form}
                modal={modal}
                toggle={toggle}
                filters={initialFilterValues}
                setForm={setForm}
                dataOnClick={dataOnClick}
                currentPageNumber={currentPageNumber}
                submitDelete={handleRemoveDeviceModel}
            />
        </div>
    )
}

export default DeviceModelsManage
