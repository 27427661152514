import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import { Button, Label, FormGroup, Spinner, Input } from 'reactstrap'
import { useParams } from 'react-router-dom'
import { customInputForm } from 'ui/common'
import {
    createNewPreset,
    getPresetTasksV2,
    updatePresetTaskV2,
    cleanFormValues,
    updateActionsPresets,
    getActionsPresets,
    createActionsPresets,
    getPreset,
} from 'features/dashboard/Task.actionTypes'
import RenderDropDown from './RenderDropDown'
import { HiOutlineTrash } from 'react-icons/hi'
import { removeEmptyStringsFromObj, removeNullFromObj } from 'utils'
import { selectors } from 'features/dashboard'
import DropdownSelect from 'layout-components/Dropdown'
import { getFilteredVehicles } from './helpers'
import LoadingSpinner from 'components/utils/LoadingSpinner'

const PreSetTaskFormNew = ({
    toggle,
    preset = {},
    stations,
    taskStore,
    devices,
    actions,
    actionsPresets,
    activeTab,
    toggleRemove,
    currentPageNumber,
}) => {
    const dispatch = useDispatch()
    const { slug } = useParams()
    const [initPreset, setInitPreset] = useState({})
    const vehicles = useSelector(selectors.getVehicles)
    const categories = useSelector(selectors.getVehiclesCategoires)
    const movementOptions = [
        { title: 'Everywhere', uuid: 'everywhere' },
        { title: 'Zone presets', uuid: 'zone_presets' },
        { title: 'Nowhere', uuid: 'nowhere' },
    ]
    const [movement, setMovement] = useState(preset?.autogenerate_movement || '')
    const [category, setCategory] = useState(
        categories.find((cat) => cat.uuid === preset.required_vehicle_category)?.title || ''
    )
    const [robot, setRobot] = useState(preset.vehicle?.verbose_name || '')

    useEffect(() => {
        activeTab === 'presets' &&
            preset.uuid &&
            dispatch(getPreset(preset.uuid)).then((res) => setInitPreset(res.payload.data))
    }, [preset.uuid, activeTab, dispatch])

    const mergedArray = [...actions, ...actionsPresets]
    const handleSubmit = async ({ uuid, ...outputs }, { setErrors, setSubmitting, setStatus }) => {
        let {
            definition,
            point,
            description,
            device_state,
            device_components,
            auto_confirmationSubtask,
            ...rest
        } = outputs
        if (activeTab === 'presets') {
            const subtasks = definition?.map(async (def, i) => ({
                index: i + 1,
                auto_confirmation: auto_confirmationSubtask[i],
                action: {
                    definition: def.definition || def.uuid,
                    ...(def.form_values && {
                        form_values: def.form_values,
                    }),
                    ...(def.device_type === 'iot'
                        ? {
                              ...(def.point_information
                                  ? { point: def.point_information.uuid }
                                  : point[i]
                                  ? { point: point[i] }
                                  : {}),
                              ...(device_state &&
                                  device_state[i] && { device_state: device_state[i] }),
                              ...(device_components &&
                                  device_components[i] && {
                                      device_components: device_components[i],
                                  }),
                          }
                        : def.slug === 'follow_path'
                        ? {
                              ...(def.point_information
                                  ? { path: def.point_information.uuid }
                                  : point[i]
                                  ? { path: point[i].map((p) => p.uuid) }
                                  : {}),
                          }
                        : {
                              ...(def.point_information
                                  ? { point: def.point_information.uuid }
                                  : point[i]
                                  ? { point: point[i] }
                                  : {}),
                          }),
                },
            }))

            const updatedSubtask = await Promise.all(subtasks)
            const data = {
                ...rest,
                subtasks: updatedSubtask,
            }
            const removeNull = removeNullFromObj(data)
            const removeString = removeEmptyStringsFromObj(removeNull)
            if (uuid) {
                return dispatch(updatePresetTaskV2({ uuid, data: removeString })).then(
                    ({ error }) => {
                        setSubmitting(false)
                        dispatch(getPresetTasksV2({ slug, page: currentPageNumber }))
                        if (error) {
                            return setErrors(error.response.data.subtasks)
                        }
                        toggle()
                        dispatch(cleanFormValues())
                    }
                )
            } else {
                return dispatch(createNewPreset({ slug, data: removeString })).then(({ error }) => {
                    setSubmitting(false)
                    if (error) {
                        setStatus('error')
                        return setErrors(error.response.data.subtasks)
                    }
                    dispatch(getPresetTasksV2({ slug, page: currentPageNumber }))
                    setStatus('saving')
                    toggle()
                    dispatch(cleanFormValues())
                })
            }
        }

        if (activeTab === 'actions-presets') {
            if (uuid) {
                const data = {
                    ...rest,
                    description,
                    definition: definition.uuid,
                    ...(definition.form_values && {
                        form_values: definition.form_values,
                    }),
                    ...(definition.device_type === 'iot'
                        ? {
                              ...(definition.point_information
                                  ? { point: definition.point_information.uuid }
                                  : point && { point }),
                              ...(device_state &&
                                  Object.keys(device_state).length > 0 && { device_state }),
                              ...(device_components &&
                                  Object.keys(device_components).length > 0 && {
                                      device_components,
                                  }),
                          }
                        : definition.slug === 'follow_path'
                        ? {
                              ...(definition.point_information
                                  ? { path: definition.point_information.uuid }
                                  : point?.length && { path: point.map((p) => p.uuid) }),
                          }
                        : {
                              ...(definition.point_information
                                  ? { point: definition.point_information.uuid }
                                  : point && { point }),
                          }),
                }

                return dispatch(
                    updateActionsPresets({
                        uuid,
                        data,
                    })
                ).then(({ error }) => {
                    setSubmitting(false)
                    dispatch(getActionsPresets({ slug }))
                    if (error) {
                        return setErrors(error.response.data.subtasks)
                    }
                    toggle()
                    dispatch(cleanFormValues())
                })
            } else {
                const data = {
                    ...rest,
                    definition: definition[0].uuid,
                    ...(definition[0].form_values &&
                        Object.values(definition[0].form_values).length > 0 && {
                            form_values: definition[0].form_values,
                        }),
                    ...(definition[0].device_type === 'iot'
                        ? {
                              ...(definition[0].point_information
                                  ? { point: definition[0].point_information.uuid }
                                  : point[0] && { point: point[0] }),
                              ...(device_state &&
                                  Object.keys(device_state).length > 0 && { device_state }),
                              ...(device_components &&
                                  Object.keys(device_components).length > 0 && {
                                      device_components,
                                  }),
                          }
                        : definition[0].slug === 'follow_path'
                        ? {
                              ...(definition[0].point_information
                                  ? { path: definition[0].point_information.uuid }
                                  : point[0]?.length && { path: point[0].map((p) => p.uuid) }),
                          }
                        : {
                              ...(definition[0].point_information
                                  ? { point: definition[0].point_information.uuid }
                                  : point[0] && { point: point[0] }),
                          }),
                }

                return dispatch(
                    createActionsPresets({
                        slug,
                        data,
                    })
                ).then(({ error }) => {
                    setSubmitting(false)
                    if (error) {
                        setStatus('error')
                        return setErrors(error.response.data.subtasks)
                    }
                    dispatch(getActionsPresets({ slug }))
                    setStatus('saving')
                    toggle()
                    dispatch(cleanFormValues())
                })
            }
        }
    }

    const initValues = () => {
        switch (activeTab) {
            case 'actions-presets':
                if (Object.values(preset).length !== 0) {
                    return {
                        uuid: preset.uuid,
                        auto_confirmationSubtask: preset.auto_confirmation,
                        displayable_name: preset.displayable_name,
                        description: preset.description,
                        device_state: preset.device_state,
                        device_components: preset.device_components,
                        definition: {
                            ...preset.definition_information,
                            form_values: preset.form_values,
                            form_variables: preset.form_variables,
                            form: null,
                        },
                        point:
                            preset.path.length > 0
                                ? preset?.path || []
                                : (preset.point_information && preset.point_information.uuid) || '',
                    }
                }
                return {
                    ...preset,
                    definition: [],
                    point: [],
                    device_state: [],
                    device_components: [],
                    auto_confirmationSubtask: [],
                }
            case 'presets':
                if (Object.values(initPreset).length !== 0) {
                    return {
                        ...initPreset,
                        vehicle_uuid: initPreset.vehicle?.uuid,
                        auto_confirmationSubtask: initPreset.subtasks.map(
                            (subtask) => subtask.auto_confirmation
                        ),
                        device_state: initPreset.subtasks.map(
                            (subtask) => subtask.action.device_state || ''
                        ),
                        ...initPreset.subtasks.map((subtask) =>
                            subtask.action.device_components
                                ? { device_components: subtask.action.device_components }
                                : {}
                        ),
                        definition: initPreset.subtasks.map((subtask) => ({
                            ...subtask.action.definition_information,
                            form_values: subtask.action.form_values,
                            form_variables: subtask.action.form_variables,
                            form: null,
                        })),
                        point: initPreset.subtasks.map((subtask) =>
                            subtask.action.path.length > 0
                                ? subtask?.action?.path || []
                                : (subtask.action.point_information &&
                                      subtask?.action?.point_information.uuid) ||
                                  ''
                        ),
                    }
                }
                return {
                    ...preset,
                    definition: [],
                    point: [],
                    auto_confirmationSubtask: [],
                    device_state: [],
                    device_components: [],
                }

            default:
                return {
                    ...preset,
                    definition: [],
                    point: [],
                    auto_confirmationSubtask: [],
                    device_state: [],
                }
        }
    }

    if (activeTab === 'presets' && preset.uuid && !initPreset.uuid) return

    return taskStore.status === 'loading' ? (
        <div style={{ minHeight: '600px' }}>
            <LoadingSpinner />
            <span>Fetching actions...</span>
        </div>
    ) : (
        <>
            {' '}
            <div
                style={{ marginBottom: '24px' }}
                className={`w-100 d-flex ${
                    preset.uuid ? 'justify-content-end' : 'justify-content-between'
                }`}
            >
                {!preset.uuid && (
                    <img
                        src="/svgs/trigger-actions/add-plus.svg"
                        alt="plus"
                        width="48px"
                        height="48px"
                    />
                )}
                <img
                    src="/svgs/close-icon/x-dark-default.svg"
                    alt="x"
                    width="24px"
                    height="24px"
                    style={{ cursor: 'pointer' }}
                    onClick={toggle}
                />
            </div>
            <h5>
                {preset.uuid ? (
                    activeTab === 'presets' ? (
                        <strong className="modals-header">Edit mission preset</strong>
                    ) : (
                        <strong className="modals-header">Edit action preset</strong>
                    )
                ) : activeTab === 'presets' ? (
                    <strong className="modals-header">New mission preset</strong>
                ) : (
                    <strong className="modals-header">New action preset</strong>
                )}
            </h5>
            <Formik
                initialValues={initValues()}
                // validationSchema={presetTaskSchema}
                onSubmit={handleSubmit}
            >
                {({ values, isSubmitting, setFieldValue }) => (
                    <Form>
                        <FormGroup data-testid="title">
                            <Label className="modals-labels" for="title">
                                Title *
                            </Label>
                            <Field
                                component={customInputForm}
                                type="text"
                                className="modals-inputs"
                                name={activeTab === 'presets' ? 'title' : 'displayable_name'}
                                value={
                                    activeTab === 'presets'
                                        ? values.title
                                        : values.displayable_name || ''
                                }
                                placeholder="Title"
                                required
                            />
                        </FormGroup>
                        {activeTab === 'actions-presets' && (
                            <FormGroup>
                                <Label className="modals-labels" for="description">
                                    Description
                                </Label>
                                <Field
                                    component={customInputForm}
                                    type="text"
                                    className="modals-inputs"
                                    name="description"
                                    value={values.description || ''}
                                    placeholder="Description"
                                />
                            </FormGroup>
                        )}
                        <FormGroup>
                            <Label
                                className="modals-labels"
                                for="definition"
                                style={{
                                    position: 'sticky',
                                    top: '-15px',
                                    zIndex: '1000',
                                    backgroundColor: 'white',
                                    width: '100rem',
                                }}
                            >
                                Actions *
                            </Label>
                            <RenderDropDown
                                definitionValues={values.definition}
                                pointValues={values.point}
                                device_state={values.device_state}
                                auto_confirmation={values.auto_confirmationSubtask}
                                setFieldValue={setFieldValue}
                                actions={activeTab === 'actions-presets' ? actions : mergedArray}
                                stations={stations}
                                devices={devices}
                                property="definition"
                                activeTab={activeTab}
                                values={values}
                            />
                            <Field
                                component={customInputForm}
                                type="hidden"
                                id="definition"
                                name="definition"
                            />
                        </FormGroup>

                        {activeTab === 'presets' && (
                            <>
                                <FormGroup>
                                    <Label className="modals-labels" for="auto-movement">
                                        Autogenerate movement
                                    </Label>
                                    <DropdownSelect
                                        selected={movement}
                                        setSelected={setMovement}
                                        options={['', ...movementOptions]}
                                        setFieldValue={setFieldValue}
                                        fieldValue="autogenerate_movement"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="modals-labels" for="cat">
                                        Vehicle category
                                    </Label>
                                    <DropdownSelect
                                        selected={category}
                                        setSelected={setCategory}
                                        options={[{ title: 'No category' }, ...categories]}
                                        setFieldValue={setFieldValue}
                                        fieldValue="required_vehicle_category"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="modals-labels" for="vehicle">
                                        Robot
                                    </Label>
                                    <DropdownSelect
                                        selected={robot}
                                        setSelected={setRobot}
                                        options={['', ...getFilteredVehicles(vehicles, values)]}
                                        setFieldValue={setFieldValue}
                                        fieldValue="vehicle_uuid"
                                    />
                                </FormGroup>
                                <FormGroup
                                    style={{ marginTop: '24px', marginBottom: '24px' }}
                                    className="d-flex flex-column"
                                >
                                    <Label className="modals-labels" for="priority_level">
                                        Priority
                                    </Label>
                                    <Field
                                        id="priority_level"
                                        name="range"
                                        type="range"
                                        value={values.priority_level || 0}
                                        onChange={(e) => {
                                            setFieldValue('priority_level', Number(e.target.value))
                                        }}
                                    />
                                    <output>{values.priority_level}</output>
                                </FormGroup>
                                <FormGroup switch className="mb-2 ml-3">
                                    <Input
                                        style={{ height: '20px', width: '36px' }}
                                        type="switch"
                                        role="switch"
                                        className="custom-switch-form modals-labels"
                                        id="auto_confirmation"
                                        checked={values.auto_confirmation}
                                        name="auto_confirmation"
                                        onChange={(e) => {
                                            setFieldValue(e.target.name, !values.auto_confirmation)
                                        }}
                                    />
                                    <Label check className="ml-3 mt-1">
                                        Auto-confirmation
                                    </Label>
                                </FormGroup>
                                <FormGroup switch className="d-flex flex-column ml-3">
                                    <Input
                                        style={{ height: '20px', width: '36px' }}
                                        type="switch"
                                        role="switch"
                                        id="looped_task"
                                        className="custom-switch-form modals-labels"
                                        checked={values.looped_task}
                                        name="looped_task"
                                        onChange={(e) => {
                                            setFieldValue(e.target.name, !values.looped_task)
                                            setFieldValue('schedule_later', false)
                                        }}
                                    />
                                    <Label check className="ml-3 mt-1">
                                        Looped mission
                                    </Label>
                                </FormGroup>
                            </>
                        )}

                        <div
                            style={{ marginTop: '24px' }}
                            className={`w-100 d-flex ${
                                preset.uuid ? 'justify-content-between' : 'justify-content-end'
                            } align-items-center`}
                        >
                            {preset.uuid ? (
                                <Button
                                    color="none"
                                    onClick={() => {
                                        toggleRemove()
                                    }}
                                    className="modals-delete-btn"
                                >
                                    <HiOutlineTrash size={20} className="mr-1" />
                                    Delete preset
                                </Button>
                            ) : null}
                            <div className="d-flex">
                                <Button
                                    color="white"
                                    className="cancel-btn-modal modals-new-btns mr-2"
                                    onClick={() => toggle()}
                                >
                                    Discard
                                </Button>
                                <Button type="submit" className="save-btn-modal modals-new-btns">
                                    Save
                                    {isSubmitting && <Spinner color="white" size="sm ml-2" />}
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default PreSetTaskFormNew
