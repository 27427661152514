import React, { useState, useEffect } from 'react'
import { Button, ButtonGroup } from 'reactstrap'
import { TITLE } from 'config'
import MyDetails from './MyDetails'
import Notifications from './Notifications'
import { useDispatch } from 'react-redux'
import { get2FASecret, getAccessToken, getProfile } from 'features/user/actionTypes'
import { getNotificationSettings } from 'features/dashboard/Notification.actionTypes'
import Security from './Security'

const Settings = () => {
    const dispatch = useDispatch()
    const [isActive, setIsActive] = useState('general')
    const [twoFactorEnabled, setTwoFactorEnabled] = useState(false)

    useEffect(() => {
        document.title = `${TITLE} - Settings`
    }, [])

    useEffect(() => {
        dispatch(getProfile())
        dispatch(getAccessToken())
        dispatch(get2FASecret()).then((response) => {
            if (!response.error) setTwoFactorEnabled(response.payload.data.enabled)
        })
        dispatch(getNotificationSettings())
    }, [dispatch])

    const ContentSwitcher = () => {
        switch (isActive) {
            case 'general':
                return <MyDetails />
            case 'notifications':
                return <Notifications />
            default:
                return (
                    <Security
                        twoFactorEnabled={twoFactorEnabled}
                        setTwoFactorEnabled={setTwoFactorEnabled}
                    />
                )
        }
    }

    return (
        <div>
            <div className="w-100" style={{ marginTop: '-45px' }}>
                <h3>Settings</h3>
            </div>
            <ButtonGroup
                className="org-button-group mt-4"
                style={{ width: '25rem', padding: '3px' }}
            >
                <Button
                    className={`${isActive === 'general' ? 'org-button-selected' : ''} org-button`}
                    style={{ height: '2.5rem', borderRadius: '6px' }}
                    onClick={() => setIsActive('general')}
                >
                    My details
                </Button>
                <Button
                    className={`${
                        isActive === 'notifications' ? 'org-button-selected' : ''
                    } org-button`}
                    style={{ height: '2.5rem', borderRadius: '6px' }}
                    onClick={() => setIsActive('notifications')}
                >
                    Notifications
                </Button>
                <Button
                    className={`${isActive === 'security' ? 'org-button-selected' : ''} org-button`}
                    style={{ height: '2.5rem', borderRadius: '6px' }}
                    onClick={() => setIsActive('security')}
                >
                    Security
                </Button>
            </ButtonGroup>
            {ContentSwitcher()}
        </div>
    )
}

export default Settings
