import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Form, Input } from 'reactstrap'
import { loginUser } from 'features/user/actionTypes'
import secureLocalStorage from 'react-secure-storage'
import { useQuery } from 'utils'
import { Formik } from 'formik'

const Login2FA = () => {
    const data = JSON.parse(secureLocalStorage.getItem('user'))
    const dispatch = useDispatch()
    const query = useQuery(window.location)

    const handleConfirm = (code) => {
        data.mfa_token = code
        dispatch(loginUser(data)).then(({ error }) => {
            if (!error) {
                data.rememberme
                    ? secureLocalStorage.setItem('user', JSON.stringify(data))
                    : secureLocalStorage.removeItem('user')
                const nextPage = query.get('next') || '/dashboard/'
                return (window.location = nextPage)
            }
        })
    }

    return (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <div className="d-flex flex-column justify-content-center align-items-center gap-4">
                <img
                    src="/svgs/modal-icons/security.svg"
                    alt="obstacle-icon"
                    width="56px"
                    height="56px"
                />
                <div className="d-flex flex-column justify-content-start align-items-center gap-1">
                    <h4>Two-factor authentication</h4>
                    <span>Enter the code displayed in your authenticator app.</span>
                </div>
                <Formik
                    initialValues={{ authCode: '' }}
                    onSubmit={(values) => {
                        handleConfirm(values.authCode)
                    }}
                >
                    {({ setFieldValue, handleSubmit, values }) => (
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault()
                                handleSubmit()
                            }}
                            className="d-flex flex-column justify-content-start align-items-center gap-3"
                            style={{ width: '85%' }}
                        >
                            <Input
                                className="modals-inputs"
                                placeholder="Code"
                                height={40}
                                autoFocus
                                name="authCode"
                                value={values.authCode || ''}
                                onChange={(e) => setFieldValue('authCode', e.target.value)}
                            />
                            <Button
                                className="modals-new-btns save-btn-modal w-100"
                                type="submit"
                                style={{ height: '40px' }}
                            >
                                Confirm
                            </Button>
                        </Form>
                    )}
                </Formik>
                <div
                    className="grey-600 text-center mx-auto mb-5 mt-4 size-14 weight-500"
                    onClick={() => window.history.back()}
                    style={{ width: '340px' }}
                >
                    <img
                        src="/svgs/user-icon/arrow.svg"
                        style={{ cursor: 'pointer', paddingRight: '8px' }}
                        alt="Logout"
                    ></img>
                    <span style={{ cursor: 'pointer', lineHeight: '28px' }}>Back to log in</span>
                </div>
            </div>
        </div>
    )
}

export default Login2FA
