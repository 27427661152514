import React from 'react'
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom'
import { selectors } from 'features/dashboard'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap'
import cx from 'classnames'
import { useToggler } from 'utils'
import { PageTitle, Sidebar } from 'layout-components'
import DashboardHome from 'components/dashboard-components/DashboardHome'
import TeamMap from 'components/dashboard-components/Teams/TeamMap'
import MapEditor from 'components/dashboard-components/MapComponents/MapEditor'
import MapUpload from 'components/dashboard-components/MapComponents/MapUpload'
import VehicleManage from 'components/dashboard-components/VehicleManage'
import TriggerManage from 'components/dashboard-components/Triggers/TriggerManage'
import Settings from 'components/dashboard-components/SettingsPage/Settings'
import TeamSelect from 'components/dashboard-components/Teams/TeamSelect'
import OutsideMapCreate from 'components/dashboard-components/MapComponents/OutsideMapCreate'
import OrganizationRoutes from './OrganizationRoutes'
import Support from 'components/dashboard-components/organization/Support'
import TeamPageProtectedRoute from './TeamPageProtectedRoute'
import TeamTasksV2 from 'components/dashboard-components/Teams/TeamTasksV2'
import CustomPage from 'components/dashboard-components/CustomPage'
import TwoFactorAuthentication from 'components/dashboard-components/SettingsPage/TwoFactorAuthentication'

const DashboardRoutes = ({ team }) => {
    let { path } = useRouteMatch()
    const location = useLocation()
    const [isHidden, toggle] = useToggler(true)
    const currentPath = location.pathname

    const shouldScroll = (location) => {
        // Add the exact paths for routes that shouldn't have scroll here
        return (
            location === `/dashboard/${team.details.slug}/maps/` ||
            location === `/dashboard/${team.details.slug}/maps/modify/` ||
            location === `/dashboard/${team.details.slug}/missions/`
        )
    }

    return (
        <Switch>
            <Route exact path={`${path}`}>
                <Row>
                    <Col md={12}>
                        <TeamSelect />
                    </Col>
                </Row>
            </Route>
            <Route strict path={`${path}organization/`}>
                <OrganizationRoutes />
            </Route>
            <TeamPageProtectedRoute path={`${path}:slug/`}>
                <Row className="" style={{ minHeight: '100vh' }}>
                    <Col sm={0} className={cx('sticky-nav')}>
                        <Sidebar hidden={isHidden} toggle={toggle} />
                    </Col>
                    <Col
                        sm={10}
                        style={{
                            maxHeight: '100vh',
                            overflowY: shouldScroll(currentPath) ? 'hidden' : 'auto',
                            overflowX: 'hidden',
                        }}
                        className={cx('main', {
                            'collapse-space': isHidden,
                        })}
                    >
                        <PageTitle isHidden={isHidden} />

                        <Route exact path={`${path}:slug/maps/modify/`} component={MapEditor} />
                        <Route exact path={`${path}:slug/maps/upload/`} component={MapUpload} />
                        <Route
                            exact
                            path={`${path}:slug/maps/outside-modify/`}
                            component={OutsideMapCreate}
                        />
                        <Route exact path={`${path}:slug/missions/`} component={TeamTasksV2} />
                        <Route exact path={`${path}:slug/maps/`} component={TeamMap} />
                        <Route exact path={`${path}:slug/actions/`} component={TriggerManage} />
                        <Route exact path={`${path}:slug/vehicles&devices/`} component={VehicleManage} />
                        <Route exact component={CustomPage} path={`${path}:slug/custom-page/`} />
                        <Route exact component={Support} path={`${path}:slug/support/`} />
                        <Route exact component={Settings} path={`${path}:slug/settings/`} />
                        <Route exact path={`${path}:slug/settings/new-2fa-setup`} component={TwoFactorAuthentication} />
                        <Route exact component={DashboardHome} path={`${path}:slug/`} />
                        {/* <Route exact path={`${path}:slug/analytics/`} component={Analytics} /> */}
                    </Col>
                </Row>
            </TeamPageProtectedRoute>
        </Switch>
    )
}

const mapStateToProps = (state) => {
    return {
        team: selectors.getTeam(state),
    }
}

export default compose(withRouter, connect(mapStateToProps))(DashboardRoutes)
