import dayjs from 'dayjs'
import { useState } from 'react'

export const extractCurrentLeg = (task) => {
    /* The function returns a dictioniary containing:
     * - The current leg
     * - Total number of legs
     * - A boolean indicating if the user should be expecting the robot,
     *     aka confirming it's location
     *
     * Parameter `task` must of Task instance
     */
    let finishedStatuses = ['sent', 'in_progress', 'completed']
    let totalLegs = task?.subtasks?.length

    let index = 0
    let name = ''
    let nameArray = []

    const subtaskName = task.subtasks?.map((sub) => {
        nameArray = sub.name
        return nameArray
    })

    task.subtasks?.forEach((subtask) => {
        if (finishedStatuses.includes(subtask.status)) {
            name = subtask.name
            index++
        }
    })

    return {
        currentIndex: index,
        totalLegs: totalLegs,
        requireUserInput: task.can_be_confirmed,
        subtaskName,
        name,
    }
}

export const extractSubtaskInfo = (task) => {
    let finishedStatuses = ['sent', 'in_progress', 'completed']
    let totalLegs = task?.subtasks?.length

    let index = 0
    let name = ''
    let nameArray = []

    const subtaskName = task.subtasks?.map((sub) => {
        nameArray = sub.action.definition_information?.displayable_name
        return nameArray
    })

    task.subtasks?.forEach((subtask, i) => {
        if (finishedStatuses.includes(subtask.status)) {
            name = subtask.action.definition_information?.displayable_name
            index = i
        }
    })

    return {
        currentIndex: index,
        totalLegs: totalLegs,
        requireUserInput: task.can_be_confirmed,
        subtaskName,
        name,
    }
}

export const getMapRatioByStageSize = ({ stage, map }) => {
    /* 
    Assuming the stage and map are square and based on 
    Area of stage we are calcuating the ratio.
    TODO: it should be based on both width and height of stage.
      */
    const MAX_DEFAULT_RATIO = 1
    const maxStageSize = stage.x > stage.y ? stage.y : stage.x
    const stageArea = maxStageSize * maxStageSize

    const targetArea = MAX_DEFAULT_RATIO * stageArea
    const newMap = Math.sqrt(targetArea)
    return Number((newMap / map).toFixed(1))
}

export const chunkArray = (array, chunk_size) => {
    let index = 0
    const result = []

    for (index = 0; index < array.length; index += chunk_size) {
        const chunkedArray = array.slice(index, index + chunk_size)
        result.push(chunkedArray)
    }

    return result
}

export const useToggler = (init = false) => {
    const [value, setValue] = useState(init)
    const toggler = () => {
        setValue(!value)
    }
    return [value, toggler, setValue]
}

export const removeNullFromObj = (obj) => {
    return Object.keys(obj).reduce((acc, key) => {
        if (Array.isArray(obj[key]) && obj[key].length === 0) return acc
        if (obj[key] !== null) acc[key] = obj[key]
        return acc
    }, {})
}

export const removeEmptyStringsFromObj = (obj) => {
    return Object.keys(obj).reduce((acc, key) => {
        let value = obj[key]

        if (Array.isArray(value)) {
            // Recursively clean objects inside the array
            value = value
                .map((item) =>
                    typeof item === 'object' && item !== null
                        ? removeEmptyStringsFromObj(item)
                        : item
                )
                .filter((item) => !(Array.isArray(item) && item.length === 0)) // Remove empty arrays
        }

        if (
            (Array.isArray(value) && value.length === 0) || // Exclude empty arrays
            (typeof value === 'string' && value.trim() === '') // Exclude empty strings
        ) {
            return acc
        }

        if (value !== null) {
            acc[key] = value
        }

        return acc
    }, {})
}

export const getPaginationPages = (index, pages) => {
    const PAGES_TO_SHOW = 3
    const pagesLength = pages.length
    const nextStop = PAGES_TO_SHOW + index > pagesLength ? pagesLength : PAGES_TO_SHOW + index
    const pervStop = index - PAGES_TO_SHOW > 0 ? index - PAGES_TO_SHOW : 0
    const result = []
    for (let i = index; i >= pervStop; i--) {
        result.unshift(i)
    }
    for (let i = index + 1; i < nextStop; i++) {
        result.push(i)
    }
    return result
}

export const formDispatchHandler =
    (dispatcher, action, toggle) =>
    (data, { setErrors, setSubmitting, setStatus }) => {
        setStatus('saving')
        return dispatcher(action(data)).then(({ error }) => {
            setSubmitting(false)
            if (error) {
                setStatus('error')
                if (!error.response || error.status === 0)
                    return setErrors({
                        non_field_errors: 'Can not access the server!',
                    })
                return setErrors(error.response?.data.tasks)
            }
            setStatus('saved')
            if (toggle) toggle()
        })
    }

export const useQuery = (location) => new URLSearchParams(location.search)

export const secondsToString = (sec = 0) => {
    const [hours, minutes, seconds] = new Date(sec * 1000).toISOString().substr(11, 8).split(':')
    return {
        hours,
        minutes,
        seconds,
    }
}

export const differenceFromNow = (dateString, unit = 'hour') => {
    const today = dayjs()
    const parsedDate = dayjs(dateString)
    return parsedDate.diff(today, unit)
}

export const buildWebsocketURL = (base, token, options = {}) => {
    // Use window.location.origin as a fallback only if `window` is defined
    if (!base || base === '/') {
        base = typeof window !== 'undefined' ? window.location.origin : 'http://localhost'
    }

    // Ensure base has a trailing slash for correct pathname construction
    const uri = base.endsWith('/') ? base : `${base}/`
    const url = new URL(uri)

    // Set protocol to ws/wss based on the original protocol
    url.protocol = url.protocol === 'http:' ? 'ws:' : 'wss:'

    // Determine if the notifications path should be used
    if (options.notifications) {
        url.pathname += 'notifications/ws'
        url.search = `?token=${token}`
    } else {
        url.pathname += `ws/users/${token}/`
    }

    return url.toString()
}

export const formatDateToDdMmYy = (date) => {
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = String(date.getFullYear())

    return `${day}/${month}/${year}`
}
