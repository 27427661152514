import React, { useState } from 'react'
import { Image as KonvaImage } from 'react-konva'
import useImage from 'use-image'
import { stationIconSize } from './MapLayerHelpers'
import { Html } from 'react-konva-utils'
import IndoorMapDeviceModal from './IndoorMapDeviceModal'

const IndoorMapDevices = ({ device, aoi, setDataHovered }) => {
    const [deviceDefault] = useImage('/svgs/map-layer/device.svg')
    const [deviceDefaultHover] = useImage('/svgs/map-layer/device-hover.svg')
    const [deviceOffline] = useImage('/svgs/map-layer/device-disabled.svg')
    const [closedDoorDefault] = useImage('/svgs/map-layer/closed-door.svg')
    const [closedDoorHover] = useImage('/svgs/map-layer/closed-door-hover.svg')
    const [closedDoorOffline] = useImage('/svgs/map-layer/door-offline.svg')
    const [switchOnDefault] = useImage('/svgs/map-layer/switch.svg')
    const [switchOnHover] = useImage('/svgs/map-layer/switch-hover.svg')
    const [switchOffline] = useImage('/svgs/map-layer/switch-offline.svg')
    const [elevatorDefault] = useImage('/svgs/map-layer/elevator.svg')
    const [elevatorHover] = useImage('/svgs/map-layer/elevator-hover.svg')
    const [elevatorOffline] = useImage('/svgs/map-layer/elevator-offline.svg')
    const [trafficLightRedDefault] = useImage('/svgs/map-layer/traffic-light-red.svg')
    const [trafficLightRedHover] = useImage('/svgs/map-layer/traffic-light-red-hover.svg')
    const [trafficLightOffline] = useImage('/svgs/map-layer/traffic-light-offline.svg')
    const { width, height } = aoi
    const biggerMapSize = 1000000
    const [hovered, setHovered] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const toggle = () => setModalOpen(!modalOpen)

    const showImage = () => {
        switch (device.model_type) {
            case 'door':
                if (hovered) return closedDoorHover
                if (!device.online) return closedDoorOffline
                return closedDoorDefault
            case 'switch':
                if (hovered) return switchOnHover
                if (!device.online) return switchOffline
                return switchOnDefault
            case 'elevator':
                if (hovered) return elevatorHover
                if (!device.online) return elevatorOffline
                return elevatorDefault
            case 'traffic_light':
                if (hovered) return trafficLightRedHover
                if (!device.online) return trafficLightOffline
                return trafficLightRedDefault
            default:
                if (device.online) {
                    if (hovered) return deviceDefaultHover
                    return deviceDefault
                }
                return deviceOffline
        }
    }

    if (!device) return
    if (!device.points) return

    const name = device.name
    const identifier = device.identifier
    const model_type = device.model_type
    const online = device.online
    const dataToShow = { name, identifier, model_type, online }

    return (
        <>
            <KonvaImage
                image={showImage()}
                width={
                    width * height > biggerMapSize
                        ? stationIconSize(device, width) / 2
                        : stationIconSize(device, width)
                }
                height={
                    width * height > biggerMapSize
                        ? stationIconSize(device, width) / 2
                        : stationIconSize(device, width)
                }
                offsetX={
                    width * height > biggerMapSize
                        ? stationIconSize(device, width) / 4
                        : stationIconSize(device, width) / 2
                }
                offsetY={
                    width * height > biggerMapSize
                        ? stationIconSize(device, width) / 4
                        : stationIconSize(device, width) / 2
                }
                x={device.points[0]}
                y={device.points[1]}
                onClick={toggle}
                onMouseEnter={(e) => {
                    if (!device.online) return
                    setHovered(true)
                    setDataHovered(dataToShow)
                    const container = e.target.getStage().container()
                    container.style.cursor = 'pointer'
                }}
                onMouseLeave={(e) => {
                    setHovered(false)
                    setDataHovered(null)
                    const container = e.target.getStage().container()
                    container.style.cursor = 'default'
                }}
            />
            <Html>
                <IndoorMapDeviceModal device={device} modalOpen={modalOpen} toggle={toggle} />
            </Html>
        </>
    )
}

export default IndoorMapDevices
