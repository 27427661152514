
export const getCardData = (filteredData, title) => {
    if (!filteredData || Object.values(filteredData).length === 0) return null
    const cardData = Object.values(filteredData).find((item) => item.title === title)
    return cardData ? cardData?.data : null
}
export const calculateComparison = (value, compValue) => {
    if (isNaN(value) || isNaN(compValue)) return null
    const difference = Math.round(value - compValue)
    const isIncrease = difference > 0
    const isEqual = difference === 0
    return {
        difference,
        isIncrease,
        isEqual,
    }
}
