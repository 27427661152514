import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'reactstrap'
import {
    canRedo,
    canUndo,
    deleteAreaZone,
    deleteStation,
    deleteZonesPath,
    getAreaZones,
    getMapStations,
    getZonesPaths,
} from 'features/dashboard/Map.actionTypes'
import {
    deleteActionsPresets,
    deletePresetTaskV2,
    deleteScheduleTaskV2,
    deleteTaskDefinitions,
    getActionsPresets,
    getPresetTasksV2,
    getScheduleTasksV2,
    getTaskDefinitions,
} from 'features/dashboard/Task.actionTypes'
import { deleteDevice, getDevices } from 'features/dashboard/RosSetup.actionTypes'
import { deleteTrigger, getTriggers } from 'features/dashboard/Trigger.actionTypes'
import { deleteWebhook, getWebhooks } from 'features/dashboard/Team.actionTypes'

const RemoveModal = ({
    data,
    slug,
    toggle,
    type,
    toggleBig,
    setDataOnClick,
    cancelNewZone,
    currentPage,
    areaUuid,
}) => {
    const dispatch = useDispatch()
    const submitDelete = () => {
        switch (type) {
            case 'station':
                return dispatch(deleteStation(data.uuid))
                    .then((res) => {
                        if (data.station_type === 'charging' || data.station_type === 'resting') {
                            dispatch(
                                deleteAreaZone({
                                    type: data.zone_information?.zone_type,
                                    uuid: data.zone_information?.uuid,
                                })
                            )
                        }
                        dispatch(getMapStations({ uuid: areaUuid }))
                        dispatch(getZonesPaths(areaUuid))
                        dispatch(canRedo(areaUuid))
                        dispatch(canUndo(areaUuid))
                    })
                    .finally(toggle) // Ensure toggle is called regardless of promise resolution or rejection

            case 'path':
                return dispatch(deleteZonesPath(data.uuid))
                    .then((res) => {
                        dispatch(getZonesPaths(areaUuid))
                        dispatch(getMapStations({ uuid: areaUuid }))
                        dispatch(canRedo(areaUuid))
                        dispatch(canUndo(areaUuid))
                    })
                    .finally(toggle)

            case 'presets':
                if (data.title) {
                    return dispatch(deletePresetTaskV2(data))
                        .then((res) => {
                            dispatch(getPresetTasksV2({ slug, page: currentPage }))
                            setDataOnClick({})
                        })
                        .finally(() => {
                            toggle()
                            toggleBig()
                        })
                }

                if (data.form_values) {
                    return dispatch(deleteActionsPresets(data.uuid))
                        .then((res) => {
                            dispatch(getActionsPresets({ slug }))
                            setDataOnClick({})
                        })
                        .finally(() => {
                            toggle()
                            toggleBig()
                        })
                }

                if (data.date_tz) {
                    return dispatch(deleteScheduleTaskV2(data))
                        .then((res) => {
                            dispatch(getScheduleTasksV2(slug))
                            setDataOnClick({})
                        })
                        .finally(() => {
                            toggle()
                            toggleBig()
                        })
                }

                if (data.required_object) {
                    return dispatch(deleteTaskDefinitions(data.uuid))
                        .then((res) => {
                            dispatch(getTaskDefinitions(slug))
                            setDataOnClick({})
                        })
                        .finally(() => {
                            toggle()
                            toggleBig()
                        })
                }

                if (data.ip_whitelist) {
                    return dispatch(deleteTrigger(data))
                        .then((res) => {
                            dispatch(getTriggers({ slug }))
                        })
                        .finally(() => {
                            toggle()
                            toggleBig()
                        })
                }
                break
            case 'webhook':
                return dispatch(deleteWebhook({ uuid: data.uuid })).then(() => {
                    dispatch(getWebhooks({ slug }))
                    toggle()
                    toggleBig()
                })

            case 'device':
                return dispatch(deleteDevice(data.uuid))
                    .then((res) => {
                        dispatch(getDevices({ slug }))
                        dispatch(canRedo(areaUuid))
                        dispatch(canUndo(areaUuid))
                    })
                    .finally(toggle)

            default:
                dispatch(deleteAreaZone({ type: data.zone_type, uuid: data.uuid }))
                    .then(({ error }) => {
                        dispatch(getAreaZones({ uuid: areaUuid, type: data.zone_type }))
                        dispatch(getMapStations({ uuid: areaUuid }))
                        dispatch(getZonesPaths(areaUuid))
                        dispatch(canRedo(areaUuid))
                        dispatch(canUndo(areaUuid))
                    })
                    .finally(cancelNewZone)
        }
    }

    const showText = () => {
        switch (type) {
            case 'path':
                return 'this path'
            case 'zone':
            case 'station':  return 'this station'
            case 'device':
                if (data.zone_type === 'action') return data?.title || 'this action zone'
                if (data.zone_type === 'charging') return data?.title || 'this charging zone'
                if (data.zone_type === 'resting') return data?.title || 'this resting zone'
                if (data.zone_type === 'forbidden') return data?.title || 'this forbidden zone'
                return
            case 'presets':
                if (data.date_tz) return 'this schedule'
                if (data.required_object) return 'this definition'
                if (data.ip_whitelist) return 'this trigger'
                return 'this preset'
            case 'webhook':
                return 'this webhook'
            default:
                return
        }
    }

    return (
        <>
            <div className="w-100 d-flex justify-content-between" style={{ marginBottom: '24px' }}>
                <img
                    src="/svgs/modal-icons/trash-icon.svg"
                    alt="obstacle-icon"
                    width="48px"
                    height="48px"
                />
                <img
                    src="/svgs/close-icon/x-dark-default.svg"
                    alt="obstacle-icon"
                    width="24px"
                    height="24px"
                    style={{ cursor: 'pointer' }}
                    onClick={toggle}
                />
            </div>
            <div>
                {`Are you sure you want to delete ${showText()}?
                This action cannot be undone.`}
            </div>
            <div
                style={{ marginTop: '24px' }}
                className={`d-flex w-100 justify-content-between align-items-center`}
            >
                <Button
                    className="prompt-modal-footer-decline-button"
                    onClick={() => {
                        toggle()
                    }}
                >
                    Cancel
                </Button>
                <Button
                    className="prompt-modal-footer-accept-button-danger"
                    onClick={() => submitDelete()}
                >
                    Delete
                </Button>
            </div>
        </>
    )
}

export default RemoveModal
