import * as Yup from 'yup'

export const OrgDetailBoardingSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
})

export const OrgBillingBoardingSchema = Yup.object({
    billing: Yup.object().shape({
        address1: Yup.string().min(5, 'Too short!').required('Required'),
        city: Yup.string().required('Required'),
        country: Yup.string().required('Required'),
        zip_code: Yup.string().required('Required'),
    }),
})

export const PhoneNumberSchema = Yup.object().shape({
    phone_number: Yup.string()
        .matches(/^\+?[1-9]\d{1,14}$/, 'Invalid phone number')
        .required('Phone number is required'),
})

export const OrgAddressBoardingSchema = Yup.object({
    title: Yup.string().required('Required'),
    address: Yup.object().shape({
        address1: Yup.string().min(5, 'Too short!').required('Required'),
        city: Yup.string().required('Required'),
        country: Yup.string().required('Required'),
        zip_code: Yup.number().required('Required'),
    }),
})

export const newOrgSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    address1: Yup.string().min(5, 'Too short!').required('Required'),
    city: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    zip_code: Yup.number().required('Required'),
})

export const SignupSchema = Yup.object().shape({
    password: Yup.string().min(5, 'Too short!').max(50, 'Too long!').required('Required'),
    password_confirm: Yup.string()
        .oneOf([Yup.ref('password'), null], "Password don't match")
        .required('Required'),
    username: Yup.string().required('Required'),
})

export const UserSchema = Yup.object().shape({
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
})
export const ProfileSchema = Yup.object().shape({
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    username: Yup.string().required('Required'),
})

export const LoginSchema = Yup.object().shape({
    password: Yup.string().min(5, 'Too short!').max(50, 'Too long!').required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
})

export const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
})

export const SetPasswordSchema = Yup.object().shape({
    password: Yup.string().min(8, 'Too short!').max(50, 'Too long!').required('Required'),
    password_confirm: Yup.string()
        .oneOf([Yup.ref('password'), null], "Password don't match")
        .required('Required'),
})

export const PasswordUpdateSchema = Yup.object().shape({
    old_password: Yup.string().required('Required'),
    new_password: Yup.string().min(8, 'Too short!').max(50, 'Too long!').required('Required'),
    password_confirm: Yup.string()
        .oneOf([Yup.ref('new_password'), null], "Password don't match")
        .required('Required'),
})
