import React, { useState, useEffect } from 'react'
import useImage from 'use-image'
import { Image as KonvaImage, Text } from 'react-konva'

const MapStation = ({
    station,
    customIcon,
    editPoint,
    handleMouse,
    setdraw,
    icon,
    size,
    path,
    destinationPoints,
    setConnectPoint,
    connectPoint,
    handleApiRequest,
    setHover,
    setDataHovered,
    setDestinationPoints,
    handleClickStation,
    handleDragStart,
    handleDragEnd,
    handleMouseDown,
}) => {
    const [imageUrl, setImageUrl] = useState(null)
    const [stationDefault] = useImage(imageUrl)
    const [defaultHover] = useImage('/svgs/map-layer/station-hover.svg')
    const [charging] = useImage('/svgs/map-layer/charging-station.svg')
    const [chargingHover] = useImage('/svgs/map-layer/charging-hover.svg')
    const [docking] = useImage('/svgs/map-layer/docking-station.svg')
    const [undocking] = useImage('/svgs/map-layer/undocking-station.svg')
    const [resting] = useImage('/svgs/map-layer/resting-station.svg')
    const [restingHover] = useImage('/svgs/map-layer/resting-hover.svg')
    const [traversal] = useImage('/svgs/map-layer/traversal-default.svg')
    const [traversalHover] = useImage('/svgs/map-layer/traversal-hover.svg')
    const [stationDisabled] = useImage('/svgs/map-layer/station-disabled.svg')
    const [chargingDisabled] = useImage('/svgs/map-layer/charging-disabled.svg')
    const [restingDisabled] = useImage('/svgs/map-layer/resting-disabled.svg')
    const [traversalDisabled] = useImage('/svgs/map-layer/traversal-disabled.svg')
    const [evacuationDefault] = useImage('/svgs/map-layer/evacuation-station.svg')
    const [evacuationHover] = useImage('/svgs/map-layer/evacuation-hover.svg')
    const [evacuationDisabled] = useImage('/svgs/map-layer/evacuation-disabled.svg')
    const [hov, setHov] = useState('')
    const [highlight, sethighlight] = useState(false)
    const [draggingAction, setDragginAction] = useState(false)
    const iconColor = station?.icon_color ? station?.icon_color : '#BA4FFA'
    const RenderCustomIcon = customIcon?.map((icon) => {
        let newImageURL = ''
        switch (icon.title) {
            case 'Shelf':
                newImageURL = '/svgs/map-layer/shelf.svg'
                break
            case 'Pallet loaded':
                newImageURL = '/svgs/map-layer/pallet-loaded.svg'
                break
            case 'Pallet unloaded':
                newImageURL = '/svgs/map-layer/pallet-unloaded.svg'
                break
            case 'Conveyor':
                newImageURL = '/svgs/map-layer/conveyor.svg'
                break
            case 'Palletiser':
                newImageURL = '/svgs/map-layer/palletiser.svg'
                break
            case 'Cleaning mission start point':
                newImageURL = '/svgs/map-layer/cleaning-mission-start-point.svg'
                break
            case 'Assembly line robot':
                newImageURL = '/svgs/map-layer/assembly-line-robot.svg'
                break
            case 'Assembly line person':
                newImageURL = '/svgs/map-layer/assembly-line-person.svg'
                break
            case 'Assembly line person and robot':
                newImageURL = '/svgs/map-layer/assembly-line-person-robot.svg'
                break
            case 'Default meili':
                newImageURL = '/svgs/map-layer/station-default.svg'
                break
            default:
                newImageURL = icon.icon
                break
        }

        return { ...icon, icon: newImageURL }
    })
    const customisedIcon = Object?.assign(
        {},
        ...(RenderCustomIcon || [])?.filter((icon) => icon.uuid === station?.custom_icon)
    )
    const [customStationIcon] = useImage(
        customisedIcon.system_generated ? imageUrl : customisedIcon.icon
    )

    const modifySvgColor = () => {
        const svgURL =
            customisedIcon.system_generated === true
                ? customisedIcon.icon
                : '/svgs/map-layer/station-default.svg'
        fetch(svgURL)
            .then((response) => response.text())
            .then((svg) => {
                svg = svg.replace(/stroke="[^"]+"/g, `stroke="${iconColor}"`)
                svg = svg.replace(/fill="#BA4FFA"/g, `fill="${iconColor}"`)
                const dataUrl = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg)
                setImageUrl(dataUrl)
            })
    }

    useEffect(() => {
        modifySvgColor(iconColor)
    }, [iconColor, station?.custom_icon]) // eslint-disable-line react-hooks/exhaustive-deps

    const showImage = () => {
        switch (station.station_type) {
            case 'charging':
                if (highlight && station.is_active) return chargingHover
                if (!station.is_active) {
                    return chargingDisabled
                }
                return charging
            case 'docking':
                return docking
            case 'undocking':
                return undocking
            case 'resting':
                if (highlight && station.is_active) return restingHover
                if (!station.is_active) {
                    return restingDisabled
                }
                return resting
            case 'traversal':
                if (highlight && station.is_active) return traversalHover
                if (!station.is_active) {
                    return traversalDisabled
                }
                return traversal
            case 'evacuation':
                if (highlight && station.is_active) return evacuationHover
                if (!station.is_active) {
                    return evacuationDisabled
                }
                return evacuationDefault
            default:
                if (highlight && station.is_active && !station.custom_icon) return defaultHover
                if (!station.is_active) {
                    return stationDisabled
                }
                if (station.custom_icon) {
                    return customStationIcon
                }
                return stationDefault
        }
    }

    if (!station) return
    if (!station.points) return

    const name = station.title ? station.title : ''
    const posX = `${station?.points_in_meters && station.points_in_meters[0]}m`
    const posY = `${station?.points_in_meters && station.points_in_meters[1]}m`
    const type = station?.station_type.charAt(0).toUpperCase() + station?.station_type.slice(1)
    const rotation = station?.rotation
    const dataToShow = { name, type, x: posX, y: posY, rotation }

    return (
        <>
            <KonvaImage
                image={showImage()}
                width={station?.station_dimensions_in_pixels[1]}
                height={station?.station_dimensions_in_pixels[1]}
                draggable={editPoint && station.station_type !== 'action'}
                offsetX={station?.station_dimensions_in_pixels[1] / 2}
                offsetY={station?.station_dimensions_in_pixels[1] / 2}
                x={station.points[0]}
                y={station.points[1]}
                scaleX={highlight ? 1.1 : 1}
                scaleY={highlight ? 1.1 : 1}
                rotation={station.rotation}
                onDragMove={handleMouse}
                onDragEnd={(e) => {
                    setDragginAction(false)
                    handleDragEnd(e, station)
                }}
                onDragStart={(e) => {
                    setDragginAction(true)
                    handleDragStart(e)
                }}
                onMouseEnter={(e) => {
                    // if (station.station_type === 'action') return
                    const container = e.target.getStage().container()
                    container.style.cursor = 'pointer'
                    sethighlight(true)
                    setDataHovered(dataToShow)
                    if (path) {
                        destinationPoints.length === 0 && setHov('Click to create path')
                        setHover('')
                    }
                    if (editPoint) {
                        destinationPoints.length === 0 && setHov('Drag to move/Click to rotate')
                    }
                }}
                onMouseLeave={(e) => {
                    // if (station.station_type === 'action') return
                    const container = e.target.getStage()?.container()
                    container.style.cursor = 'default'
                    sethighlight(false)
                    setDataHovered(null)
                    setHov('')
                }}
                onClick={() => {
                    // if (station.station_type === 'action') return
                    if (!station.is_active) return
                    if (path === true) {
                        if (destinationPoints.length === 0) {
                            setdraw(true)
                            setConnectPoint([...connectPoint, station.points[0], station.points[1]])
                            setDestinationPoints([...destinationPoints, station.uuid])
                        }
                        if (destinationPoints.length === 1) {
                            setDestinationPoints([...destinationPoints, station.uuid])
                            handleApiRequest(station.uuid)
                        }
                    }

                    if (editPoint) {
                        handleMouseDown(station)
                    } else if (!editPoint && !path) {
                        handleClickStation(station)
                    }
                }}
            />

            {!draggingAction && (
                <Text
                    x={station.points[0]}
                    y={station.points[1] - size || station.points[1]}
                    offsetX={station?.station_dimensions_in_pixels[1] / 2}
                    offsetY={station?.station_dimensions_in_pixels[1] / 2}
                    stroke="#670d95"
                    strokeWidth={0.1}
                    text={icon && station.station_type !== 'traversal' ? station.title : hov}
                    fontStyle="bold"
                    fontSize={size || 3}
                />
            )}
        </>
    )
}

export default MapStation
