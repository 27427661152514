import React, { useRef, useState } from 'react'
import { compose } from 'redux'
import { connect, useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { fileUpload } from 'middleware/api'
import { FormGroup, Label, Button, Input, ButtonGroup, Modal, ModalBody } from 'reactstrap'
import { customInputForm } from 'ui/common'
import { HiOutlineTrash } from 'react-icons/hi'
import { selectors } from 'features/dashboard'
import {
    canRedo,
    canUndo,
    createStation,
    getMapStations,
    getZonesPaths,
    updateStation,
} from 'features/dashboard/Map.actionTypes'
import { handleConversion } from './helpers'
import { getTeamVehicles } from 'features/dashboard/Vehicle.actionTypes'
import DropdownSelect from 'layout-components/Dropdown'
import CustomStationIcon from './CustomStationIcon'

const StationModal = ({
    status,
    toggle,
    station = {},
    categories,
    areaUuid,
    slug,
    handleNewPoint,
    setTraversalCreate,
    setWindows,
    orgSlug,
}) => {
    const dispatch = useDispatch()
    const vehicles = useSelector(selectors.getVehicles)
    const stations = useSelector(selectors.getTeamMap)
    const [stationType, setStationType] = useState('')
    const [uploadIcon, setUploadIcon] = useState(false)
    const [preview, setPreview] = useState('')
    const [image, setImage] = useState(null)
    const [tab, setTab] = useState('general')
    const [active, setActive] = useState(true)
    const iconColor = station?.icon_color ? station?.icon_color : '#BA4FFA'
    const [color, setColor] = useState(iconColor)
    const inputFile = useRef(null)
    const [fetchBtn, setFetchBtn] = useState(false)
    const [edit, setEdit] = useState('')
    const [error, setError] = useState('')
    const [category, setCategory] = useState(
        (station?.allowed_vehicle_categories &&
            categories?.find((cat) => cat.uuid === station?.allowed_vehicle_categories[0])
                ?.title) ||
            []
    )
    const [title, setTitle] = useState('')
    const custom_icon = stations.customIcon
    const [selectedFile, setSelectedFile] = useState('')
    const filteredCategories = [{ title: 'No cateogry' }, ...categories.map((cat) => cat)]
    const [robotPositioningInput, setRobotPositioningInput] = useState('')
    const defaultIcon = '/svgs/map-layer/station-default.svg'

    const updatedImages = custom_icon.map((icon) => {
        let newImageURL = ''

        switch (icon.title) {
            case 'Shelf':
                newImageURL = '/svgs/map-layer/shelf.svg'
                break
            case 'Pallet loaded':
                newImageURL = '/svgs/map-layer/pallet-loaded.svg'
                break
            case 'Pallet unloaded':
                newImageURL = '/svgs/map-layer/pallet-unloaded.svg'
                break
            case 'Conveyor':
                newImageURL = '/svgs/map-layer/conveyor.svg'
                break
            case 'Palletiser':
                newImageURL = '/svgs/map-layer/palletiser.svg'
                break
            case 'Cleaning mission start point':
                newImageURL = '/svgs/map-layer/cleaning-mission-start-point.svg'
                break
            case 'Assembly line robot':
                newImageURL = '/svgs/map-layer/assembly-line-robot.svg'
                break
            case 'Assembly line person':
                newImageURL = '/svgs/map-layer/assembly-line-person.svg'
                break
            case 'Assembly line person and robot':
                newImageURL = '/svgs/map-layer/assembly-line-person-robot.svg'
                break
            case 'Default meili':
                newImageURL = '/svgs/map-layer/station-default.svg'
                break
            default:
                newImageURL = icon.icon
                break
        }
        return { ...icon, icon: newImageURL }
    })

    const selectedIcon = Object?.assign(
        {},
        ...updatedImages.filter((icon) => icon.uuid === station?.custom_icon)
    )
    const [icon, setIcon] = useState(selectedIcon)

    const handleTitleChange = (event) => {
        setTitle(event.target.value)
    }   
    const handleImageChange = async (event) => {
        const file = event.target.files[0]
        setSelectedFile(file)
        if (file) {
            const reader = new FileReader()
            reader.onloadend = () => {
                setImage(reader.result)
            }
            reader.readAsDataURL(file)
        }
        setFetchBtn(false)
    }

    const onButtonClick = () => {
        inputFile.current.click()
        setFetchBtn(true)
    }
    const setInitialValues = () => {
        if (tab === 'general' && status === 'edit')
            return {
                ...station,
                robotPositioning: false,
               // allowed_vehicle_categories: station.allowed_vehicle_categories,
            }
    }

    const handleUpdate = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('title', title)
        if (selectedFile) {
            formData.append('icon', selectedFile)
        }
        await fileUpload
            .patch(`/api/station-icons/${edit.uuid}`, formData, {
                method: 'PATCH',
                body: formData,
                'Content-Type': 'multipart/form-data',
            })
            .then((result) => {
                setError('')
                setPreview(result.data)
                setUploadIcon(false)
            })
            .catch((err) => {
                setError(err.response.data.errors)
            })
    }

    const handleUpload = async () => {
        if (!selectedFile) {
            setError('Please select a file to upload.')
            console.log('error')
            return
        }
        const formData = new FormData()
        formData.append('icon', selectedFile)
        formData.append('title', title)

        await fileUpload
            .post(`/api/organizations/${orgSlug}/station-icons/`, formData, {
                method: 'POST',
                body: formData,
                'Content-Type': 'multipart/form-data',
            })

            .then((result) => {
                setError('')
                setPreview(result.data)
                console.log('updated')
                setUploadIcon(false)
            })
            .catch((err) => {
                setError(err.response.data.errors)
            })
    }
    const handleSubmit = (data) => {
        let robotData
        let {
            uuid,
            robotPositioning,
            vehicle,
            points_in_meters,
            icon_color,
            custom_icon,
            apply_custom_icon_choice_to,
            apply_color_choice_to,
            points,
            ...rest
        } = data
        if (robotPositioning) {
            const vehicleUuid = vehicles.teamVehicle.filter((veh) => veh.uuid === vehicle)
            robotData = {
                ...rest,
                vehicle,
                rotation: Math.round(vehicleUuid[0].details.rotation)
                    ? Math.round(vehicleUuid[0].details.rotation)
                    : 0,
                points: [vehicleUuid[0].details.xd, vehicleUuid[0].details.yd],
                ...(vehicleUuid[0].category && {
                    allowed_vehicle_categories: [vehicleUuid[0].category],
                }),
            }
        }
        if (status === 'edit') {
            dispatch(
                updateStation({
                    uuid,
                    data: handleConversion(
                        robotPositioning
                            ? { ...robotData, robotPositioning }
                            : { ...data, points_in_meters }
                    ),
                })
            ).then((res) => {
                if (res.error) return
                dispatch(getMapStations({ uuid: areaUuid }))
                dispatch(getZonesPaths(areaUuid))
                dispatch(canRedo(areaUuid))
                dispatch(canUndo(areaUuid))
            })
        }
        if (status === 'add') {
            if (points_in_meters || robotPositioning) {
                dispatch(getTeamVehicles({ slug, text: 'status=true' })).then((res) => {
                    dispatch(
                        createStation({
                            uuid: areaUuid,
                            data: handleConversion(
                                robotPositioning
                                    ? { ...data, robotPositioning }
                                    : { ...rest, points_in_meters }
                            ),
                        })
                    ).then((res) => {
                        if (res.error) return
                        dispatch(getMapStations({ uuid: areaUuid }))
                        dispatch(canRedo(areaUuid))
                        dispatch(canUndo(areaUuid))
                        toggle()
                    })
                })
            } else {
                if (data.station_type === 'traversal') setTraversalCreate(true)
                const station = { ...rest, points }
                handleNewPoint(station)
            }
        }
        setActive(true)
        toggle()
    }
    return (
        <>
            <div className="w-100 d-flex justify-content-between" style={{ marginBottom: '24px' }}>
                <img
                    src="/svgs/modal-icons/modal-station-icon.svg"
                    alt="obstacle-icon"
                    width="48px"
                    height="48px"
                />
                <img
                    src="/svgs/close-icon/x-dark-default.svg"
                    alt="obstacle-icon"
                    width="24px"
                    height="24px"
                    style={{ cursor: 'pointer' }}
                    onClick={toggle}
                />
            </div>
            {/* <h5>
                <strong className="modals-header">
                    {status === 'edit' ? <>Edit station</> : <>New station</>}
                </strong>
            </h5> */}
            <div>
                <span className="org-modal-header">
                    {status === 'add-vehicle' ? 'New station' : 'Station settings'}
                </span>

                <div className="modals-container-buttons">
                    <ButtonGroup className="org-button-group">
                        <Button
                            className={`${
                                tab === 'general'
                                    ? ' org-button-selected weight-500 size-14 grey-800'
                                    : ''
                            } org-button`}
                            style={{ borderRadius: '6px' }}
                            onClick={() => {
                                setTab('general')
                            }}
                        >
                            General
                        </Button>
                        {status === 'edit' && (
                            <Button
                                className={`${
                                    tab === 'appearance'
                                        ? ' org-button-selected weight-500 size-14 grey-800'
                                        : ''
                                } org-button`}
                                style={{ borderRadius: '6px' }}
                                onClick={() => {
                                    setTab('appearance')
                                }}
                            >
                                Appearance
                            </Button>
                        )}
                    </ButtonGroup>
                </div>
            </div>
            <Formik initialValues={setInitialValues()} onSubmit={handleSubmit}>
                {({ values, setFieldValue }) => (
                    <Form>
                        {tab === 'general' && (
                            <>
                                <FormGroup>
                                    <Label className="modals-labels">Name</Label>
                                    <Field
                                        type="text"
                                        name="title"
                                        className="modals-inputs"
                                        placeholder="Station name"
                                        component={customInputForm}
                                        value={values.title || ''}
                                    ></Field>
                                </FormGroup>

                                {status === 'add' ? (
                                    <>
                                        <FormGroup>
                                            <Label className="modals-labels">Type *</Label>
                                            <DropdownSelect
                                                selected={stationType}
                                                setSelected={setStationType}
                                                options={[
                                                    { uuid: 'default', name: 'Default' },
                                                    { uuid: 'traversal', name: 'Traversal' },
                                                ]}
                                                setFieldValue={setFieldValue}
                                                fieldValue="station_type"
                                            />
                                            {/* <Field
                                        type="select"
                                        component={customInputForm}
                                        name="station_type"
                                        className="modals-inputs"
                                        required
                                    >
                                        <option value=""></option>
                                        <option value="default">Default</option>
                                        <option value="traversal">Traversal</option>
                                    </Field> */}
                                        </FormGroup>
                                    </>
                                ) : null}
                                {values.station_type !== 'traversal' ? (
                                    <>
                                        <FormGroup check className="ml-2 mb-0">
                                            <Input
                                                type="checkbox"
                                                id="robotPositioning"
                                                checked={values.robotPositioning}
                                                onChange={() => {
                                                    setFieldValue(
                                                        'robotPositioning',
                                                        !values.robotPositioning
                                                    )
                                                }}
                                            />
                                            <Label className="modals-labels mt-1">
                                                {' '}
                                                Use robot positioning
                                            </Label>
                                        </FormGroup>

                                        {values.robotPositioning ? (
                                            <FormGroup>
                                                <Label for="rotation">Connected robots</Label>
                                                <DropdownSelect
                                                    selected={robotPositioningInput}
                                                    setSelected={setRobotPositioningInput}
                                                    options={vehicles.teamVehicle.filter(
                                                        (vehicle) => vehicle.details
                                                    )}
                                                    setFieldValue={setFieldValue}
                                                    fieldValue="vehicle"
                                                />
                                                {/* <Field
                                            component={customInputForm}
                                            type="select"
                                            name="vehicle"
                                            className="modals-inputs"
                                        >
                                            <option value=""></option>
                                            {vehicles.teamVehicle.map(
                                                ({ details, ...vehicle }) =>
                                                    details && (
                                                        <option
                                                            key={vehicle.uuid}
                                                            value={vehicle.uuid}
                                                        >
                                                            {vehicle.verbose_name}
                                                        </option>
                                                    )
                                            )}
                                        </Field> */}
                                            </FormGroup>
                                        ) : (
                                            <>
                                                <FormGroup>
                                                    <Label className="modals-labels">
                                                        Vehicle category
                                                    </Label>
                                                    <DropdownSelect
                                                        selected={category}
                                                        setSelected={setCategory}
                                                        options={filteredCategories}
                                                        setFieldValue={setFieldValue}
                                                        fieldValue="allowed_vehicle_categories[0]"
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label className="modals-labels">
                                                        Position
                                                    </Label>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <Field
                                                            className="mr-1 modals-inputs"
                                                            type="number"
                                                            placeholder="Metric X"
                                                            step={0.00001}
                                                            name="points_in_meters[0]"
                                                            component={customInputForm}
                                                        />
                                                        <Field
                                                            className="ml-1 modals-inputs"
                                                            type="number"
                                                            step={0.00001}
                                                            placeholder="Metric Y"
                                                            name="points_in_meters[1]"
                                                            component={customInputForm}
                                                        />
                                                    </div>
                                                    {!station?.uuid && (
                                                        <span className="small-text">
                                                            If position is not entered you can drag
                                                            and drop on the map
                                                        </span>
                                                    )}
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label className="modals-labels">
                                                        Rotation
                                                    </Label>
                                                    <Field
                                                        className="modals-inputs"
                                                        component={customInputForm}
                                                        step={0.1}
                                                        type="number"
                                                        name="rotation"
                                                        placeholder="0°"
                                                        value={values.rotation}
                                                    />
                                                </FormGroup>
                                            </>
                                        )}
                                    </>
                                ) : null}
                            </>
                        )}
                        {tab === 'appearance' && (
                            <>
                                {(icon?.system_generated || icon?.title === undefined || null) && (
                                    <div>
                                        <FormGroup>
                                            <Label className="grey-900 weight-500 size-14">
                                                Station colour
                                            </Label>
                                            <label className="color-selector modals-inputs">
                                                <span
                                                    className="circle"
                                                    style={{ background: color }}
                                                />
                                                <span className="grey-400">{color}</span>
                                                <Field
                                                    style={{
                                                        marginLeft: '100px',
                                                    }}
                                                    type="color"
                                                    id="icon_color"
                                                    name="icon_color"
                                                    value={values.icon_color || ''}
                                                    onChange={(e) => {
                                                        const color = e.target.value
                                                        setColor(color)
                                                        setFieldValue('icon_color', color)
                                                    }}
                                                    className="hidden"
                                                />
                                            </label>
                                        </FormGroup>

                                        <div className="d-flex">
                                            <FormGroup
                                                switch
                                                className="d-flex flex-column mb-2 ml-3 mb-3"
                                            >
                                                <Input
                                                    style={{ height: '20px', width: '36px' }}
                                                    role="switch"
                                                    type="switch"
                                                    id="apply_color_choice_to"
                                                    className="custom-switch-form modals-labels"
                                                    checked={values.apply_color_choice_to || ''}
                                                    name="apply_color_choice_to"
                                                    onChange={(e) => {
                                                        setFieldValue(e.target.name, 'all')
                                                    }}
                                                />
                                                <Label
                                                    check
                                                    className="mt-1"
                                                    style={{ marginLeft: '8px' }}
                                                >
                                                    Apply to all stations
                                                </Label>
                                            </FormGroup>
                                        </div>
                                    </div>
                                )}

                                {!uploadIcon && (
                                    <div className="mt-3 mb-3">
                                        <FormGroup>
                                            <Label className="grey-900 weight-500 mb-3 size-14">
                                                Station icon
                                            </Label>

                                            <CustomStationIcon
                                                selected={icon}
                                                preview={preview}
                                                setPreview={setPreview}
                                                setSelected={setIcon}
                                                options={['', ...updatedImages]}
                                                setFieldValue={setFieldValue}
                                                fieldValue="custom_icon"
                                                iconColor={color}
                                                defaultIcon={defaultIcon}
                                            />
                                        </FormGroup>

                                        {preview && (
                                            <div
                                                className="w-full d-flex ml-1 justify-content-between"
                                                style={{ marginTop: '35px' }}
                                            >
                                                <div className="d-flex justify-content-around">
                                                    <img
                                                        src={preview.icon}
                                                        alt="Selected"
                                                        style={{
                                                            maxWidth: '25px',
                                                            height: '25px',
                                                            marginLeft: '8px',
                                                        }}
                                                    />

                                                    <p className="grey-900 weight-500 size-14 ml-2">
                                                        {preview.title}
                                                    </p>
                                                </div>
                                                <div className="d-flex">
                                                    <img
                                                        src="/svgs/map-layer/upload-icon.svg"
                                                        alt="upload-icon"
                                                        width="25rem"
                                                        height="25rem"
                                                        style={{
                                                            marginRight: '8px',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            setTitle(preview.title)
                                                            setUploadIcon(true)
                                                            setEdit(preview)
                                                            setFetchBtn(false)
                                                        }}
                                                    />
                                                    <HiOutlineTrash
                                                        size={20}
                                                        className="mr-1"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => setPreview(false)}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {preview && (
                                    <div className="d-flex">
                                        <FormGroup switch className="d-flex flex-column mb-2 ml-3">
                                            <Input
                                                style={{ height: '20px', width: '36px' }}
                                                role="switch"
                                                type="switch"
                                                id="apply_custom_icon_choice_to"
                                                className="custom-switch-form modals-labels"
                                                checked={values.apply_custom_icon_choice_to || ''}
                                                name="apply_custom_icon_choice_to"
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        e.target.name,
                                                        e.target.checked ? 'all' : null
                                                    )
                                                }}
                                            />
                                            <Label
                                                check
                                                className="mt-1"
                                                style={{ marginLeft: '8px' }}
                                            >
                                                Apply to all stations
                                            </Label>
                                        </FormGroup>
                                    </div>
                                )}

                                {!uploadIcon && !preview && (
                                    <div className="w-full d-flex mb-3 mt-4 justify-content-center">
                                        <Button
                                            className="modals-save-btn"
                                            style={{
                                                background: '#F9F5FF',
                                                border: 'none',
                                                width: '100%',
                                            }}
                                            onClick={() => {
                                                setUploadIcon(true)
                                                setFetchBtn(true)
                                            }}
                                        >
                                            <img
                                                src="/svgs/task-modal/plus.svg"
                                                alt="obstacle-icon"
                                                width="20rem"
                                                height="20rem"
                                                style={{ marginRight: '8px' }}
                                            />
                                            Upload your own
                                        </Button>
                                    </div>
                                )}
                            </>
                        )}

                        <div
                            style={{ marginTop: '24px' }}
                            className={`d-flex w-100 ${
                                status === 'edit'
                                    ? 'justify-content-between'
                                    : 'justify-content-end'
                            } align-items-center`}
                        >
                            {status === 'edit' && tab === 'general' ? (
                                <Button
                                    color="none"
                                    onClick={() => {
                                        setWindows({ removeStation: true })
                                    }}
                                    className="modals-delete-btn"
                                >
                                    <HiOutlineTrash
                                        size={20}
                                        className="mr-1"
                                        style={{ cursor: 'pointer' }}
                                    />
                                    Delete
                                </Button>
                            ) : (
                                <Button
                                    className="reset-button modals-delete-btn"
                                    color="none"
                                    onClick={() => {
                                        setColor('#BA4FFA')
                                        setActive(!active)
                                        setFieldValue('icon_color', '#BA4FFA')
                                        setFieldValue('custom_icon', null)
                                        setIcon(null)
                                        setPreview(false)
                                    }}
                                >
                                    <img
                                        src="/svgs/map-layer/reset-btn.svg"
                                        alt="reset-icon"
                                        width="21px"
                                        height="21px"
                                    />

                                    <span style={{ marginLeft: '1px' }}> Reset to default</span>
                                </Button>
                            )}

                            <div className="d-flex">
                                <Button
                                    color="none"
                                    onClick={toggle}
                                    className="cancel-btn-modal modals-new-btns mr-2"
                                >
                                    Cancel
                                </Button>

                                {status === 'edit' ? (
                                    <Button
                                        type="submit"
                                        className="save-btn-modal modals-new-btns"
                                        disabled={
                                            JSON.stringify(station) === JSON.stringify(values)
                                        }
                                    >
                                        Save
                                    </Button>
                                ) : (
                                    <Button
                                        type="submit"
                                        className="save-btn-modal modals-new-btns"
                                        disabled={
                                            values.station_type === 'traversal'
                                                ? false
                                                : !values.station_type
                                        }
                                    >
                                        Create station
                                    </Button>
                                )}
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>

            <Modal isOpen={uploadIcon} toggle={setUploadIcon} centered>
                <ModalBody style={{ padding: '24px' }}>
                    <div>
                        <div
                            className="w-100 d-flex justify-content-between"
                            style={{ marginBottom: '24px' }}
                        >
                            <img
                                src="/svgs/modal-icons/modal-station-icon.svg"
                                alt="obstacle-icon"
                                width="48px"
                                height="48px"
                            />
                            <img
                                src="/svgs/close-icon/x-dark-default.svg"
                                alt="obstacle-icon"
                                width="24px"
                                height="24px"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setUploadIcon(false)
                                }}
                            />
                        </div>

                        <span className="org-modal-header mb-3">Custom station icon</span>

                        {image && !fetchBtn && (
                            <>
                                <div className="w-full d-flex mt-5 justify-content-between">
                                    <div className="d-flex justify-content-around">
                                        <img
                                            src={image}
                                            alt="Selected"
                                            style={{
                                                maxWidth: '20px',
                                                height: '20px',
                                                marginLeft: '8px',
                                            }}
                                        />

                                        <p className="grey-900 weight-500 size-14 ml-3">{title}</p>
                                    </div>

                                    <div className="d-flex">
                                        <img
                                            src="/svgs/map-layer/upload-icon.svg"
                                            alt="upload-icon"
                                            width="20rem"
                                            height="20rem"
                                            style={{ marginRight: '8px' }}
                                            onClick={() => {
                                                setTitle(preview.title)
                                                setUploadIcon(true)
                                                setEdit(preview)
                                                setFetchBtn(true)
                                            }}
                                        />
                                    </div>
                                </div>
                                {error.icon && <div className="error-message">{error.icon}</div>}
                            </>
                        )}

                        {edit && !image && !fetchBtn && (
                            <>
                                <div className="w-full d-flex mt-5 justify-content-between">
                                    <div className="d-flex justify-content-around">
                                        <img
                                            src={edit.icon}
                                            alt="Selected"
                                            style={{
                                                maxWidth: '20px',
                                                height: '20px',
                                                marginLeft: '8px',
                                                cursor: 'pointer',
                                            }}
                                        />

                                        <p className="grey-900 weight-500 size-14 ml-3">
                                            {edit.title}
                                        </p>
                                    </div>

                                    <div className="d-flex">
                                        <img
                                            src="/svgs/map-layer/upload-icon.svg"
                                            alt="upload-icon"
                                            width="20rem"
                                            height="20rem"
                                            style={{ marginRight: '8px', cursor: 'pointer' }}
                                            onClick={() => {
                                                setFetchBtn(true)
                                            }}
                                        />
                                    </div>
                                </div>
                                {error.icon && <div className="error-message">{error.icon}</div>}
                            </>
                        )}

                        <div className="w-full mt-3">
                            <Label className="modals-labels grey-900 weight-500 size-14 mb-1">
                                Icon name *
                            </Label>
                            <input
                                Required
                                type="text"
                                name="customTitle"
                                className="modals-inputs w-100 "
                                placeholder={title}
                                component={customInputForm}
                                onChange={handleTitleChange}
                                value={title}
                            ></input>
                            {error.title && (
                                <div className="error-message">{error.title.title}</div>
                            )}
                        </div>

                        <div className="w-full d-flex justify-content-between mt-4 mb-3">
                            <input
                                className="d-none"
                                ref={inputFile}
                                onChange={handleImageChange}
                                type="file"
                                multiple="multiple"
                                accept="image/*"
                            />
                            {fetchBtn && (
                                <Button
                                    id="browse-btn"
                                    onClick={onButtonClick}
                                    className="modals-save-btn w-100"
                                    style={{
                                        background: '#F9F5FF',
                                        border: 'none',
                                        width: '100%',
                                    }}
                                >
                                    <img
                                        src="/svgs/task-modal/plus.svg"
                                        alt="obstacle-icon"
                                        width="20rem"
                                        height="20rem"
                                        style={{ marginRight: '8px' }}
                                    />{' '}
                                    Choose file
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                        <Button
                            color="none"
                            onClick={() => {
                                setUploadIcon(false)
                            }}
                            className="cancel-btn-modal modals-new-btns mr-2"
                        >
                            Back
                        </Button>
                        {edit ? (
                            <Button
                                className="save-btn-modal modals-new-btns"
                                onClick={(e) => {
                                    handleUpdate(e)
                                }}
                                disabled={!title}
                            >
                                Update
                            </Button>
                        ) : (
                            <Button
                                className="save-btn-modal modals-new-btns"
                                onClick={() => {
                                    handleUpload()
                                }}
                                disabled={!title}
                            >
                                Upload
                            </Button>
                        )}
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

function mapStateToProps(state) {
    return {
        categories: selectors.getVehiclesCategoires(state),
    }
}

export default compose(withRouter, connect(mapStateToProps))(StationModal)
