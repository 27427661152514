import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CardBody, Label, FormGroup, Button } from 'reactstrap'
import { Field, Form, Formik } from 'formik'
import saveSvg from './../../assets/images/illustrations/save-icon.svg'
import deleteSvg from './../../assets/images/illustrations/delete.svg'
import deleteitSvg from './../../assets/images/illustrations/deleteit.svg'
import settingSvg from './../../assets/images/illustrations/setting.svg'
import { selectors } from 'features/dashboard'
import { Modal, ModalBody, Input } from 'reactstrap'
import { customInputForm } from 'ui/common'
import {
    createDashboard,
    deleteDashboardView,
    getDashboardView,
    getFilterDashboard,
    updateDashboard,
} from 'features/dashboard/TeamAnalytics.actionTypes'
import { getDashViews } from 'features/dashboard/selectors'

const NewDashViews = ({
    openModal,
    modalType,
    slug,
    uuid,
    toggle,
    view,
    ToggleSidebar,
    setView,
    setPreset,
    setId,
    from_date,
     to_date,
}) => {
    const dispatch = useDispatch()
    const customValue = useSelector(selectors.getCustom)
    const dashboardView = useSelector(getDashViews)
    const [openmodal, setModal] = useState(false)
    const toggleM = () => {
        setModal(!openmodal)
    }

    const primaryColor = {
        backgroundColor: customValue.primary_color,
        color: 'white',
    }
    const defaultColor = {
        backgroundColor: '#670D95',
        color: 'white',
    }

    const handleSubmit = (data, action) => {
        dispatch(createDashboard({ slug, data })).then(({ error, payload }) => {
            action.setSubmitting(false)
            const uuid = payload.data.uuid
            setId(uuid)
            setPreset(payload.data.is_preset)
            setView(payload.data)
            toggle()
            dispatch(getDashboardView({ slug }))
        })
    }
    const handleUpdate = (data, action) => {
        const { uuid } = data
        dispatch(updateDashboard({ slug, uuid, data })).then(({ error }) => {
            action.setSubmitting(false)
            toggle()
            dispatch(getDashboardView({ slug }))
        })
    }

    switch ((openModal, modalType)) {
        case 'save':
            return (
                <div data-testid="saveModal">
                    <img alt="save" className="mb-3" src={saveSvg}></img>
                    <h5 className="mb-2">Save layout</h5>
                    <span className="pb-5">Would you like to save your changes to view</span>
                    <div className="mt-5 d-flex flex-row justify-content-between">
                        <Button
                            className="cancel-border prompt-modal-footer-decline-button grey-600 weight-500"
                            onClick={toggle}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                dispatch(getFilterDashboard({ slug, uuid, from_date, to_date }))
                                toggle()
                            }}
                            // disabled={user.status === 'loading'}
                            type="submit"
                            data-testid="submit"
                            style={customValue.primary_color ? primaryColor : defaultColor}
                            size="sm"
                            className="prompt-modal-footer-accept-button"
                            color="meili"
                        >
                            Save changes
                        </Button>
                    </div>
                </div>
            )

        case 'createView':
            return (
                <CardBody data-testid="createViewModal">
                    <img alt="save" src={saveSvg}></img>
                    <h5 className="my-4">Save new view</h5>
                    <Formik
                        initialValues={{ title: '', is_default: false }}
                        onSubmit={handleSubmit}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>
                                <FormGroup>
                                    <Label for="name">Name</Label>
                                    <Field
                                        component={customInputForm}
                                        bsSize="md"
                                        type="text"
                                        name="title"
                                        placeholder="Save view"
                                    />
                                </FormGroup>
                                <div
                                    className="d-flex justify-content-between"
                                    style={{ marginTop: '60px' }}
                                >
                                    <FormGroup check>
                                        <Input
                                            name="check"
                                            type="checkbox"
                                            id="is_default"
                                            className="custom-check"
                                            style={{ width: '16px', height: '16px' }}
                                            //className="view-setting"
                                            label="Set as default"
                                            checked={values?.is_default === false ? false : true}
                                            onChange={() => {
                                                setFieldValue('is_default', !values.is_default)
                                            }}
                                        />
                                        <Label check for="is_default">
                                            Set as default
                                        </Label>
                                    </FormGroup>

                                    <div className="d-flex">
                                        <Button
                                            className="cancel-btn-modal modals-new-btns mr-2"
                                            onClick={toggle}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            // disabled={user.status === 'loading'}
                                            type="submit"
                                            className="save-btn-modal modals-new-btns"
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </CardBody>
            )
        case 'setting':
            return (
                <>
                    <CardBody data-testid="settingModal">
                        <div className="icon-border">
                            <img alt="setting" src={settingSvg}></img>
                        </div>

                        <h5 className="modal-title my-3">View settings</h5>
                        <Formik initialValues={{ ...view }} onSubmit={handleUpdate}>
                            {({ values, setFieldValue }) => (
                                <Form>
                                    <FormGroup>
                                        <Label for="name">Name</Label>
                                        <Field
                                            component={customInputForm}
                                            bsSize="md"
                                            type="text"
                                            name="title"
                                            values={view?.title}
                                            placeholder="Saved view"
                                        />
                                    </FormGroup>
                                    <FormGroup check>
                                        <Input
                                            type="checkbox"
                                            id="is_default"
                                            style={{ width: '16px', height: '16px' }}
                                            className="view-setting"
                                            label="Save as default view"
                                            checked={values?.is_default === false ? false : true}
                                            onChange={() => {
                                                setFieldValue('is_default', !values.is_default)
                                            }}
                                        />
                                        <Label check for="is_default">
                                            Save as default view
                                        </Label>
                                    </FormGroup>
                                    <div className="d-flex justify-content-between mt-5">
                                        <span
                                            color="none"
                                            size="sm"
                                            onClick={() => {
                                                toggleM()
                                            }}
                                            style={{ cursor: 'pointer' }}
                                            className="view-setting grey-700 weight-500 font-16"
                                        >
                                            <img alt="deleteit" src={deleteitSvg}></img>
                                            <span>Delete</span>
                                        </span>
                                        <div className="d-flex">
                                            <Button
                                                onClick={toggle}
                                                className="modals-cancel-btn"
                                                color="none"
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                type="submit"
                                                color="meili"
                                                className="modals-save-btn font-14"
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </CardBody>
                    <Modal isOpen={openmodal} toggle={toggleM} className="model-position">
                        <ModalBody>
                            <div>
                                <img alt="delete" className="mb-3" src={deleteSvg}></img>
                                <h5>Delete view</h5>
                                <span className="pb-5">
                                    Are you sure you want to delete this view ? This action can not
                                    be undone
                                </span>
                                <div
                                    style={{ marginTop: '24px' }}
                                    className={`d-flex w-100 justify-content-between align-items-center`}
                                >
                                    <Button
                                        className="cancel-border prompt-modal-footer-decline-button grey-600 weight-500"
                                        onClick={() => {
                                            toggle()
                                        }}
                                        color="none"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="prompt-modal-footer-accept-button-danger"
                                        onClick={() => {
                                            dispatch(deleteDashboardView({ slug, uuid })).then(
                                                ({ error }) => {
                                                    if (!error) {
                                                        dispatch(getDashboardView({ slug }))
                                                        dashboardView.forEach((view) => {
                                                            if (view.is_default) {
                                                                setView(view)
                                                                setPreset(view.is_preset)
                                                                setId(view.uuid)
                                                            }
                                                        })
                                                    }
                                                }
                                            )
                                            toggle()
                                            ToggleSidebar()
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </>
            )

        default:
            return null
    }
}

export default NewDashViews
