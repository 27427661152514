import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { TITLE } from 'config'
import { selectors } from 'features/dashboard'
import { getAuditLogsLight, getOrgTeams } from 'features/dashboard/Organization.actionTypes'
import { validateDateHour } from './OrgTeamHelpers'
import dayjs from 'dayjs'
import { Button, Popover } from 'reactstrap'
import { AgGridReact } from 'ag-grid-react'
import LoadingSpinner from 'components/utils/LoadingSpinner'
import { BiChevronDown } from 'react-icons/bi'
import moment from 'moment'
import { createStaticRanges, DateRangePicker } from 'react-date-range'
import { subDays, startOfWeek, startOfMonth, endOfMonth } from 'date-fns'
import Filter from 'layout-components/Filter'
import { HiOutlineX } from 'react-icons/hi'

const customRanges = createStaticRanges([
    {
        label: 'Yesterday',
        range: () => ({
            startDate: subDays(new Date(), 1),
            endDate: subDays(new Date(), 1),
            key: 'selection',
            value: 'last_day',
        }),
    },
    {
        label: 'Last week',
        range: () => ({
            startDate: startOfWeek(subDays(new Date(), 7)),
            endDate: subDays(new Date(), 1),
            key: 'selection',
            value: 'last_week',
        }),
    },
    {
        label: 'Last month',
        range: () => ({
            startDate: startOfMonth(subDays(new Date(), 30)),
            endDate: endOfMonth(subDays(new Date(), 30)),
            key: 'selection',
            value: 'last_month',
        }),
    },
])

const AuditLogs = () => {
    const gridRef = useRef()
    const dispatch = useDispatch()
    const organizationSetup = useSelector(selectors.getOrg)
    const teams = useSelector(selectors.getOrganizationTeams)
    const [currentOrg] = useState(JSON.parse(localStorage.getItem('org')))

    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [lastClickedPage, setLastClickedPage] = useState(0)
    const [searchText, setSearchText] = useState('')
    const [api, setApi] = useState(null)
    const [datePicker, setDatePicker] = useState(false)
    const [filterOpen, setFilterOpen] = useState(false)

    const dateToggle = () => setDatePicker((prevState) => !prevState)
    const filterToggle = () => setFilterOpen((prevState) => !prevState)

    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: startOfWeek(subDays(new Date(), 7)),
        endDate: subDays(new Date(), 1),
        key: 'selection',
        value: 'last_week',
    })

    const [initialFilterValues, setInitialFilterValues] = useState({
        team: [],
        event_type: [],
    })

    var start = moment(selectedDateRange?.startDate).format('DD MMM YYYY')
    var end = moment(selectedDateRange?.endDate).format('DD MMM YYYY')

    useEffect(() => {
        document.title = `${TITLE} - Audit Logs`
    }, [])

    useEffect(() => {
        dispatch(getOrgTeams(currentOrg.slug))
        dispatch(getAuditLogsLight({ slug: currentOrg.slug, date: selectedDateRange }))
    }, [dispatch, currentOrg.slug]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (currentPageNumber > 1) {
            dispatch(
                getAuditLogsLight({
                    slug: currentOrg.slug,
                    page: currentPageNumber,
                    filters: initialFilterValues,
                    date: selectedDateRange,
                })
            )
        }
    }, [currentPageNumber]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSelect = (ranges) => {
        const { startDate, endDate } = ranges.selection

        const isStaticRange = customRanges.some((range) => {
            const { startDate: staticStart, endDate: staticEnd } = range.range()
            return (
                staticStart.getTime() === startDate.getTime() &&
                staticEnd.getTime() === endDate.getTime()
            )
        })

        if (!isStaticRange) {
            setSelectedDateRange({ startDate, endDate, key: 'selection' })
        } else {
            setSelectedDateRange(ranges.selection)
        }
    }

    const updateDate = () => {
        dispatch(
            getAuditLogsLight({
                slug: currentOrg.slug,
                filters: initialFilterValues,
                date: selectedDateRange,
            })
        ).then(() => {
            setCurrentPageNumber(1)
            setLastClickedPage(0)
        })
        dateToggle()
    }

    const onGridReady = (params) => {
        gridRef.current = params.api // Store grid API in gridRef
        params.api.sizeColumnsToFit() // Resize columns once the grid is ready
        setApi(params.api)
        currentPageNumber > 1 && params.api.paginationGoToPage(lastClickedPage)
    }

    const getCurrentPage = () => {
        if (api && !api.isDestroyed()) {
            const currentPage = api.paginationGetCurrentPage() + 1 // Add 1 to convert to 1-based index
            setLastClickedPage(currentPage)
        }
    }

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase()
        setSearchText(value)
        api && api.onFilterChanged(value)
    }

    const dataLogs = useMemo(() => {
        return organizationSetup?.audit_logs?.results?.map((log) => {
            return {
                uuid: log.uuid,
                created_at: <div>{validateDateHour(dayjs(log.created_at))}</div>,
                team: log.team || '-',
                name: log.user_name || '-',
                email: log.user_email,
                object_type: log.object_type || '-',
                event_type: log.event_type || '-',
                object_name: log.object_name || '-',
                message: log.message || '-',
            }
        })
    }, [organizationSetup?.audit_logs?.results])

    const defaultColDef = {
        cellClass: 'cell-class', // Apply custom class to all cells
        headerClass: 'custom-header', // Apply custom class to all headers
    }

    const columns = [
        {
            field: 'created_at',
            headerName: 'Created At',
            valueFormatter: (params) => {
                return params.value
            },
            cellRenderer: (params) => {
                return <div>{params.data?.created_at.props.children}</div>
            },
        },
        {
            field: 'name',
            headerName: 'User',
            width: 300,
            minWidth: 200,
            flex: 1,
            sortable: true,
            unSortIcon: true,
            valueFormatter: (params) => {
                return params.value
            },
            cellRenderer: (params) => {
                return (
                    <div className="d-flex flex-column justify-centent-center">
                        <span>{params.data.name}</span>
                        <span style={{ fontWeight: '200' }}>{params.data.email}</span>
                    </div>
                )
            },
        },
        {
            field: 'team',
            headerName: 'Team',
            sortable: true,
            unSortIcon: true,
            valueFormatter: (params) => {
                return params.value
            },
            cellRenderer: (row) => {
                return <div className="team-div-cell">{row.data.team}</div>
            },
        },
        {
            field: 'event_type',
            headerName: 'Event Type',
            sortable: true,
            unSortIcon: true,
        },
        {
            field: 'object_type',
            headerName: 'Object type',
            sortable: true,
            unSortIcon: true,
            valueFormatter: (params) => {
                return params.value
            },
            cellRenderer: (row) => {
                return (
                    <div
                        className="team-div-cell text-meili"
                        style={{ backgroundColor: '#F9EFFE' }}
                    >
                        {row.data.object_type}
                    </div>
                )
            },
        },
        {
            field: 'object_name',
            headerName: 'Object Name',
            sortable: true,
            unSortIcon: true,
            width: 300,
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'message',
            headerName: 'Message',
            sortable: true,
            unSortIcon: true,
            width: 400,
            minWidth: 300,
            flex: 2,
        },
    ]

    return (
        <div className="big-org-container">
            <div className="heading-container-org" style={{ marginTop: '32px' }}>
                <div>
                    <div className="mb-2 d-flex align-items-center">
                        <span className="header-text-org">Audit logs</span>
                        {organizationSetup.status === 'loaded' && (
                            <span
                                className="text-meili rounded px-2"
                                style={{ backgroundColor: '#F9EFFE', marginLeft: '24px' }}
                            >
                                {organizationSetup?.audit_logs?.count} logs
                            </span>
                        )}
                    </div>
                    <span className="small-text-org">
                        A history of user-related changes and events within your organization.
                    </span>
                </div>
            </div>
            <div className="search-header">
                <input
                    type="text"
                    className="search-box"
                    placeholder="Search"
                    value={searchText}
                    onChange={handleSearch}
                ></input>
                <div className="d-flex align-items-center">
                    <Button
                        outline
                        style={{ borderRadius: 10 }}
                        id="datepicker"
                        className="d-flex align-items-center gap-2 bg-white justify-content-center text-black py-2 px-3"
                    >
                        <span>{start}</span> - <span>{end}</span>
                        <BiChevronDown />
                    </Button>
                    <Popover
                        popperClassName="popover-full-width rounded border-0 card-border"
                        trigger="legacy"
                        target="datepicker"
                        toggle={dateToggle}
                        placement="bottom-end"
                        isOpen={datePicker}
                    >
                        <DateRangePicker
                            ranges={[selectedDateRange]}
                            onChange={handleSelect}
                            months={2}
                            direction="vertical"
                            minDate={new Date(2020, 0, 1)}
                            maxDate={new Date(2025, 11, 31)}
                            fixedHeight={false}
                            staticRanges={customRanges}
                        />
                        <div
                            className="d-flex justify-content-end mb-2 mr-3"
                            style={{
                                width: '100%',
                                padding: '14px',
                                borderTop: '2px solid #f1f1f3',
                            }}
                        >
                            <Button
                                className="cancel-btn-modal modals-new-btns"
                                style={{ marginRight: '8px' }}
                                onClick={dateToggle}
                            >
                                Cancel
                            </Button>
                            <Button className="save-btn-modal modals-new-btns" onClick={updateDate}>
                                Apply
                            </Button>
                        </div>
                    </Popover>{' '}
                    <Filter
                        table="audit_logs"
                        slug={currentOrg.slug}
                        initValues={initialFilterValues}
                        setInitValues={setInitialFilterValues}
                        setCurrentPageNumber={setCurrentPageNumber}
                        setLastClickedPage={setLastClickedPage}
                        dropdownOpen={filterOpen}
                        selectedDateRange={selectedDateRange}
                        toggle={filterToggle}
                        teams={teams}
                    />
                </div>
            </div>
            {organizationSetup.status === 'loading' ? (
                <LoadingSpinner />
            ) : (
                <>
                    {[...initialFilterValues.event_type, ...initialFilterValues.team].length > 0 && (
                        <div className="d-flex align-items-start">
                            {[...initialFilterValues.event_type, ...initialFilterValues.team].map((item, index) => (
                                <div
                                    key={index}
                                    className="filter-style  d-flex align-items-center text-meili"
                                    style={{
                                        backgroundColor: '#F9EFFE',
                                        marginRight: '8px',
                                    }}
                                >
                                    <HiOutlineX
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setInitialFilterValues((prevValues) => {
                                                const newEventType = prevValues.event_type.filter(
                                                    (t) => t !== item
                                                )
                                                const newTeam = prevValues.team.filter((t) => t !== item)

                                                const updatedValues = {
                                                    event_type: newEventType,
                                                    team: newTeam,
                                                }

                                                dispatch(
                                                    getAuditLogsLight({
                                                        slug: currentOrg.slug,
                                                        filters: updatedValues,
                                                    })
                                                )

                                                setLastClickedPage(0)
                                                setCurrentPageNumber(1)

                                                return updatedValues
                                            })
                                        }}
                                    />
                                    {item}
                                </div>
                            ))}
                        </div>
                    )}
                    <div className="rounded-table-container ag-theme-quartz">
                        {organizationSetup?.audit_logs?.results?.length > 0 ? (
                            <AgGridReact
                                ref={gridRef}
                                classes="ag-header-group-cell w-100"
                                keyField="uuid"
                                rowData={dataLogs}
                                columnDefs={columns}
                                onGridReady={onGridReady}
                                pagination={true}
                                paginationPageSize={10}
                                paginationPageSizeSelector={[10, 20, 50, 100]}
                                defaultColDef={defaultColDef}
                                rowHeight={70}
                                onPaginationChanged={getCurrentPage}
                                quickFilterText={searchText}
                                domLayout="autoHeight"
                                rowClassRules={{
                                    'gray-row': (params) => params.node.rowIndex % 2 === 1,
                                }}
                            />
                        ) : (
                            <h5 className="p-4">There are currently no logs.</h5>
                        )}
                        {organizationSetup?.audit_logs?.next &&
                            lastClickedPage ===
                                Math.ceil(organizationSetup?.audit_logs?.results.length / 10) && (
                                <Button
                                    style={{
                                        position: 'absolute',
                                        bottom: '0.7rem',
                                        right: '24px',
                                        borderRadius: '8px',
                                        background: '#f9fafb',
                                        color: '#4e5462',
                                    }}
                                    onClick={() => setCurrentPageNumber(currentPageNumber + 1)}
                                >
                                    Load more (
                                    {organizationSetup?.audit_logs?.count -
                                        organizationSetup?.audit_logs?.results.length}
                                    )
                                </Button>
                            )}
                    </div>
                </>
            )}
        </div>
    )
}

export default AuditLogs
