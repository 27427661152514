import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { selectors } from 'features/dashboard'
import { TITLE } from 'config'
import {
    getOutdoorArea,
    getOutdoorMapPoints,
    getOutdoorMapPaths,
    getMapAreaOfInterest,
    getMapAreaDetails,
} from 'features/dashboard/Map.actionTypes'
import { Loading } from 'ui/common'
import MapLayer from '../MapComponents/MapLayer'
import OutsideMapLayer from '../MapComponents/OutsideMapLayer'

const TeamMap = ({ match }) => {
    const { slug } = match.params
    const dispatch = useDispatch()
    const team = useSelector(selectors.getTeam)
    const mapStore = useSelector(selectors.getTeamMap)
    const [status, setStatus] = useState('loading')



    const areaUuid = mapStore?.areas?.uuid
    const isOutsideMap = team.details?.operation_type === 'outside'
    const isInsideMap = team.details?.operation_type === 'inside'

    useEffect(() => {
        document.title = `${TITLE} - Maps`
    }, [])

    useEffect(() => {
        setStatus('loading')
        if (slug !== team.details?.slug) return

        if (isOutsideMap) {
            Promise.all([
                dispatch(getOutdoorArea({ slug })),
                dispatch(getOutdoorMapPoints({ slug })),
                dispatch(getOutdoorMapPaths({ slug })),
            ]).then(([{ payload }]) => {
                if (!payload.data.geometry) {
                    setStatus('redirect-outdoor')
                    return
                }
                if (payload.data?.geometry.coordinates.length === 0) {
                    setStatus('redirect-outdoor')
                    return
                }
                setStatus('outside')
            })
            return
        }
        if (isInsideMap) {
            Promise.all([
                dispatch(getMapAreaDetails(areaUuid)),
                dispatch(getMapAreaOfInterest(areaUuid)),
            ]).then(([{ payload }]) => {
                if (Object.values(payload.data).length === 0) {
                    setStatus('redirect-indoor')
                    return
                }
                setStatus('loaded')
            })
            setStatus('error')
        }
    }, [slug]) // eslint-disable-line react-hooks/exhaustive-deps

    if (status === 'loading') return <Loading />

    if (status === 'redirect-indoor') return <Redirect to="./modify/" />

    if (status === 'redirect-outdoor') return <Redirect to="./outside-modify/" />

    if (status === 'outside')
        return (
            <OutsideMapLayer
                area={mapStore.area}
                pointsStore={mapStore.outdoorPoints}
                pathsStore={mapStore.paths}
            />
        )

    return mapStore ? (
        <MapLayer map={mapStore} parentStatus={status} slug={slug} devices={team.setup.devices} orgSlug={team.details.organization.slug} />
    ) : (
        <Redirect to={`/dashboard/${slug}/maps/upload/`} />
    )
}

export default TeamMap
