import React from 'react'
import { Button, ButtonGroup, NavLink } from 'reactstrap'
import { Link } from 'react-router-dom'

const MiniNavbar = ({ slug, isActive, status }) => {
    return (
        <ButtonGroup
            className="org-button-group"
            style={{ width: '40rem', padding: '4px' }}
        >
            <Button
                disabled={status ? status : false}
                className={`${
                    isActive === 'map-layer' ? 'org-button-selected' : 'actionBtn'
                } org-button`}
                style={{ height: '3rem', borderRadius: '6px' }}
            >
                <NavLink tag={Link} className='org-button-links' to={`/dashboard/${slug}/maps/`}>
                    Path & station editor
                </NavLink>
            </Button>
            <Button
                disabled={status ? status : false}
                className={`${
                    isActive === 'map-create' ? 'org-button-selected' : 'actionBtn'
                }  org-button`}
                style={{ height: '3rem', borderRadius: '6px' }}
            >
                <NavLink tag={Link} className='org-button-links' to={`/dashboard/${slug}/maps/modify/`}>
                    Map editor
                </NavLink>
            </Button>
            <Button
                className={`${
                    isActive === 'map-upload' ? 'org-button-selected' : 'actionBtn'
                } org-button`}
                style={{ height: '3rem', borderRadius: '6px' }}
            >
                <NavLink tag={Link} className='org-button-links' to={`/dashboard/${slug}/maps/upload/`}>
                    Map uploader
                </NavLink>
            </Button>
        </ButtonGroup>
    )
}

export default MiniNavbar
