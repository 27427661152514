import { produce } from 'immer'
import { actionTypes } from './Organization.actionTypes'
import { actionTypes as teamActionTypes } from './Team.actionTypes'

const initialState = {
    status: 'loading',
    manufacturer: [],
    list: [],
    members: [],
    results: [],
    teams: [],
    categories: [],
    integrations: [],
    integration: { orders: [], locations: [] },
    integrations_orders: [],
    webhooks: [],
    customeValue: {},
    details: {},
    release_notes: [],
    audit_logs: {},
}

export default produce((draft, { type, payload }) => {
    switch (type) {
        // Loading States
        case teamActionTypes.CREATE_TEAM:
        case teamActionTypes.UPDATE_TEAM:
        case actionTypes.GET_INTEGRATION:
        case actionTypes.GET_INTEGRATION_ORDERS:
        case actionTypes.GET_INTEGRATION_LOCATION_GROUP:
        case actionTypes.GET_ORGS:
        case actionTypes.GET_ORG:
        case actionTypes.GET_ORG_TEAMS:
        case actionTypes.CREATE_ORG:
        case actionTypes.CREATE_ORG_MEMBER:
        case actionTypes.REMOVE_ORG_MEMBER:
        case actionTypes.UPDATE_ORG:
        case actionTypes.UPDATE_CUSTOMVALUE:
        case actionTypes.GET_ALL_ORG_MEMBERS:
        case actionTypes.GET_MANUFACTURER:
        case actionTypes.GET_CUSTOMVAlUE:
        case actionTypes.RELEASE_NOTES:
        case actionTypes.GET_AUDIT_LOGS:
            draft.status = 'loading'
            return draft

        // Loaded States
        case actionTypes.CREATE_WMS_SUCCESS:
        case actionTypes.CREATE_LOCATION_GROUP_SUCCESS:
        case actionTypes.GET_INTEGRATION_SUCCESS:
        case actionTypes.GET_INTEGRATION_ORDERS_SUCCESS:
        case actionTypes.GET_INTEGRATION_LOCATION_GROUP_SUCCESS:
        case actionTypes.GET_ORG_SUCCESS:
        case actionTypes.GET_ORG_INTEG_ORDER_SUCCESS:
        case actionTypes.GET_ORG_INTEG_SUCCESS:
        case actionTypes.GET_ORG_TEAMS_SUCCESS:
        case actionTypes.CREATE_ORG_WEBHOOK_SUCCESS:
        case actionTypes.GET_ORG_WEBHOOKS_SUCCESS:
        case actionTypes.GET_ORG_CATEGORIES_SUCCESS:
        case actionTypes.CREATE_ORG_SUCCESS:
        case actionTypes.CREATE_ORG_MEMBER_SUCCESS:
        case actionTypes.REMOVE_ORG_MEMBER_SUCCESS:
        case actionTypes.UPDATE_ORG_SUCCESS:
        case actionTypes.UPDATE_ORG_WEBHOOK_SUCCESS:
        case actionTypes.UPDATE_ORG_CATEGORY_SUCCESS:
        case actionTypes.UPDATE_ORG_MEMBER_SUCCESS:
        case actionTypes.UPDATE_CUSTOMVALUE_SUCCESS:
        case actionTypes.GET_ALL_ORG_MEMBERS_SUCCESS:
        case actionTypes.GET_MANUFACTURER_SUCCESS:
        case actionTypes.GET_CUSTOMVAlUE_SUCCESS:
        case actionTypes.GET_ORGS_SUCCESS:
        case actionTypes.RELEASE_NOTES_SUCCESS:
        case actionTypes.GET_AUDIT_LOGS_SUCCESS:
            draft.status = 'loaded'
            // Apply payload data if available
            if (payload && payload.data) {
                // Handle specific cases where payload data needs to be updated
                switch (type) {
                    case actionTypes.CREATE_WMS_SUCCESS:
                    case actionTypes.CREATE_LOCATION_GROUP_SUCCESS:
                        draft.integrations.push(payload.data)
                        break
                    case actionTypes.GET_INTEGRATION_ORDERS_SUCCESS:
                        draft.integration.orders = payload.data
                        break
                    case actionTypes.GET_INTEGRATION_LOCATION_GROUP_SUCCESS:
                        draft.integration.locations = payload.data
                        break
                    case actionTypes.GET_ORG_SUCCESS:
                        draft.details = payload.data
                        break
                    case actionTypes.GET_ORG_INTEG_ORDER_SUCCESS:
                        draft.integrations_orders = payload.data
                        break
                    case actionTypes.GET_ORG_INTEG_SUCCESS:
                        draft.integrations = payload.data
                        break
                    case actionTypes.GET_ORG_TEAMS_SUCCESS:
                        draft.teams = payload.data
                        break
                    case actionTypes.GET_ORG_WEBHOOKS_SUCCESS:
                        draft.webhooks = payload.data
                        break
                    case actionTypes.GET_ORG_CATEGORIES_SUCCESS:
                        draft.categories = payload.data
                        break
                    case actionTypes.CREATE_ORG_SUCCESS:
                        draft.list = [...draft.list, payload.data]
                        break
                    case actionTypes.CREATE_ORG_MEMBER_SUCCESS:
                        draft.members = [...draft.members, payload.data]
                        break
                    case actionTypes.REMOVE_ORG_MEMBER_SUCCESS:
                        draft.members = draft.members.filter(
                            (member) => member.uuid !== payload.data.uuid
                        )
                        break
                    case actionTypes.GET_ORGS_SUCCESS:
                        draft.list = payload.data
                        break
                    case actionTypes.UPDATE_ORG_SUCCESS:
                        draft.list = draft.list.map((org) =>
                            org.uuid === payload.data.uuid ? payload.data : org
                        )
                        break
                    case actionTypes.UPDATE_ORG_WEBHOOK_SUCCESS:
                        draft.webhooks = draft.webhooks.map((webhook) =>
                            webhook.uuid === payload.data.uuid ? payload.data : webhook
                        )
                        break
                    case actionTypes.UPDATE_ORG_CATEGORY_SUCCESS:
                        draft.categories = draft.categories.map((cat) =>
                            cat.uuid === payload.data.uuid ? payload.data : cat
                        )
                        break
                    case actionTypes.UPDATE_ORG_MEMBER_SUCCESS:
                        draft.results = draft.results.map((memb) =>
                            memb.uuid === payload.data.uuid ? payload.data : memb
                        )
                        break
                    case actionTypes.UPDATE_CUSTOMVALUE_SUCCESS:
                        draft.customValue = { ...draft.customValue, ...payload.data }
                        break
                    case actionTypes.GET_ALL_ORG_MEMBERS_SUCCESS:
                        draft.results = payload.data.results
                        break
                    case actionTypes.GET_MANUFACTURER_SUCCESS:
                        draft.manufacturer = payload.data
                        break
                    case actionTypes.GET_CUSTOMVAlUE_SUCCESS:
                        draft.customValue = payload.data
                        break
                    case actionTypes.GET_AUDIT_LOGS_SUCCESS:
                        if (!draft.audit_logs || !payload.data.previous) {
                            // First page or no previous data, replace with new data
                            draft.audit_logs = payload.data
                        } else {
                            // Create a Set of unique identifiers using `event_type` and `created_at`
                            const existingEntries = new Set(
                                draft.audit_logs.results.map(
                                    (item) => `${item.event_type}-${item.created_at}`
                                )
                            )

                            // Merge new results, filtering out duplicates based on `event_type` and `created_at`
                            draft.audit_logs.results = [
                                ...draft.audit_logs.results,
                                ...payload.data.results.filter(
                                    (item) =>
                                        !existingEntries.has(
                                            `${item.event_type}-${item.created_at}`
                                        )
                                ),
                            ]

                            // Preserve pagination metadata
                            draft.audit_logs.next = payload.data.next
                            draft.audit_logs.previous = payload.data.previous
                        }
                        break

                    case actionTypes.RELEASE_NOTES_SUCCESS:
                        draft.release_notes = payload.data
                        break
                    default:
                        break
                }
            }
            return draft
        // Error States
        case teamActionTypes.CREATE_TEAM_FAIL:
        case teamActionTypes.UPDATE_TEAM_FAIL:
        case actionTypes.GET_ORG_TEAMS_FAIL:
        case actionTypes.GET_ALL_ORG_MEMBERS_FAIL:
        case actionTypes.GET_CUSTOMVAlUE_FAIL:
        case actionTypes.UPDATE_ORG_FAIL:
        case actionTypes.CREATE_ORG_FAIL:
        case actionTypes.CREATE_ORG_MEMBER_FAIL:
        case actionTypes.REMOVE_ORG_MEMBER_FAIL:
        case actionTypes.RELEASE_NOTES_FAIL:
        case actionTypes.GET_AUDIT_LOGS_FAIL:
            draft.status = 'error'
            return draft
        case actionTypes.RESET_AUDIT_LOGS:
            draft.audit_logs = {}
            return draft

        default:
            return draft
    }
}, initialState)
