export const actionTypes = {
    GET_TEAM_ANALYTICS: 'GET_TEAM_ANALYTICS',
    GET_TEAM_ANALYTICS_SUCCESS: 'GET_TEAM_ANALYTICS_SUCCESS',
    GET_TEAM_ANALYTICS_FAIL: 'GET_TEAM_ANALYTICS_FAIL',

    GET_All_CARD: 'GET_All_CARD',
    GET_All_CARD_SUCCESS: 'GET_All_CARD_SUCCESS',
    GET_All_CARD_FAIL: 'GET_All_CARD_FAIL',

    GET_DASHBOARD_VIEW: 'GET_DASHBOARD_VIEW',
    GET_DASHBOARD_VIEW_SUCCESS: 'GET_DASHBOARD_VIEW_SUCCESS',
    GET_DASHBOARD_VIEW_FAIL: 'GET_DASHBOARD_VIEW_FAIL',

    GET_DASHBOARD_CARD: 'GET_DASHBOARD_CARD',
    GET_DASHBOARD_CARD_SUCCESS: 'GET_DASHBOARD_CARD_SUCCESS',
    GET_DASHBOARD_CARD_FAIL: 'GET_DASHBOARD_CARD_FAIL',

    CREATE_DASHBOARD: 'CREATE_DASHBOARD',
    CREATE_DASHBOARD_SUCCESS: 'CREATE_DASHBOARD_SUCCESS',
    CREATE_DASHBOARD_FAIL: 'CREATE_DASHBOARD_FAIL',

    UPDATE_DASHBOARD: ' UPDATE_DASHBOARD',
    UPDATE_DASHBOARD_SUCCESS: ' UPDATE_DASHBOARD_SUCCESS',
    UPDATE_DASHBOARD_FAIL: ' UPDATE_DASHBOARD_FAIL',

    ADD_DASHBOARD_CARD: 'ADD_DASHBOARDCARD',
    ADD_DASHBOARD_CARD_SUCCESS: 'ADD_DASHBOARD_CARD_SUCCESS',
    ADD_DASHBOARD_CARD_FAIL: 'ADD_DASHBOARD_CARD_FAIL',

    DELETE_DASHBOARD_VIEW: 'DELETE_DASHBOARD_VIEW',
    DELETE_DASHBOARD_VIEW_SUCCESS: 'DELETE_DASHBOARD_VIEW_SUCCESS',
    DELETE_DASHBOARD_VIEW_FAIL: 'DELETE_DASHBOARD_VIEW_FAIL',

    DELETE_DASHBOARD_CARD: 'DELETE_DASHBOARD_CARD',
    DELETE_DASHBOARD_CARD_SUCCESS: 'DELETE_DASHBOARD_CARD_SUCCESS',
    DELETE_DASHBOARD_CARD_FAIL: 'DELETE_DASHBOARD_CARD_FAIL',

    FILTER_DASHBOARD: 'FILTER_DASHBOARD',
    FILTER_DASHBOARD_SUCCESS: 'FILTER_DASHBOARD_SUCCESS',
    FILTER_DASHBOARD_FAIL: 'FILTER_DASHBOARD_FAIL',
}

export function getTeamAnalytics({ slug }) {
    return {
        type: actionTypes.GET_TEAM_ANALYTICS,
        payload: {
            request: {
                url: `api/teams/${slug}/analytics/`,
                method: 'GET',
            },
        },
    }
}
export function getCards({slug,uuid}) {
    return {
        type: actionTypes.GET_All_CARD,
        payload: {
            request: {
                url: `/api/v2/teams/${slug}/dashboards/${uuid}/card_presets/`,
                method: 'GET',
            },
        },
    }
}

export function getDashboardView({ slug }) {
    return {
        type: actionTypes.GET_DASHBOARD_VIEW,
        payload: {
            request: {
                url: `api/v2/teams/${slug}/dashboards/`,
                method: 'GET',
            },
        },
    }
}

// export function getDashboardCard({ slug, uuid }) {
//     return {
//         type: actionTypes.GET_DASHBOARD_CARD,
//         payload: {
//             request: {
//                 url: `api/v2/teams/${slug}/dashboards/${uuid}/`,
//                 method: 'GET',
//             },
//         },
//     }
// }

export function createDashboard({ slug, data }) {
    return {
        type: actionTypes.CREATE_DASHBOARD,
        payload: {
            request: {
                url: `api/v2/teams/${slug}/dashboards/`,
                method: 'POST',
                data,
            },
        },
    }
}

export function updateDashboard({ slug, uuid, data }) {
    return {
        type: actionTypes.UPDATE_DASHBOARD,
        payload: {
            request: {
                url: `api/v2/teams/${slug}/dashboards/${uuid}/`,
                method: 'PATCH',
                data,
            },
        },
    }
}

export function addDashboardCard({ slug, uuid, data }) {
    return {
        type: actionTypes.ADD_DASHBOARD_CARD,
        payload: {
            request: {
                url: `/api/v2/teams/${slug}/dashboards/${uuid}/cards/`,
                method: 'POST',
                data,
            },
        },
    }
}
export function getFilterDashboard({ slug, uuid, ...data }) {
    return {
        type: actionTypes.FILTER_DASHBOARD,
        payload: {
            request: {
                url: `api/v2/teams/${slug}/dashboards/${uuid}/`,
                method: 'POST',
                data,
            },
        },
    }
}
export function deleteDashboardView({ slug, uuid }) {
    return {
        type: actionTypes.DELETE_DASHBOARD_VIEW,
        payload: {
            request: {
                url: `api/v2/teams/${slug}/dashboards/${uuid}/`,
                method: 'DELETE',
            },
        },
    }
}
export function deleteDashboardCard(uuid) {
    return {
        type: actionTypes.DELETE_DASHBOARD_CARD,
        payload: {
            request: {
                url: `api/v2/dashboards/cards/${uuid}/`,
                method: 'DELETE',
            },
        },
    }
}
