import React, { useState, useEffect } from 'react'
import { Image as KonvaImage } from 'react-konva'
import useImage from 'use-image'

const MapEditorStations = ({ pointKey, point, largestPoint, aoi, icon, editPoint, ...props }) => {
    const [imageUrl, setImageUrl] = useState(null)
    const [stationDefault] = useImage(imageUrl)
    const [charging] = useImage('/svgs/map-layer/charging-station.svg')
    const [docking] = useImage('/svgs/map-layer/docking-station.svg')
    const [undocking] = useImage('/svgs/map-layer/undocking-station.svg')
    const [resting] = useImage('/svgs/map-layer/resting-station.svg')
    const [traversal] = useImage('/svgs/map-layer/traversal-default.svg')
    const [stationDisabled] = useImage('/svgs/map-layer/station-disabled.svg')
    const [chargingDisabled] = useImage('/svgs/map-layer/charging-disabled.svg')
    const [restingDisabled] = useImage('/svgs/map-layer/resting-disabled.svg')
    const [traversalDisabled] = useImage('/svgs/map-layer/traversal-disabled.svg')
    const [evacuationDefault] = useImage('/svgs/map-layer/evacuation-station.svg')
    const [evacuationDisabled] = useImage('/svgs/map-layer/evacuation-disabled.svg')

     const iconColor = point?.icon_color ? point?.icon_color : '#BA4FFA'
    const modifySvgColor = (color) => {
        const svgURL = '/svgs/map-layer/station-default.svg'

        fetch(svgURL)
            .then((response) => response.text())
            .then((svg) => {
                // Modify the SVG content: replace stroke color
                const modifiedSvg = svg.replace(/stroke="[^"]+"/g, `stroke="${iconColor}"`)

                // Convert the modified SVG to a data URL
                const dataUrl =
                    'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(modifiedSvg)

                // Update the image URL state with the modified SVG data URL
                setImageUrl(dataUrl)
            })
    }

    useEffect(() => {
        // Modify SVG color whenever the color changes
        modifySvgColor(iconColor)
    }, [iconColor]) // eslint-disable-line react-hooks/exhaustive-deps

    const showImage = () => {
        switch (point.station_type) {
            case 'charging':
                if (!point.is_active) {
                    return chargingDisabled
                }
                return charging
            case 'docking':
                return docking
            case 'undocking':
                return undocking
            case 'resting':
                if (!point.is_active) {
                    return restingDisabled
                }
                return resting
            case 'traversal':
                if (!point.is_active) {
                    return traversalDisabled
                }
                return traversal
            case 'evacuation':
                if (!point.is_active) {
                    return evacuationDisabled
                }
                return evacuationDefault
            default:
                if (!point.is_active) {
                    return stationDisabled
                }
                return stationDefault
        }
    }

    if (
        editPoint &&
        (point.station_type === 'charging' ||
            point.station_type === 'resting' ||
            point.station_type === 'evacuation')
    )
        return

    return (
        <KonvaImage
            image={showImage()}
            width={point?.station_dimensions_in_pixels[1]}
            height={point?.station_dimensions_in_pixels[1]}
            {...props}
            offsetX={point?.station_dimensions_in_pixels[1] / 2}
            offsetY={point?.station_dimensions_in_pixels[1] / 2}
            x={point.points[0]}
            y={point.points[1]}
            rotation={point.rotation}
            listening={false}
        />
    )
}

export default MapEditorStations
