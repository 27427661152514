import React from 'react'
import { AiOutlineSetting } from 'react-icons/ai'
import { BiPlus } from 'react-icons/bi'
import { Button } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import {
    getCards,
    addDashboardCard,
    deleteDashboardCard,
    getFilterDashboard,
} from 'features/dashboard/TeamAnalytics.actionTypes'
import dots from './../../assets/images/illustrations/dots.svg'
import { MdOutlineCancel } from 'react-icons/md'

const SortableItem = SortableElement(({ idx, value, onRemove }) => (
    <div className="dash-cards-setting d-flex justify-content-between">
        <div>
            <img alt="dots" src={dots}></img>
            <span className="card-title">{value}</span>
        </div>
        <MdOutlineCancel
            size={24}
            onClick={() => {
                onRemove(idx)
            }}
            className="btn btn-icon"
            style={{ color: '#656D7E' }}
        />
    </div>
))
const SortableList = SortableContainer(({ list, onRemove }) => {
    return (
        <div>
            {list?.map((value, index) => (
                <SortableItem
                    key={`item-${value.uuid}`}
                    index={index}
                    idx={value.uuid}
                    value={value.title}
                    onRemove={onRemove}
                />
            ))}
        </div>
    )
})

export default function DashboardPagesetting({
    slug,
    uuid,
    setSave,
    view,
    setView,
    setModalType,
    setSetting,
    setCreateView,
    settingToggle,
    ToggleSidebar,
    list,
    setList,
    setAddcard,
    modalToggle,
    from_date, 
    to_date
}) {
    const dispatch = useDispatch()

    const getDash = (view) => {
        setView(view)
        modalToggle()
    }

    const arrayMoveImmutable = (array, oldIndex, newIndex) => {
        const newArray = Array.from(array)
        const [removed] = newArray.splice(oldIndex, 1)
        newArray.splice(newIndex, 0, removed)
        return newArray.map((item, index) => ({
            ...item,
            location: index + 1,
        }))
    }
    const onSortEnd = ({ oldIndex, newIndex }) => {
        setList((list) => arrayMoveImmutable(list, oldIndex, newIndex))
    }


    const onRemove = (idx) => {
        dispatch(deleteDashboardCard(idx))
    }
    return (
        <>
            <div
                style={{
                    width: '392px',
                    height: window.innerHeight - 245,
                    display: 'block',
                    position: 'fixed',
                    right: '0px',
                    bottom: '0px',
                    padding: '24px',
                    borderTopLeftRadius: '10px',
                    background: '#fff',
                    boxShadow:
                        '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
                    overflowY: 'auto',
                }}
            >
                <div
                    className="d-flex justify-content-between"
                    style={{ borderBottom: '1px solid #E7E9EC', paddingBottom: '12px' }}
                >
                    <div>
                        <h6 className="custmText">Customise page</h6>
                        <small className="small-text"> Add or remove dashboard cards </small>
                    </div>
                    <img
                        src="/svgs/close-icon/x-dark-default.svg"
                        alt="obstacle-icon"
                        width="24px"
                        height="24px"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            ToggleSidebar()
                        }}
                    />
                </div>

                <div style={{ cursor: 'grab' }}>
                    <div style={{ paddingBottom: '12px', marginTop: '24px' }}>Cards </div>
                    <SortableList
                        distance={1}
                        helperClass="sortableHelper"
                        list={list}
                        lockAxis="y"
                        onSortEnd={onSortEnd}
                        onRemove={(idx) => onRemove(idx)}
                        onInsertAfter={(value, index) => this.handleInsertAfter(value, index)}
                        onInsertBefore={(value, index) => this.handleInsertBefore(value, index)}
                    />

                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            dispatch(getCards({ slug, uuid }))
                            modalToggle()
                            setAddcard(true)
                        }}
                        className="added-border dash-cards-setting"
                    >
                        <BiPlus className="btn btn-icon custmText"></BiPlus>
                        <span> Add cards</span>
                    </div>
                    <div
                        className="d-flex justify-content-between "
                        style={{
                            bottom: 0,
                            position: 'relative',
                            width: '340px',
                            marginTop: '70px',
                        }}
                    >
                        <span
                            className="d-flex view-setting mr-2"
                            color="white"
                            onClick={() => {
                                getDash(view)
                                setSetting(true)
                                setSave(false)
                                setCreateView(false)
                                setModalType('setting')
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <AiOutlineSetting size={24} className="mb-1" />
                            <span
                                style={{ paddingLeft: '1px', color: '#3A3E66' }}
                                className="weight-500"
                            >
                                View settings{' '}
                            </span>
                        </span>

                        <div className="d-flex mr-0">
                            <Button
                                className="modals-cancel-btn"
                                color="none"
                                onClick={() => {
                                    ToggleSidebar()
                                }}
                            >
                                Cancel
                            </Button>

                            <Button
                                className="modals-save-btn font-14"
                                color="meili"
                                onClick={() => {
                                    modalToggle()
                                    setAddcard(false)
                                    settingToggle()
                                    setSave(true)
                                    setModalType('save')
                                    const newList = list.map((item, newIndex) => ({
                                        ...item,
                                        location: newIndex + 1,
                                    }))
                                    const data = newList

                                    dispatch(
                                        addDashboardCard({
                                            slug,
                                            uuid,
                                            data,
                                        })
                                    )
                                    dispatch(getFilterDashboard({ slug, uuid , from_date, to_date}))
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
