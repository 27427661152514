import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { Button, ButtonGroup, Collapse, DropdownMenu, DropdownToggle, DropdownItem,UncontrolledDropdown } from 'reactstrap'
import moment from 'moment'
import { produce } from 'immer'
import { TASK_TYPES } from '../Teams/TeamTasksV2'
import TasksGridView from '../Tasks/TasksGridView'
import ScheduleTasksGridView from '../ScheduleTasksGridView'
import AllTasksGridView from '../Tasks/AllTasksGridView'
import { useSelector } from 'react-redux'
import { selectors } from 'features/dashboard'

const Missions = memo(({
    slug,
    currentLayout,
    lastJsonMessage,
    setTaskHovered,
    robot,
    setCancelTask,
}) => {
    const taskStore = useSelector(selectors.getTask)
    const [filter] = useState('all')
    const [buttonText, setButtonText] = useState('View all')
    const [collapse, setCollapse] = useState({
        in_progress: false,
        queue: false,
        completed: false,
        cancelled: false,
        upcoming: false,
        all: true,
    })
    const [tasks, setTasks] = useState([
        {
            in_progress: { ...TASK_TYPES },
            queue: { ...TASK_TYPES },
            cancelled: { ...TASK_TYPES },
            all: { ...TASK_TYPES },
        },
    ])
    let setRef = window.innerWidth > 920 ? false : true
    let isMobile = useRef(setRef)


    const isForCancelled = (task) =>
        task.status === 'terminated' || task.status === 'failed' || task.status === 'aborted'
    const isQueued = (task) =>
        task.status === 'pending' ||
        task.status === 'created' ||
        task.status === 'sent' ||
        task.status === 'rerun'
    const isActiveTask = (task) =>
        task.status === 'rerun' || task.status === 'created' || task.status === 'in_progress'

    const catgorizeTasks = useMemo(() => {
        let filteredTask = {
            in_progress: { ...TASK_TYPES },
            queue: { ...TASK_TYPES },
            cancelled: { ...TASK_TYPES },
            completed: { ...TASK_TYPES },
            all: { ...TASK_TYPES },
        }

        Object.values(taskStore.listV2)?.forEach((task) => {
            filteredTask = produce(filteredTask, (draft) => {
                draft.all.all.push(task)

                if (isQueued(task)) {
                    draft.queue.all.push(task)
                }

                if (task.status === 'completed') {
                    draft.completed.all.push(task)
                }

                if (task.status === 'in_progress' || task.status === 'paused') {
                    draft.in_progress.all.push(task)
                }

                if (isForCancelled(task)) {
                    draft.cancelled.all.push(task)
                }

                if (isActiveTask(task)) {
                    setCancelTask(true)
                }
            })
        })

        return filteredTask
    }, [taskStore.listV2]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setTasks(catgorizeTasks)
    }, [taskStore.listV2, lastJsonMessage]) // eslint-disable-line react-hooks/exhaustive-deps

    const upcomingTask = useMemo(() => {
        const currentDate = moment().format('YYYY-MM-DD HH:mm')
        const currentDay = moment().day() - 1
        return taskStore.schedulesV2
            ?.filter((tz) => {
                // Check if any of the values are null or NaN
                return (
                    (tz?.week_days_tz &&
                        tz?.date_tz + ' ' + tz?.time_tz > currentDate &&
                        !tz?.execute_every &&
                        tz?.week_days_tz.every((day) => day !== null) && // Check week_days_tz
                        tz?.date_tz !== null && // Check date_tz
                        !isNaN(Date.parse(tz?.date_tz)) && // Check if date_tz is a valid date
                        tz?.time_tz !== null && // Check time_tz
                        tz?.time_tz.trim() !== '') || // Check if time_tz is not empty
                    (tz?.execute_every &&
                        tz?.week_days_tz?.[tz?.week_days_tz?.length - 1] > currentDay)
                )
            })
            .map((task) => task)
    }, [taskStore.schedulesV2])

    const DropdownButton = ({ label, isActive, onClick }) => (
        <DropdownItem className="dropdownhover">
            <Button outline className="text-dark" active={isActive} onClick={onClick}>
                {label}
            </Button>
        </DropdownItem>)

    const ToggleButton = ({ label, isActive, onClick }) => (
        <Button
            outline
            className="btn-group shadow-none"
            active={isActive}
            onClick={onClick}
            data-testid="viewallid"
        >
            {label}
        </Button>
    )
    

    const handleToggle = (key, text) => {
        setButtonText(text)
        setCollapse((prevState) => ({
            ...prevState,
            in_progress: key === 'in_progress' ? !prevState.in_progress : false,
            queue: key === 'queue' ? !prevState.queue : false,
            completed: key === 'completed' ? !prevState.completed : false,
            cancelled: key === 'cancelled' ? !prevState.cancelled : false,
            upcoming: key === 'upcoming' ? !prevState.upcoming : false,
            all: key === 'all' ? !prevState.all : false,
        }))
    }

    return (
        <div className="w-100 h-100 d-flex flex-column justify-content-start align-items-start gap-2">
            {isMobile.current ? (
                        <UncontrolledDropdown style={{ marginBottom: '8px' }}>
                            <DropdownToggle caret className="text-dark bg-white">
                                {buttonText}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownButton
                                    label="View all"
                                    isActive={collapse.all}
                                    onClick={() => handleToggle('all', 'View all')}
                                />
                                <DropdownButton
                                    label="In progress"
                                    isActive={collapse.in_progress}
                                    onClick={() => handleToggle('in_progress', 'In progress')}
                                />
                                <DropdownButton
                                    label="Queue"
                                    isActive={collapse.queue}
                                    onClick={() => handleToggle('queue', 'Queue')}
                                />
                                <DropdownButton
                                    label="Upcoming"
                                    isActive={collapse.upcoming}
                                    onClick={() => handleToggle('upcoming', 'Upcoming')}
                                />
                                <DropdownButton
                                    label="Completed"
                                    isActive={collapse.completed}
                                    onClick={() => handleToggle('completed', 'Completed')}
                                />
                                <DropdownButton
                                    label="Cancelled"
                                    isActive={collapse.cancelled}
                                    onClick={() => handleToggle('cancelled', 'Cancelled')}
                                />
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    ) : (
                        <ButtonGroup
                        className="d-flex align-items-center justify-content-center border"
                        style={{
                            borderRadius: '8px',
                            overflow: 'hidden',
                            height: '40px',
                            minHeight: '40px',
                            boxShadow: '0px 2px 2px 0px rgba(16, 24, 40, 0.06)',
                        }}
                    >
                        <ToggleButton
                            label="View all"
                            isActive={collapse.all}
                            onClick={() => handleToggle('all')}
                        />
                        <ToggleButton
                            label="In progress"
                            isActive={collapse.in_progress}
                            onClick={() => handleToggle('in_progress')}
                        />
                        <ToggleButton
                            label="Queue"
                            isActive={collapse.queue}
                            onClick={() => handleToggle('queue')}
                        />
                        <ToggleButton
                            label="Upcoming"
                            isActive={collapse.upcoming}
                            onClick={() => handleToggle('upcoming')}
                        />
                        <ToggleButton
                            label="Completed"
                            isActive={collapse.completed}
                            onClick={() => handleToggle('completed')}
                        />
                        <ToggleButton
                            label="Cancelled"
                            isActive={collapse.cancelled}
                            onClick={() => handleToggle('cancelled')}
                        />
                    </ButtonGroup>
                    )}
            <div className="p-0 m-0 w-100">
                {collapse.all && (
                    <Collapse isOpen={collapse.all} className="d-flex" data-testid="alltasks">
                        <AllTasksGridView
                            allTasks={tasks.all}
                            currentLayout={currentLayout}
                            upcoming={taskStore.schedulesV2}
                            filterLabel="all"
                            setTaskHovered={setTaskHovered}
                            upcomingTask={upcomingTask}
                            robot={robot}
                        />
                    </Collapse>
                )}
                {collapse.in_progress && (
                    <Collapse
                        isOpen={collapse.in_progress}
                        className="d-flex justify-content-between"
                        data-testid="progressgrid"
                    >
                        <TasksGridView
                            tasks={[...tasks.in_progress[filter]]}
                            setTaskHovered={setTaskHovered}
                            currentLayout={currentLayout}
                            filterLabel="progress"
                            robot={robot}
                        />
                    </Collapse>
                )}
                {collapse.queue && (
                    <Collapse isOpen={collapse.queue} className="d-flex" data-testid="queuegrid">
                        <TasksGridView
                            tasks={[...tasks.queue[filter]]}
                            setTaskHovered={setTaskHovered}
                            currentLayout={currentLayout}
                            filterLabel=" the queue"
                            robot={robot}
                        />
                    </Collapse>
                )}
                {collapse.upcoming && (
                    <Collapse
                        isOpen={collapse.upcoming}
                        className="d-flex justify-content-between card_item"
                        data-testid="upcominggrid"
                    >
                        <ScheduleTasksGridView
                            setTaskHovered={setTaskHovered}
                            tasks={taskStore?.schedulesV2}
                            currentLayout={currentLayout}
                            filterLabel="scheduled"
                            slug={slug}
                            upcomingTask={upcomingTask}
                        />
                    </Collapse>
                )}
                {collapse.completed && (
                    <Collapse
                        isOpen={collapse.completed}
                        className="d-flex justify-content-between"
                        data-testid="completegrid"
                    >
                        <TasksGridView
                            tasks={tasks.completed[filter]}
                            setTaskHovered={setTaskHovered}
                            currentLayout={currentLayout}
                            filterLabel="completed"
                        />
                    </Collapse>
                )}
                {collapse.cancelled && (
                    <Collapse
                        isOpen={collapse.cancelled}
                        className="d-flex justify-content-between"
                    >
                        <TasksGridView
                            tasks={[...tasks.cancelled[filter]]}
                            setTaskHovered={setTaskHovered}
                            currentLayout={currentLayout}
                            filterLabel="cancelled"
                        />
                    </Collapse>
                )}
            </div>
        </div>
    )
})

export default Missions
