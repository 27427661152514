import React, { useState } from 'react'
import { Field, Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { Button, Input, Form, FormGroup, Label } from 'reactstrap'
import { customInputForm } from 'ui/common'
import {
    createScheduleTaskV2,
    getPreset,
    getScheduleTasksV2,
    getTasksV2,
    runPresetTaskV2,
} from 'features/dashboard/Task.actionTypes'
import Scheduler from '../Scheduler'
import { removeEmptyStringsFromObj, removeNullFromObj } from 'utils'
import DropdownSelect from 'layout-components/Dropdown'
import { getFilteredVehicles } from './helpers'

const RunMissionPreset = ({
    presets,
    slug,
    toggle,
    setIsCreateNew,
    setIcon,
    vehicles,
    categories,
    setCurrentPage,
    nextPagePresets,
}) => {
    const dispatch = useDispatch()
    const [selectedPreset, setSelectedPreset] = useState('')
    const [robot, setRobot] = useState('')
    const [category, setCategory] = useState('')
    const handleSubmit = (data, { setSubmitting, resetForm }) => {
        const { schedule_later, run_when, by, several_occasions_type, ...rest } = data
        setSubmitting(true)
        if (schedule_later) {
            const nullRemoved = removeNullFromObj(rest)
            const stringRemove = removeEmptyStringsFromObj(nullRemoved)
            const type =
                run_when === 'run_once'
                    ? 'simple'
                    : several_occasions_type === 'week_days'
                    ? 'repeated'
                    : 'cron'
            dispatch(createScheduleTaskV2({ slug, data: stringRemove, type }))
                .then((res) => {
                    dispatch(getScheduleTasksV2({ slug }))
                    dispatch(getTasksV2(slug))
                    setSubmitting(false)
                    setIcon(false)
                    resetForm()
                    setSelectedPreset('')
                    setRobot('')
                    setCategory('')
                    toggle()
                })
                .catch((err) => setSubmitting(false))
        } else {
            const nullRemoved = removeNullFromObj(rest)
            const stringRemove = removeEmptyStringsFromObj(nullRemoved)
            dispatch(runPresetTaskV2({ uuid: rest.task_preset, data: stringRemove })).then(
                (res) => {
                    dispatch(getTasksV2(slug))
                    setSubmitting(false)
                    setIcon(false)
                    resetForm()
                    setSelectedPreset('')
                    setRobot('')
                    setCategory('')
                    toggle()
                }
            )
        }
    }

    const handlePresetChange = (setFieldValue, item) => {
        setFieldValue('task_preset', item.uuid)
        setSelectedPreset(item.title)
        dispatch(getPreset(item.uuid)).then((res) => {
            if (!res.error) {
                if (res.payload.data.vehicle) {
                    setRobot(res.payload.data?.vehicle.verbose_name)
                } else {
                    setRobot('')
                }
                if (res.payload.data?.required_vehicle_category) {
                    const cat = categories.find(
                        (category) => category.uuid === res.payload.data.required_vehicle_category
                    )
                    setCategory(cat.title)
                } else {
                    setCategory('')
                }
                setFieldValue('schedule_later', false)
                setFieldValue('vehicle_uuid', res.payload.data.vehicle)
                setFieldValue('priority_level', res.payload.data.priority_level)
                setFieldValue('auto_confirmation', res.payload.data.auto_confirmation)
                setFieldValue('looped_task', res.payload.data.looped_task)
                setFieldValue(
                    'required_vehicle_category',
                    res.payload.data.required_vehicle_category
                )
                setFieldValue('vehicle_uuid', res.payload.data.vehicle?.uuid)
            }
        })
    }

    return (
        <>
            <div
                className="w-100"
                style={{ borderBottom: '1px solid lightgray', marginBottom: '24px' }}
            >
                <h5>
                    <strong>Run mission </strong>
                </h5>
                <span>Run an existing preset or create a new one.</span>
            </div>

            <Formik
                initialValues={{
                    run_when: '',
                    by: 'week_days',
                    task_preset: '',
                    vehicle_uuid: '',
                    priority_level: 0,
                    looped_task: false,
                    schedule_later: false,
                    auto_confirmation: false,
                    required_vehicle_category: '',
                }}
                onSubmit={handleSubmit}
            >
                {({ values, handleSubmit, isSubmitting, setFieldValue, resetForm }) => (
                    <Form
                        className="pt-3 d-flex flex-column justify-content-between"
                        style={{ height: '85%', maxHeight: '100vh' }}
                        onSubmit={(e) => {
                            e.preventDefault()
                            handleSubmit()
                        }}
                    >
                        <div>
                            <FormGroup>
                                <Label className="modals-labels">Mission presets</Label>
                                <DropdownSelect
                                    selected={selectedPreset}
                                    setSelected={setSelectedPreset}
                                    options={presets}
                                    setFieldValue={setFieldValue}
                                    fieldValue="task_preset"
                                    onClick={handlePresetChange}
                                    setCurrentPage={setCurrentPage}
                                    nextPagePresets={nextPagePresets}
                                />
                            </FormGroup>

                            {values.task_preset && (
                                <>
                                    <FormGroup>
                                        <Label className="modals-labels" for="cat">
                                            Vehicle category
                                        </Label>
                                        <DropdownSelect
                                            selected={category}
                                            setSelected={setCategory}
                                            options={[{ title: 'No category' }, ...categories]}
                                            setFieldValue={setFieldValue}
                                            fieldValue="required_vehicle_category"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label className="modals-labels" for="vehicle">
                                            Robot
                                        </Label>
                                        <DropdownSelect
                                            selected={robot}
                                            setSelected={setRobot}
                                            options={['', ...getFilteredVehicles(vehicles, values)]}
                                            setFieldValue={setFieldValue}
                                            fieldValue="vehicle_uuid"
                                        />
                                    </FormGroup>

                                    <FormGroup
                                        style={{ marginTop: '24px' }}
                                        className="d-flex flex-column"
                                    >
                                        <Label className="modals-labels" for="priority_level">
                                            Priority
                                        </Label>
                                        <Field
                                            id="priority_level"
                                            name="range"
                                            type="range"
                                            value={values.priority_level}
                                            onChange={(e) => {
                                                setFieldValue(
                                                    'priority_level',
                                                    Number(e.target.value)
                                                )
                                            }}
                                        />
                                        <output>{values.priority_level}</output>
                                    </FormGroup>
                                    <FormGroup switch className="mb-2 ml-3">
                                        <Input
                                            style={{ height: '20px', width: '36px' }}
                                            role="switch"
                                            type="switch"
                                            className="modals-labels custom-switch-form"
                                            id="auto_confirmation"
                                            checked={values.auto_confirmation}
                                            name="auto_confirmation"
                                            onChange={(e) => {
                                                setFieldValue(
                                                    e.target.name,
                                                    !values.auto_confirmation
                                                )
                                            }}
                                        />
                                        <Label check className="ml-3 mt-1">
                                            Auto confirmation
                                        </Label>
                                    </FormGroup>

                                    <FormGroup switch className="mb-2 ml-3">
                                        <Input
                                            style={{ height: '20px', width: '36px' }}
                                            role="switch"
                                            type="switch"
                                            id="looped_task"
                                            className=" custom-switch-form modals-labels"
                                            checked={values.looped_task}
                                            name="looped_task"
                                            onChange={(e) => {
                                                setFieldValue(e.target.name, !values.looped_task)
                                                setFieldValue('schedule_later', false)
                                            }}
                                        />
                                        <Label check className="ml-3 mt-1">
                                            Looped mission
                                        </Label>
                                    </FormGroup>

                                    <FormGroup switch className="d-flex flex-column mb-2 ml-3">
                                        <Input
                                            style={{ height: '20px', width: '36px' }}
                                            role="switch"
                                            type="switch"
                                            id="schedule_later"
                                            checked={values.schedule_later}
                                            name="schedule_later"
                                            onChange={(e) => {
                                                setFieldValue(e.target.name, !values.schedule_later)
                                                setFieldValue('looped_task', false)
                                            }}
                                            className=" custom-switch-form modals-labels"
                                        />
                                        <Label check className="ml-3 mt-1">
                                            Schedule for later
                                        </Label>
                                        {values.schedule_later && (
                                            <small className="ml-3 text-meili">
                                                Note: Team's timezone will be used
                                            </small>
                                        )}
                                    </FormGroup>

                                    {values.schedule_later ? (
                                        <div className="p-2">
                                            <FormGroup switch>
                                                <Input
                                                    style={{ height: '20px', width: '36px' }}
                                                    role="switch"
                                                    type="switch"
                                                    id="run_once"
                                                    checked={values.run_when === 'run_once' || ''}
                                                    name="run_once"
                                                    onChange={(e) => {
                                                        delete values['several_occasions']
                                                        setFieldValue('run_when', 'run_once')
                                                    }}
                                                    className="custom-switch-form modals-labels"
                                                />
                                                <Label check className="ml-3 mt-1">
                                                    Run only once
                                                </Label>
                                            </FormGroup>
                                            {values.run_when === 'run_once' ? (
                                                <div className="d-flex justify-content-between align-items-center px-3 mb-2">
                                                    {' '}
                                                    <FormGroup className="mr-1 w-75">
                                                        <Label className="modals-labels">
                                                            Execution date
                                                        </Label>
                                                        <Field
                                                            component={customInputForm}
                                                            type="date"
                                                            className="modals-inputs"
                                                            min="2020-01-01"
                                                            max="2025-12-31"
                                                            value={values.date || ''}
                                                            name="date"
                                                        />
                                                    </FormGroup>
                                                    <FormGroup className="w-50 ml-1">
                                                        <Label className="modals-labels">
                                                            Execution time
                                                        </Label>
                                                        <Field
                                                            component={customInputForm}
                                                            type="time"
                                                            timeZone="UTC"
                                                            className="modals-inputs"
                                                            value={values.time || ''}
                                                            name="time"
                                                        />
                                                    </FormGroup>
                                                </div>
                                            ) : null}

                                            <FormGroup switch>
                                                <Input
                                                    style={{ height: '20px', width: '36px' }}
                                                    type="switch"
                                                    role="switch"
                                                    id="several_occasions"
                                                    checked={
                                                        values.run_when === 'several_occasions' ||
                                                        ''
                                                    }
                                                    name="several_occasions"
                                                    onChange={(e) => {
                                                        delete values['run_once']
                                                        setFieldValue(
                                                            'run_when',
                                                            'several_occasions'
                                                        )
                                                    }}
                                                    className=" custom-switch modals-labels"
                                                />
                                                <Label check className="ml-3 mt-1">
                                                    Run on several occasions
                                                </Label>
                                            </FormGroup>
                                            {values.run_when === 'several_occasions' ? (
                                                <div className="p-2">
                                                    <FormGroup switch className="ml-3">
                                                        <Input
                                                            style={{
                                                                height: '20px',
                                                                width: '36px',
                                                            }}
                                                            type="switch"
                                                            role="switch"
                                                            id="week_Days"
                                                            checked={
                                                                values.several_occasions_type ===
                                                                    'week_days' || ''
                                                            }
                                                            name="week_days"
                                                            onChange={(e) => {
                                                                delete values['execute_every']
                                                                setFieldValue(
                                                                    'several_occasions_type',
                                                                    'week_days'
                                                                )
                                                            }}
                                                            className="custom-switch modals-labels"
                                                        />
                                                        <Label check className="ml-3 mt-1">
                                                            Week days{' '}
                                                        </Label>
                                                    </FormGroup>
                                                    {values.several_occasions_type ===
                                                        'week_days' && (
                                                        <>
                                                            <FormGroup className="d-flex flex-column">
                                                                <Label className="modals-labels">
                                                                    Execution day(s)
                                                                </Label>
                                                                <div>
                                                                    <Scheduler
                                                                        isDisabled={
                                                                            values.by !==
                                                                            'week_days'
                                                                        }
                                                                        days={
                                                                            values.week_days || []
                                                                        }
                                                                        onChange={(week_days) => {
                                                                            setFieldValue(
                                                                                'week_days',
                                                                                week_days
                                                                            )
                                                                        }}
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                            <FormGroup className="">
                                                                <Label className="modals-labels">
                                                                    Execution time
                                                                </Label>
                                                                <Field
                                                                    component={customInputForm}
                                                                    type="time"
                                                                    timeZone="UTC"
                                                                    style={{ width: '108px' }}
                                                                    className="modals-inputs"
                                                                    value={values.time || ''}
                                                                    name="time"
                                                                />
                                                            </FormGroup>
                                                        </>
                                                    )}
                                                    <FormGroup switch className="ml-3">
                                                        <Input
                                                            style={{
                                                                height: '20px',
                                                                width: '36px',
                                                            }}
                                                            type="switch"
                                                            role="switch"
                                                            id="execute_every"
                                                            checked={
                                                                values.several_occasions_type ===
                                                                    'execute_every' || ''
                                                            }
                                                            name="execute_every"
                                                            onChange={(e) => {
                                                                delete values['week_days']
                                                                setFieldValue(
                                                                    'several_occasions_type',
                                                                    'execute_every'
                                                                )
                                                            }}
                                                            className="custom-switch modals-labels"
                                                        />
                                                        <Label check className="ml-3 mt-1">
                                                            Execute every{' '}
                                                        </Label>
                                                    </FormGroup>
                                                    {values.several_occasions_type ===
                                                        'execute_every' && (
                                                        <FormGroup className="d-flex align-items-start justify-content-between">
                                                            <div>
                                                                <Label className="modals-labels">
                                                                    Execute every
                                                                </Label>
                                                                <Field
                                                                    component={customInputForm}
                                                                    type="number"
                                                                    placeholder="00 minutes"
                                                                    className="modals-inputs"
                                                                    value={
                                                                        values.execute_every || ''
                                                                    }
                                                                    name="execute_every"
                                                                    style={{ width: '132px' }}
                                                                />
                                                            </div>
                                                            <div
                                                                className="d-flex flex-column justiy-content-center align-items-end"
                                                                style={{ paddingTop: '32px' }}
                                                            >
                                                                <div className="d-flex justify-content-start align-items-center">
                                                                    <Label className="modals-labels mr-2">
                                                                        from
                                                                    </Label>
                                                                    <Field
                                                                        component={customInputForm}
                                                                        className="modals-inputs"
                                                                        type="time"
                                                                        value={
                                                                            values.active_from_tz ||
                                                                            values.active_from
                                                                        }
                                                                        name="active_from"
                                                                        style={{ width: '108px' }}
                                                                    />
                                                                </div>
                                                                <div className="mt-2 d-flex justify-content-center align-items-center">
                                                                    <Label className="modals-labels mr-2">
                                                                        to
                                                                    </Label>
                                                                    <Field
                                                                        component={customInputForm}
                                                                        className="modals-inputs"
                                                                        type="time"
                                                                        value={
                                                                            values.active_to_tz ||
                                                                            values.active_to
                                                                        }
                                                                        name="active_to"
                                                                        style={{ width: '108px' }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </FormGroup>
                                                    )}
                                                </div>
                                            ) : null}
                                        </div>
                                    ) : null}
                                </>
                            )}

                            <div className="w-full d-flex justify-content-end">
                                <Button
                                    className="modals-save-btn"
                                    style={{
                                        background: '#F9F5FF',
                                        border: 'none',
                                    }}
                                    onClick={() => setIsCreateNew(true)}
                                >
                                    <img
                                        src="/svgs/task-modal/plus.svg"
                                        alt="obstacle-icon"
                                        width="20rem"
                                        height="20rem"
                                        style={{ marginRight: '8px' }}
                                    />
                                    New mission
                                </Button>
                            </div>
                        </div>
                        <div
                            className={`d-flex w-100 justify-content-end align-items-center mt-4 pb-3`}
                        >
                            <div className="d-flex">
                                <Button
                                    className="cancel-btn-modal modals-new-btns mr-2"
                                    onClick={() => {
                                        toggle()
                                        resetForm({ values: '' })
                                        setIcon(false)
                                    }}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    className="save-btn-modal modals-new-btns"
                                    type="submit"
                                    disabled={
                                        !values.task_preset || Array.isArray(values.task_preset)
                                    }
                                >
                                    {isSubmitting ? 'Processing...' : 'Run mission'}
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default RunMissionPreset
