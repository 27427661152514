import React, { useEffect, useState } from 'react'
import { Loading } from 'ui/common'
import NotificationItem from './NotificationItem'

const NotificationBody = ({ items, status, handleDelete }) => {
    const [notificationState, setNotificationState] = useState(
        items?.map(() => ({
            isDisabled: false,
            countdown: null,
        })) || []
    )

    useEffect(() => {
        setNotificationState(
            items?.map(() => ({
                isDisabled: false,
                countdown: null,
            })) || []
        )
    }, [items])

    const startCountdown = (index, id) => {
        const updatedState = [...notificationState]
        if (!updatedState[index]) return
        updatedState[index].isDisabled = true
        updatedState[index].countdown = 3 // Start countdown from 3

        setNotificationState(updatedState)

        const countdownInterval = setInterval(() => {
            setNotificationState((prevState) => {
                const newState = [...prevState]
                if (newState[index].countdown === 1) {
                    newState[index].isDisabled = false
                    newState[index].countdown = null
                    clearInterval(countdownInterval)
                    handleDelete(id) // Delete the item after countdown ends
                } else {
                    newState[index].countdown -= 1
                }
                return newState
            })
        }, 1000)
    }

    if (status === 'team-notifications-error')
        return <p className="text-center text-danger">Something went wrong</p>
    if (status === 'loading') return <Loading />
    if (!items) return <p className="mt-3 text-center text-meili">Notification center is empty!</p>

    return items.map((item, key) => (
        <NotificationItem
            item={item}
            key={key}
            index={key}
            notificationState={notificationState}
            startCountdown={startCountdown}
        />
    ))
}

export default NotificationBody
