import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import ErrorBoundary from 'components/ErrorBoundary'
import PublicRoutes from 'routes/PublicRoutes'
import DashboardRoutesWithAuthCheck from 'routes/DashboardRoutesWithAuthCheck'
import NotificationMessages from 'components/NotificationMessages'

const App = () => {
    return (
        <>
            <NotificationMessages />
            <Router>
                <Switch>
                    <Route strict path="/dashboard/">
                        <DashboardRoutesWithAuthCheck />
                    </Route>
                    <Route exact path="">
                        <Row className="d-flex justify-content-center align-items-center">
                            {/* <Col md={4} className="min-vh-100 d-none d-md-block">
                                <PublicSidebar />
                            </Col> */}
                            <Col md={12} className="align-self-center mt-5">
                                <ErrorBoundary>
                                    <PublicRoutes />
                                </ErrorBoundary>
                                <span className="copyright-style">© Meili Robots 2025</span>
                            </Col>
                        </Row>
                    </Route>
                </Switch>
            </Router>
        </>
    )
}

export default App
