import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap'
import AuthCodeInput from './AuthCode'
import { get2FAQRCode, get2FASecret, sendAppCode } from 'features/user/actionTypes'
import { Formik } from 'formik'

const TwoFactorAuthentication = () => {
    const dispatch = useDispatch()
    const mobileApps = ['Google Authenticator', 'Microsoft Authenticator', 'Duo Mobile']

    const [secret, setSecret] = useState('')
    const [qrCode, setQRCode] = useState('')
    const [backupTokens, setBackupTokens] = useState([])
    const [modal, setModal] = useState(false)

    const toggle = () => setModal(!modal)

    useEffect(() => {
        dispatch(get2FASecret()).then((response) => {
            if (!response.error) setSecret(response.payload.data.secret)
        })
        dispatch(get2FAQRCode()).then((response) => {
            if (!response.error) setQRCode(response.payload.data)
        })
    }, [dispatch])

    const handleCompleteCode = (code, setFieldValue) => {
        setFieldValue('authCode', code)
    }

    const handleSendCode = (token) => {
        if (token !== '' && token.length === 6) {
            dispatch(sendAppCode({ token })).then((response) => {
                if (!response.error) {
                    setBackupTokens(response.payload.data.backup_tokens)
                    toggle()
                }
            })
        }
    }

    return (
        <div className="w-100 h-100 d-flex flex-column justify-content-start align-items-center gap-5">
            <div className="d-flex flex-column align-items-center">
                <span style={{ fontWeight: '500', fontSize: '30px', lineHeight: '54px' }}>
                    Enable 2FA
                </span>
                <span
                    style={{
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '20px',
                        letterSpacing: '0.1px',
                    }}
                >
                    Add an extra security layer to your account.
                </span>
            </div>
            <div className="d-flex flex-column align-items-center" style={{ gap: '32px' }}>
                <div className="d-flex justify-content-center align-items-start gap-5">
                    <div className="d-flex flex-column align-items-start" style={{ gap: '13px' }}>
                        <span
                            style={{
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                                letterSpacing: '0.1px',
                                width: 'max-content',
                                display: 'inline-block',
                                whiteSpace: 'normal',
                                textAlign: 'left',
                            }}
                        >
                            You will need an authenticator mobile <br />
                            app to complete this process, such as:
                        </span>
                        {mobileApps.map((app) => (
                            <span
                                key={app}
                                style={{
                                    fontWeight: '500',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    letterSpacing: '0.1px',
                                    color: '#670D95',
                                }}
                            >
                                {app}
                            </span>
                        ))}
                    </div>
                    {qrCode !== '' && (
                        <img
                            src={`data:image/svg+xml;utf8,${encodeURIComponent(qrCode)}`}
                            alt="QR Code"
                            style={{ width: '150px', height: '150px' }}
                        />
                    )}
                </div>
                <Formik
                    initialValues={{ authCode: '' }}
                    onSubmit={(values) => {
                        handleSendCode(values.authCode)
                    }}
                >
                    {({ setFieldValue, handleSubmit }) => (
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault()
                                handleSubmit()
                            }}
                            className="w-100"
                        >
                            <FormGroup className="d-flex flex-column align-items-start w-100">
                                <Label
                                    style={{
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        letterSpacing: '0.1px',
                                        width: 'max-content',
                                        display: 'inline-block',
                                        whiteSpace: 'normal',
                                    }}
                                >
                                    Enter the following key into your app:
                                </Label>
                                <Input
                                    className="modals-inputs"
                                    placeholder="JkH 39H LJ9 GRD"
                                    value={secret}
                                />
                            </FormGroup>
                            <FormGroup
                                className="d-flex flex-column align-items-start w-100"
                                style={{
                                    gap: '12px',
                                    borderBottom: '1px solid #E7E9EC',
                                    paddingBottom: ' 32px',
                                }}
                            >
                                <Label
                                    style={{
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        letterSpacing: '0.1px',
                                        width: 'max-content',
                                        display: 'inline-block',
                                        whiteSpace: 'normal',
                                    }}
                                >
                                    Enter the six-digit code generated by your authenticator:
                                </Label>
                                <AuthCodeInput
                                    length={6}
                                    onComplete={handleCompleteCode}
                                    setFieldValue={setFieldValue}
                                />
                            </FormGroup>
                            <div
                                className="d-flex justify-content-end w-100"
                                style={{ gap: '12px' }}
                            >
                                <Button
                                    className="modals-new-btns cancel-btn-modal"
                                    onClick={() => window.history.back()}
                                >
                                    Cancel
                                </Button>

                                <Button className="modals-new-btns save-btn-modal" type="submit">
                                    Verify now
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            <Modal isOpen={modal} toggle={toggle} centered className="new-modals">
                <ModalBody>
                    <div
                        className="w-100 d-flex justify-content-between"
                        style={{ marginBottom: '24px' }}
                    >
                        <img
                            src="/svgs/modal-icons/security.svg"
                            alt="obstacle-icon"
                            width="48px"
                            height="48px"
                        />
                        <img
                            src="/svgs/close-icon/x-dark-default.svg"
                            alt="obstacle-icon"
                            width="24px"
                            height="24px"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                toggle()
                                window.history.back()
                            }}
                        />
                    </div>
                    Make sure to download and store your backup tokens in a safe place. These tokens
                    can be used to access your account if you lose access to your authentication
                    device. Once lost, they cannot be recovered.
                    <ol style={{ marginTop: '24px' }}>
                        {backupTokens.map((token, index) => (
                            <li
                                style={{
                                    fontWeight: '500',
                                    fontSize: '16px',
                                    lineHeight: '20px',
                                    letterSpacing: '0.1px',
                                }}
                                key={index}
                            >
                                {token}
                            </li>
                        ))}
                    </ol>
                    <Button
                        style={{ marginTop: '24px' }}
                        onClick={() => {
                            const element = document.createElement('a')
                            const file = new Blob([backupTokens.join('\n')], { type: 'text/plain' })
                            element.href = URL.createObjectURL(file)
                            element.download = 'backup_tokens.txt'
                            document.body.appendChild(element)
                            element.click()
                            document.body.removeChild(element)
                            toggle()
                            window.history.back()
                        }}
                        className="save-btn-modal modals-new-btns w-100"
                    >
                        Download
                    </Button>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default TwoFactorAuthentication
