import React from 'react'
import cx from 'classnames'
import { UncontrolledTooltip } from 'reactstrap'
import { formatDate } from './organization/OrgTeamHelpers'
import { BiMobile } from 'react-icons/bi'

const NotificationItem = ({ item, index, notificationState, startCountdown }) => {
    const icons = {
        system: '/svgs/dashboard-icons/settings-icon.svg',
        vehicle: '/svgs/dashboard-icons/vehicle-icon.svg',
    }

    const tooltipText = `${
        item.notification_type.charAt(0).toUpperCase() + item.notification_type.slice(1)
    } notification`

    return (
        <div
            className={cx('mb-1 rounded-0 w-100', { 'read ': !item.read })}
            key={index}
            style={{ paddingBottom: '10px' }}
        >
            <div className="d-flex flex-column align-items-start justify-content-start w-100">
                <div className="d-flex align-items-center justify-content-between w-100">
                    <div className="d-flex align-items-center w-100">
                        <img
                            alt={item.notification_type}
                            width={48}
                            height={48}
                            id={`${item.notification_type}-${index}`}
                            src={icons[item.notification_type]}
                        />
                        <span
                            className="border-none rounded px-1 bg-grey"
                            style={{ color: '#656D7E' }}
                        >
                            {formatDate(item._created)}
                        </span>
                        {item.sms_id && (
                            <span
                                className="border-none rounded bg-grey d-flex align-items-center px-1"
                                style={{ color: '#656D7E' }}
                            >
                                <BiMobile size={18} /> SMS
                            </span>
                        )}
                        <UncontrolledTooltip
                            placement="top"
                            target={`${item.notification_type}-${index}`}
                            style={{ backgroundColor: '#FFFF', color: '#3D414C' }}
                        >
                            {tooltipText}
                        </UncontrolledTooltip>
                    </div>
                    {notificationState[index]?.isDisabled &&
                    notificationState[index]?.countdown !== null ? (
                        <span style={{ marginBottom: '5px' }}>
                            {notificationState[index].countdown}
                        </span>
                    ) : (
                        <img
                            src="/svgs/close-icon/x-dark-default.svg"
                            alt="obstacle-icon"
                            width="14px"
                            height="14px"
                            style={{ cursor: 'pointer', marginBottom: '5px' }}
                            onClick={() => startCountdown(index, item._id)} // Start countdown for this item
                        />
                    )}
                </div>
                <p className={cx('unread ml-3', { read: item.read })}>{item.text}</p>
            </div>
        </div>
    )
}

export default NotificationItem
