import React, { useState } from 'react'
import { compose } from 'redux'
import { connect, useSelector } from 'react-redux'
import { FormGroup, Label, Button, Input, UncontrolledTooltip } from 'reactstrap'
import { Formik, Form, Field } from 'formik'
import { customInputForm } from 'ui/common'
import { withRouter } from 'react-router-dom'
import { selectors } from 'features/dashboard'
import { HiOutlineTrash } from 'react-icons/hi'
import DropdownSelect from 'layout-components/Dropdown'

const NewZoneForm = ({
    handleAdd,
    zone = {},
    isOriginalImage,
    toggle,
    categories,
    isClicked,
    nextPagePresets,
    setCurrentPageNumber,
    handleDeleteModalShow,
}) => {
    const taskStore = useSelector(selectors.getTask)
    const [zoneType, setZoneType] = useState('')
    const [category, setCategory] = useState(
        (zone?.allowed_vehicle_categories &&
            categories?.find((cat) => cat.uuid === zone?.allowed_vehicle_categories[0])?.title) ||
            ''
    )
    const [entryPreset, setEntryPreset] = useState(
        (
            zone?.entry_preset &&
            taskStore.presetsV2?.results?.find((preset) => preset.uuid === zone?.entry_preset)
        )?.title || ''
    )
    const [exitPreset, setExitPreset] = useState(
        (
            zone?.exit_preset &&
            taskStore.presetsV2?.results?.find((preset) => preset.uuid === zone?.exit_preset)
        )?.title || ''
    )
    const options = () => {
        switch (true) {
            case isOriginalImage:
                return [{ uuid: 'obstacle', title: 'Obstacle' }]
            default:
                return [
                    { uuid: 'action', title: 'Action zone' },
                    { uuid: 'speed_limit', title: 'Speed zone' },
                    { uuid: 'forbidden', title: 'Forbidden zone' },
                    { uuid: 'tag', title: 'Tag zone' },
                    { uuid: 'charging', title: 'Charging zone' },
                    { uuid: 'resting', title: 'Resting zone' },
                    { uuid: 'evacuation', title: 'Evacuation zone' },
                    { uuid: 'capacity', title: 'Capacity zone' },
                ]
        }
    }
    return (
        <>
            <div
                style={{ marginBottom: '24px' }}
                className={`d-flex w-100 justify-content-between align-items-start`}
            >
                <img
                    src="/svgs/map-editor/obstacles-icon.svg"
                    alt="obstacle-icon"
                    width="48px"
                    height="48px"
                />
                <img
                    src="/svgs/close-icon/x-dark-default.svg"
                    alt="obstacle-icon"
                    width="24px"
                    height="24px"
                    style={{ cursor: 'pointer' }}
                    onClick={toggle}
                />
            </div>
            <h5>
                <strong>
                    {zone.uuid ? (
                        <>Edit zone</>
                    ) : isOriginalImage ? (
                        <>New obstacle</>
                    ) : (
                        <>New zone</>
                    )}
                </strong>
            </h5>
            <Formik initialValues={zone} onSubmit={handleAdd}>
                {({ values, setFieldValue }) => (
                    <Form>
                        <FormGroup>
                            <Label className="modals-labels">
                                Name {values.zone_type === 'tag' && '*'}
                            </Label>
                            <Field
                                className="modals-inputs"
                                type="text"
                                name="title"
                                placeholder="Zone name"
                                component={customInputForm}
                                value={values.title || ''}
                                required={values.zone_type === 'tag'}
                            ></Field>
                        </FormGroup>
                        {!isClicked ? (
                            <FormGroup>
                                <Label className="modals-labels">Type *</Label>
                                <DropdownSelect
                                    selected={zoneType}
                                    setSelected={setZoneType}
                                    options={options()}
                                    setFieldValue={setFieldValue}
                                    fieldValue="zone_type"
                                />
                                {values.zone_type === 'evacuation' && (
                                    <small>
                                        After creating a zone, remember to add stations inside it
                                        for proper functionality.
                                    </small>
                                )}
                            </FormGroup>
                        ) : null}
                        {values.zone_type !== 'forbidden' &&
                        values.zone_type !== 'action' &&
                        values.zone_type !== 'speed_limit' &&
                        values.zone_type !== 'obstacle' &&
                        values.zone_type !== 'tag' &&
                        values.zone_type !== 'capacity' &&
                        !isOriginalImage ? (
                            <>
                                {' '}
                                <FormGroup>
                                    <Label className="modals-labels">Vehicle category *</Label>
                                    <DropdownSelect
                                        selected={category}
                                        setSelected={setCategory}
                                        options={categories.map((cat) => cat)}
                                        setFieldValue={setFieldValue}
                                        fieldValue="allowed_vehicle_categories"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="modals-labels">
                                        Zone safety parameter (meters) *
                                    </Label>
                                    <img
                                        src="/svgs/trigger-actions/question-mark.svg"
                                        alt="plus"
                                        id="zone-safety"
                                        width={16}
                                        height={16}
                                        style={{
                                            cursor: 'pointer',
                                            color: '#C2C5CE',
                                            marginLeft: '4px',
                                        }}
                                    />
                                    <UncontrolledTooltip
                                        placement="right"
                                        target="zone-safety"
                                        delay={{ show: 100, hide: 400 }}
                                        className="custom-tooltip"
                                    >
                                        A metric values that determines the area around the charger
                                        to be shown as charging zone.
                                    </UncontrolledTooltip>
                                    <Field
                                        className="modals-inputs"
                                        type="number"
                                        name="safety_parameter"
                                        step={0.01}
                                        min="0"
                                        placeholder="0.00 m"
                                        required
                                        component={customInputForm}
                                        value={values.safety_parameter || ''}
                                    ></Field>
                                </FormGroup>
                                {values.zone_type === 'charging' && !zone.uuid && (
                                    <FormGroup
                                        check
                                        className="my-3 ml-2 d-flex flex-column justify-content-center align-items-start"
                                    >
                                        <Input
                                            type="checkbox"
                                            id="docking_and_undocking_stations"
                                            checked={values.docking_and_undocking_stations}
                                            name="docking_and_undocking_stations"
                                            onChange={(e) => {
                                                setFieldValue(
                                                    'docking_and_undocking_stations',
                                                    e.target.checked
                                                )
                                            }}
                                        />
                                        <Label
                                            for="docking_and_undocking_stations"
                                            className="mt-1"
                                            check
                                        >
                                            Docking and undocking stations
                                        </Label>
                                    </FormGroup>
                                )}
                                <FormGroup className="d-flex flex-column">
                                    <div>
                                        <Label className="modals-labels" for="priority">
                                            Priority
                                        </Label>
                                        <img
                                            src="/svgs/trigger-actions/question-mark.svg"
                                            alt="plus"
                                            id="priority"
                                            width={16}
                                            height={16}
                                            style={{
                                                cursor: 'pointer',
                                                color: '#C2C5CE',
                                                marginLeft: '4px',
                                            }}
                                        />
                                        <UncontrolledTooltip
                                            placement="right"
                                            target="priority"
                                            delay={{ show: 100, hide: 400 }}
                                            className="custom-tooltip"
                                        >
                                            Increasing the priority will make a charging zone to be
                                            preferred over other charging zones with lower priority.
                                        </UncontrolledTooltip>
                                    </div>
                                    <Field
                                        id="priority"
                                        name="range"
                                        type="range"
                                        min="0"
                                        max="5"
                                        value={values.priority || 0}
                                        onChange={(e) => {
                                            setFieldValue('priority', Number(e.target.value))
                                        }}
                                    />
                                    <output className="pl-2">
                                        {values.priority ? values.priority : 0}
                                    </output>
                                </FormGroup>
                                <FormGroup>
                                    <Label className="modals-labels">Vehicle limit</Label>
                                    <Field
                                        className="modals-inputs"
                                        type="number"
                                        name="zone_vehicle_limit"
                                        placeholder="Vehicle limit"
                                        component={customInputForm}
                                        value={
                                            values.zone_vehicle_limit !== undefined
                                                ? values.zone_vehicle_limit
                                                : ''
                                        }
                                    ></Field>
                                </FormGroup>
                                {values.zone_type !== 'evacuation' && (
                                    <>
                                        {' '}
                                        <FormGroup>
                                            <Label className="modals-labels">Entry preset</Label>
                                            <img
                                                src="/svgs/trigger-actions/question-mark.svg"
                                                alt="plus"
                                                id="entryPreset"
                                                width={16}
                                                height={16}
                                                style={{
                                                    cursor: 'pointer',
                                                    color: '#C2C5CE',
                                                    marginLeft: '4px',
                                                }}
                                            />
                                            <UncontrolledTooltip
                                                placement="right"
                                                target="entryPreset"
                                                delay={{ show: 100, hide: 400 }}
                                                className="custom-tooltip"
                                            >
                                                Attach a mission preset to be automatically initiate
                                                docking sequence (entering the zone).
                                            </UncontrolledTooltip>
                                            <DropdownSelect
                                                selected={entryPreset}
                                                setSelected={setEntryPreset}
                                                options={[
                                                    { uuid: null, title: '' },
                                                    ...taskStore.presetsV2?.results?.map(
                                                        (preset) => preset
                                                    ),
                                                ]}
                                                setFieldValue={setFieldValue}
                                                nextPagePresets={nextPagePresets}
                                                setCurrentPage={setCurrentPageNumber}
                                                fieldValue="entry_preset"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="modals-labels">Exit preset</Label>
                                            <img
                                                src="/svgs/trigger-actions/question-mark.svg"
                                                alt="plus"
                                                id="exitPreset"
                                                width={16}
                                                height={16}
                                                style={{
                                                    cursor: 'pointer',
                                                    color: '#C2C5CE',
                                                    marginLeft: '4px',
                                                }}
                                            />
                                            <UncontrolledTooltip
                                                placement="right"
                                                target="exitPreset"
                                                delay={{ show: 100, hide: 400 }}
                                                className="custom-tooltip"
                                            >
                                                Attach a mission preset to be automatically initiate
                                                undocking sequence (when moving out of the zone).
                                            </UncontrolledTooltip>
                                            <DropdownSelect
                                                selected={exitPreset}
                                                setSelected={setExitPreset}
                                                options={[
                                                    { uuid: null, title: '' },
                                                    ...taskStore.presetsV2?.results?.map(
                                                        (preset) => preset
                                                    ),
                                                ]}
                                                setFieldValue={setFieldValue}
                                                fieldValue="exit_preset"
                                            />
                                        </FormGroup>
                                    </>
                                )}
                            </>
                        ) : null}

                        {values.zone_type !== 'forbidden' &&
                        values.zone_type !== 'action' &&
                        values.zone_type !== 'speed_limit' &&
                        values.zone_type !== 'obstacle' &&
                        values.zone_type !== 'tag' &&
                        values.zone_type === 'capacity' &&
                        !isOriginalImage ? (
                            <>
                                {' '}
                                <FormGroup>
                                    <Label className="modals-labels">
                                        Zone distance threshold (meters){' '}
                                    </Label>
                                    <img
                                        src="/svgs/trigger-actions/question-mark.svg"
                                        alt="plus"
                                        id="zone-distance"
                                        width={16}
                                        height={16}
                                        style={{
                                            cursor: 'pointer',
                                            color: '#C2C5CE',
                                            marginLeft: '4px',
                                        }}
                                    />
                                    <UncontrolledTooltip
                                        placement="right"
                                        target="zone-distance"
                                        delay={{ show: 100, hide: 400 }}
                                        className="custom-tooltip"
                                    >
                                        Distance at which capacity zone will be checked
                                    </UncontrolledTooltip>
                                    <Field
                                        className="modals-inputs"
                                        type="number"
                                        name="zone_distance_threshold"
                                        step={1}
                                        min="0"
                                        placeholder="0 m"
                                        required
                                        component={customInputForm}
                                        value={values.zone_distance_threshold || ''}
                                    ></Field>
                                </FormGroup>
                                <FormGroup check className="my-3 ml-2">
                                    <Input
                                        type="checkbox"
                                        id="check_capacity_zone_task_allocation"
                                        checked={
                                            values.check_capacity_zone_task_allocation === true
                                                ? true
                                                : false
                                        }
                                        onChange={() => {
                                            setFieldValue(
                                                'check_capacity_zone_task_allocation',
                                                !values.check_capacity_zone_task_allocation
                                            )
                                        }}
                                    />

                                    <Label check for="capacityZones">
                                        Check capacity zone allocation
                                    </Label>
                                    <img
                                        src="/svgs/trigger-actions/question-mark.svg"
                                        alt="plus"
                                        id="check-capacity"
                                        width={16}
                                        height={16}
                                        style={{
                                            cursor: 'pointer',
                                            color: '#C2C5CE',
                                            marginLeft: '4px',
                                        }}
                                    />
                                    <UncontrolledTooltip
                                        placement="right"
                                        target="check-capacity"
                                        delay={{ show: 100, hide: 400 }}
                                        className="custom-tooltip"
                                    >
                                        If set to "True", the vehicle limit for capacity zones is
                                        enforced during task allocation; if "False", the limit is
                                        managed at the entry station of the zone when vehicles
                                        arrive.
                                    </UncontrolledTooltip>
                                </FormGroup>
                                <FormGroup>
                                    <Label className="modals-labels">Zone vehicle limit </Label>
                                    <img
                                        src="/svgs/trigger-actions/question-mark.svg"
                                        alt="plus"
                                        id="vehicle-limit"
                                        width={16}
                                        height={16}
                                        style={{
                                            cursor: 'pointer',
                                            color: '#C2C5CE',
                                            marginLeft: '4px',
                                        }}
                                    />
                                    <UncontrolledTooltip
                                        placement="right"
                                        target="vehicle-limit"
                                        delay={{ show: 100, hide: 400 }}
                                        className="custom-tooltip"
                                    >
                                        Max number of vehicles allowed in this zone.
                                    </UncontrolledTooltip>
                                    <Field
                                        className="modals-inputs"
                                        type="number"
                                        name="zone_vehicle_limit"
                                        step={1}
                                        min="0"
                                        required
                                        component={customInputForm}
                                        value={values.zone_vehicle_limit || ''}
                                    ></Field>
                                </FormGroup>
                            </>
                        ) : null}

                        {values.zone_type !== 'forbidden' &&
                        values.zone_type !== 'action' &&
                        values.zone_type === 'speed_limit' &&
                        values.zone_type !== 'obstacle' &&
                        values.zone_type !== 'tag' &&
                        values.zone_type !== 'capacity' &&
                        !isOriginalImage ? (
                            <>
                                {' '}
                                <FormGroup>
                                    <Label className="modals-labels">Speed limit (m/s)</Label>
                                    <img
                                        src="/svgs/trigger-actions/question-mark.svg"
                                        alt="plus"
                                        id="speed-limit"
                                        width={16}
                                        height={16}
                                        style={{
                                            cursor: 'pointer',
                                            color: '#C2C5CE',
                                            marginLeft: '4px',
                                        }}
                                    />
                                    <UncontrolledTooltip
                                        placement="right"
                                        target="speed-limit"
                                        delay={{ show: 100, hide: 400 }}
                                        className="custom-tooltip"
                                    >
                                        Set speed limit
                                    </UncontrolledTooltip>
                                    <Field
                                        className="modals-inputs"
                                        type="number"
                                        name="speed_limit"
                                        step={1}
                                        min="0"
                                        required
                                        component={customInputForm}
                                        value={values.speed_limit || ''}
                                    ></Field>
                                </FormGroup>
                            </>
                        ) : null}
                        <div
                            style={{ marginTop: '24px' }}
                            className={`d-flex w-100 ${
                                isClicked ? 'justify-content-between' : 'justify-content-end'
                            } align-items-center`}
                        >
                            {isClicked ? (
                                <Button
                                    color="none"
                                    onClick={() => handleDeleteModalShow(zone)}
                                    className="modals-delete-btn"
                                >
                                    <HiOutlineTrash size={20} className="mr-1" />
                                    Delete
                                </Button>
                            ) : null}

                            <div className="d-flex">
                                <Button
                                    color="none"
                                    onClick={toggle}
                                    className="cancel-btn-modal modals-new-btns mr-2"
                                >
                                    Cancel
                                </Button>

                                {isClicked ? (
                                    <Button
                                        color={'meili'}
                                        type="submit"
                                        className="save-btn-modal modals-new-btns"
                                        disabled={JSON.stringify(zone) === JSON.stringify(values)}
                                    >
                                        Edit zone{' '}
                                    </Button>
                                ) : (
                                    <Button
                                        color={'meili'}
                                        type="submit"
                                        className="save-btn-modal modals-new-btns"
                                        disabled={
                                            values.zone_type !== 'forbidden' &&
                                            values.zone_type !== 'action' &&
                                            values.zone_type !== 'speed_limit' &&
                                            values.zone_type !== 'capacity' &&
                                            values.zone_type !== 'obstacle' &&
                                            values.zone_type !== 'tag'
                                                ? !values.zone_type ||
                                                  !values.safety_parameter ||
                                                  !values.allowed_vehicle_categories
                                                : false
                                        }
                                    >
                                        {isOriginalImage ? <>Create obstacle</> : <>Create zone</>}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

function mapStateToProps(state) {
    return {
        categories: selectors.getVehiclesCategoires(state),
    }
}

export default compose(withRouter, connect(mapStateToProps))(NewZoneForm)
